import { MessageCheckIcon, CrossIcon, WarningIcon } from '@assets';
import { AppAlert, AppText, AppButton, AppEmptyView } from '@components';
import { ForceUpdateStyles } from '@components/AppForceUpdate/ForceUpdate.style';
import { Constant, PERMISSION_MODEL } from '@constant';
import { AppHtmlView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import { TicketItemView } from '@screens/main/ticket/ListTicket/components/TicketItem.view';
import theme, {globalStyle, WEB_WIDTH} from '@theme';
import { deviceWidth, Font, FontSize, Spacing } from '@theme/size';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View, TouchableOpacity, ScrollView, FlatList } from 'react-native';
import { useListNotificationModal } from './hook';
import { styles } from './styles';

export const ListNotificationModal = props => {
  const { show } = props;
  const { handlePressButton, handleHideModal, modalData, onItemPress } = useListNotificationModal(props);
  const { t } = useTranslation();

  // const { title, desc } = text;

  const renderItem = ({ item, index }) => {
    return (
      <TouchableOpacity
        key={item.internalId}
        style={[styles.box]}
        onPress={() => {
          onItemPress(item)
        }}>
        <AppText style={styles.text}>{item.name}</AppText>
      </TouchableOpacity>
    );
  };

  return (
    <AppAlert
      onDismiss={handleHideModal}
      closeOnTouchOutside
      show={show}
      contentContainerStyle={{
        maxWidth: '100%',
        // borderWidth: 0,
        // borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
      }}
      customView={
        <View
          style={[
            // globalStyle.directionColumm,
            {
              borderRadius: 10,
              // marginVertical: -8,
                width:  '100%',
              // paddingHorizontal: 0,
              marginHorizontal: 0,
              overflow: 'hidden',
            },
          ]}>
          <FlatList
            style={{
                width:  Platform.OS === 'web' ? WEB_WIDTH - theme.spacing.md * 2 : deviceWidth - theme.spacing.md * 2,
              // width: 300,
              backgroundColor: 'white',
              maxHeight: (Constant.MAIN_HEIGHT + theme.spacing.lg) * 3 + 8,
            }}
            keyExtractor={(item, index) => String(index)}
            contentContainerStyle={{
              marginTop: theme.spacing.lg,
              paddingBottom: theme.spacing.lg,
              flexGrow: 1,
            }}
            data={modalData?.data ?? []}
            renderItem={renderItem}
            scrollIndicatorInsets={{ right: 1 }}
            ListEmptyComponent={AppEmptyView}
            showsVerticalScrollIndicator={false}
          />
        </View>
      }
    />
  );
};
