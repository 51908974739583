import { Constant, ReportTypeSetting } from '@constant';
import _ from 'lodash';

export const ReportHelper = {
  getReportSetting: (paramsType, appSetting) => {
    if (!_.isEmpty(appSetting)) {
      switch (paramsType) {
        case Constant.menu.report_test_items:
          return appSetting[ReportTypeSetting.reportIcode];
        case Constant.menu.report_bundle_dispatch:
          return appSetting[ReportTypeSetting.reportBundle];
        case Constant.menu.posting_package:
          return appSetting[ReportTypeSetting.reportPackage];
      }
    }

    return null;
  },
  fixFavourite: (listData, item, isAdd = true) => {
    if (_.isEmpty(listData)) {
      return listData;
    }

    const findIndex = listData.findIndex(x => x.id === item.id);
    if (findIndex < 0) {
      return listData;
    }
    const findItem = listData[findIndex];
    listData[findIndex] = {
      ...findItem,
      isFavourite: isAdd ? true : false,
    };
    return _.cloneDeep(listData);
  },
};
