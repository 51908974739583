import { Constant } from '@constant';
import theme from '@theme';
import { Font, FontSize, Spacing } from '@theme/size';
import { StyleSheet, Platform } from 'react-native';

export const styles = StyleSheet.create({
  box: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#C6C6C6',
    // paddingHorizontal: 12,
    minHeight: Constant.MAIN_HEIGHT,
    borderRadius: 4,
    // width: '100%',
    marginHorizontal: theme.spacing.md,
    marginBottom: Platform.OS === 'web' ? '20px' : Spacing.height16,
  },
  text: {
    fontWeight: '400',
    color: '#333333',
    paddingHorizontal: Spacing.width12,
  },
});
