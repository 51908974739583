import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  payload: null,
  error: null,
};

export const addFavouriteLocationRequest = createAction(
  'addFavouriteLocation/addFavouriteLocationRequest',
  (payload, onSuccess, onFailed) => {
    console.log('prepareAction>>>', payload, onSuccess);
    return {
      payload: payload,
      meta: onSuccess,
      error: onFailed,
    };
  },
);

const addFavouriteLocationSlice = createSlice({
  name: 'addFavouriteLocation',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // getReportPackageFormRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    addFavouriteLocationSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    addFavouriteLocationFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { addFavouriteLocationSuccess, addFavouriteLocationFailed } = addFavouriteLocationSlice.actions;

export default addFavouriteLocationSlice.reducer;
