import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppKAView, AppSafeAreaView, AppText } from '@components';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import { AddressContainerView } from '@screens/main/SearchAddress/components/AddressContainer.view';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text, TextInput, FlatList, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useSearchPostcode } from './SearchPostcode.hook';
import { SearchPostcodeStyle } from './SearchPostcode.style';
import _ from 'lodash';
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {AppSetting} from "@utils/common/setting";

// import {AddressContainerView} from "@components/AppAddress/AddressContainer.view";

export const AddressItem = ({ data, onPress, isLast, isSelected }) => {
  return (
    <TouchableOpacity
      style={[
        isSelected ? SearchPostcodeStyle.viewSelected : SearchPostcodeStyle.viewUnSelected,
        {
          marginBottom: isLast ? theme.spacing.sm : theme.spacing.ms,
          marginTop: theme.spacing.ms,
        },
      ]}
      onPress={onPress}>
      <AppText style={[]}>{data?.title}</AppText>
    </TouchableOpacity>
  );
};

export const AddressBundleItem = ({ data, onPress, isLast, isSelected, onFavouritePress }) => {
  console.log('AddressBundleItem<<', data);
  const canFavourite = data?.canFavourite
  // const canFavourite = true;

  return (
    <TouchableOpacity
      style={[
        isSelected ? SearchPostcodeStyle.viewSelected : SearchPostcodeStyle.viewUnSelected,
        {
          marginBottom: isLast ? theme.spacing.sm : theme.spacing.ms,
          marginTop: theme.spacing.ms,
        },
      ]}
      onPress={onPress}>
      <View style={[globalStyle.directionRow]}>
        <AppText
          style={[globalStyle.flex1]}
          // numberOfLines={1}
        >
          {data?.address}
        </AppText>
        <View>
          {!_.isNil(data?.distance) && <AppText style={[{ marginLeft: theme.spacing.sm }]}>{data?.distance}</AppText>}
          {!!canFavourite && (
            <TouchableOpacity style={{ alignItems: 'flex-end', paddingVertical: 8 }} onPress={onFavouritePress}>
              <FontAwesome5
                name={'star'}
                color={data?.isFavourite ? 'orange' : AppSetting.state?.colors?.MAIN_BLACK}
                size={24}
                solid={data?.isFavourite ? true : false}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
      {/*{data?.postBox && <AppText style={[{marginTop: 4}]}>{data?.postBox}</AppText>}*/}
    </TouchableOpacity>
  );
};

// export const AppEmptyView = ({ emptyText = 'No data' }) => {
//   return (
//     <View style={[globalStyle.fullWidth, globalStyle.center]}>
//       <AppText style={[SearchPostcodeStyle.emptyText]}>{emptyText}</AppText>
//     </View>
//   );
// };

export const AppLoadingMore = ({ loadingMore = false }) => {
  return loadingMore ? (
    <View style={[{ padding: 4 }]}>
      <ActivityIndicator size={'small'} color={palette.LOADING_COLOR} />
    </View>
  ) : (
    <></>
  );
};

export const SearchPostcodeScreen = props => {
  const { t } = useTranslation();
  const {
    onSearch,
    data,
    onRefresh,
    isRefreshing,
    loadingMore,
    loading,
    onLoadMore,
    onTextChanged,
    searchText,
    onItemPress,
  } = useSearchPostcode(props);
  return (
    <AddressContainerView
      data={data}
      loadingMore={loadingMore}
      onTextChanged={onTextChanged}
      headerText={t('POSTCODE_HEADER')}
      isRefreshing={isRefreshing}
      loading={loading}
      onLoadMore={onLoadMore}
      emptyText={loading ? t('SEARCHING') : t('NO_POSTCODE_FOUND')}
      onRefresh={onRefresh}
      onSearch={onSearch}
      searchText={searchText}
      descriptionText={t('POSTCODE_DESCRIPTION')}
      onItemPress={onItemPress}
      // isFullScreen={false}
    />
  );
};
