import { AppText } from '@components';
import { LoadingView } from '@components/Loading/Loading.view';
import { AppHtmlView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import theme, { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { deviceHeight, FontSize, Spacing } from '@theme/size';
import { ApiHelper } from '@utils/api';
import React, { useEffect, useState } from 'react';
import {Linking, Platform, StyleSheet, useWindowDimensions, View} from 'react-native';
import { WebView } from 'react-native-webview';
import {t} from "i18next";

export const AppWebView = React.memo(props => {
  const { style, html, uri, disableLoading } = props;


  const fontScale = useWindowDimensions().fontScale;
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const isConnected = ApiHelper.isConnected();
  const [connected, setConnected] = useState(isConnected)
  console.log('AppWebView>>isConnected>>>', isConnected);
  console.log('AppWebView>>props>>>', props);


  const handleSetLoading = status => {
    if(disableLoading){
      return
    }
    setLoading(status);
  };
  useEffect(() => {
    if(disableLoading){
      return
    }
    if (isConnected) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, []);

  const onError = (e) => {
    setIsError(true);
    if(Platform.OS === 'ios'){
      if ([-1009, -1008].includes(e.nativeEvent?.code)) {
        setConnected(false)
      }
    }
    else if(Platform.OS === 'android'){
      if ([-2].includes(e.nativeEvent?.code)) {
        setConnected(false)
      }
    }
    setLoading(false)
  };
  function onShouldStartLoadWithRequest(request) {
    console.log({ request });
    // short circuit these
    if (
      !request.url ||
      request.url.startsWith('http') ||
      request.url.startsWith('/') ||
      request.url.startsWith('#') ||
      request.url.startsWith('javascript') ||
      request.url.startsWith('about:blank')
    ) {
      console.log(1, { request });
      return true;
    }

    // blocked blobs
    if (request.url.startsWith('blob')) {
      console.log(2, { request });
      return false;
    }

    // list of schemas we will allow the webview
    // to open natively
    if (
      request.url.startsWith('tel:') ||
      request.url.startsWith('mailto:') ||
      request.url.startsWith('maps:') ||
      request.url.startsWith('geo:') ||
      request.url.startsWith('sms:')
    ) {
      console.log(3, { request });
      Linking.openURL(request.url).catch(er => {
        alert('Failed to open Link: ' + er.message);
      });
      return false;
    }

    // let everything else to the webview
    return true;
  }
  return (
    <>
      {loading && !disableLoading && (
        <LoadingView
          style={{
            height: deviceHeight,
            // backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 1000,
            right: 0,
            bottom: 0,
            left: 0,
            paddingTop: 120,
          }}
        />
      )}
      <View style={[styles.container, style]}>
        {connected && (
          <WebView
            style={{
              borderTopRightRadius: Spacing.width20,
              borderTopLeftRadius: Spacing.width20,
            }}
            containerStyle={{
              borderTopRightRadius: Spacing.width20,
              borderTopLeftRadius: Spacing.width20,
            }}
            loading={loading}
            onLoadStart={() => handleSetLoading(true)}
            onLoadEnd={() => {
              console.log('onLoadEnd>>');
              handleSetLoading(false);
            }}
            onError={e => {
              console.log('onError>>>', e);
              onError(e)
            }}
            originWhitelist={['http://*', 'https://*', 'intent://*']}
            source={uri ? { uri: uri } : { html: html }}
            onShouldStartLoadWithRequest={onShouldStartLoadWithRequest}
            onNavigationStateChange={e => {
              console.log(e);
            }}
            injectedJavaScript={
              Device.isIos
                ? `
          const meta = document.createElement('meta'); 
          meta.setAttribute('content', 'width=device-width, initial-scale=${fontScale}, user-scalable=1'); 
          meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta); `
                : ''
            }
            bounces={false}
            allowFileAccess
            domStorageEnabled
            javaScriptEnabled
            geolocationEnabled
            saveFormDataDisabled
            allowFileAccessFromFileURLS
            allowUniversalAccessFromFileURLs
            setSupportMultipleWindows
          />
        )}
        {!connected && (
          <View
            style={[
              globalStyle.directionRow,
              globalStyle.center,
              globalStyle.flex1,
              { paddingHorizontal: theme.spacing.md },
            ]}>
            <AppHtmlView
              style={{
                textAlign: 'center',
                fontSize: FontSize.Font15,
              }}
              text={t('NO_INTERNET_BROWSER')}
            />
          </View>
        )}
      </View>
    </>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderTopRightRadius: Spacing.width20,
    borderTopLeftRadius: Spacing.width20,
    backgroundColor: palette.WHITE,
  },
});
