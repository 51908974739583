import {Constant, DashboardStatus, POSTING_LOCATION} from '@constant';
import {
  getMenu as getMenuRequest,
  getDashboard,
  validateReport,
  getSurvey,
  getTimeline,
  getMapSettingService,
  getListDispatchPointService,
  getDetailDispatchPointService,
  getDashboardDetail,
  getPrizeDrawDetailService,
  readUnderScoreNotificationService
} from '@services/app';
import { getReportModalService } from '@services/form';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
// import { getAllFilmsFailed, getMenuSaga, getAllFilmsSuccess } from './actions';
// import * as FilmsAPI from './apiCall';
import {
  getMenu,
  getMenuSuccess,
  getMenuFailed,
  getDashboardSuccess,
  getDashboardRequest,
  getDashboardFailed,
  getReportModalSuccess,
  getReportModalRequest,
  getReportModalFailed,
  getValidateReportRequest,
  getValidateReportFailed,
  getValidateReportSuccess,
  getSurveySuccess,
  getSurveyFailed,
  getSurveyRequest,
  getBonusPoint,
  getTimelineSuccess,
  getTimelineFailed,
  getTimelineRequest,
  getMapSettingRequest,
  getMapSettingSuccess,
  getMapSettingFailed,
  getListDispatchPointRequest,
  getListDispatchPointSuccess,
  getListDispatchPointFailed,
  getDetailDispatchPoint,
  getDetailDispatchPointSuccess,
  getDetailDispatchPointFailed,
  getDashboardDetailFailed,
  getDashboardSuccessWithLoading,
  checkImportantNotificationRequest,
  checkImportantNotificationSuccess, checkImportantNotificationFailed,
  checkStatusOfDashboardSuccess,
  checkStatusOfDashboardFailed, checkStatusOfDashboardRequest,
  getListDashboardItemRequest,
  getPrizeDrawDetailRequest, getPrizeDrawDetailSuccess, getPrizeDrawDetailFailed,
  readUnderScoreNotificationSuccess,
  readUnderScoreNotificationFailed, readUnderScoreNotificationRequest,
} from './appSlice';

export function* getMenuSaga({ payload }) {
  try {
    const res = yield call(getMenuRequest);
    if (!isEmpty(res)) {
      if (res.status) {
        yield put(getMenuSuccess(res.data));
      } else {
        throw res.message;
      }
    } else yield put(getMenuFailed(Constant.ERROR_NO_RESPONSE));
  } catch (err) {
    console.error(err);
    yield put(getMenuFailed(err));
  }
}

function* getDashboardSaga({ payload }) {
  const dashboardState = yield select(state => state.app.dashboard);

  try {
    const res = yield call(getDashboard);
    if (res.status) {
      //NOTE: tại sao cần success này.
      yield put(
        getDashboardSuccess(
          res.data.map(item => {
            const index = dashboardState?.findIndex(dash => dash?.internalId === item?.internalId);
            const data = dashboardState?.[index]?.data;
            return {
              ...item,
              ...dashboardState?.[index],
              data: data,
              // data: [],
            };
          }),
        ),
      );

      const dashboard = yield all(
        res.data.map(item => {
          return call(getDashboardDetailSaga, { internalId: item?.internalId });
        }),
      );

      yield put(getDashboardSuccessWithLoading(dashboard));
      yield put(getBonusPoint(res.bonusInfo));
    } else yield put(getDashboardFailed(res));
  } catch (err) {
    console.error(err);
    yield put(getDashboardFailed(err));
  }
}
function* getListDashboardItemSaga({ payload }) {
  yield call(getListDashboardDetailSaga, payload)
}
export function* getListDashboardDetailSaga(payload) {
  // const { internalIds } = payload;
  console.log('getListDashboardDetailSaga>>>', payload);
  const internalIds = payload.internalIds;
  if (_.isEmpty(internalIds)) {
    return;
  }
  const dashboardState = yield select(state => state.app.dashboard);
  let dashboard = _.cloneDeep(dashboardState);
  if(_.isEmpty(dashboard)){
    return
  }
  const rs = yield all(
    internalIds.map(item => {
      return call(getDashboardDetailSaga, { internalId: item });
    }),
  );
  if (_.isEmpty(rs)) {
    return;
  }
  for (const item of rs) {
    const index = dashboardState?.findIndex(dash => dash?.internalId === item?.internalId);
    dashboard[index] = item;
  }
  console.log('getListDashboardDetailSaga>>end>>>', dashboardState, rs);
  yield put(getDashboardSuccess(dashboard));
}

function* getDashboardDetailSaga(payload) {
  const { internalId } = payload;

  try {
    const res = yield call(getDashboardDetail, { internalId });
    if (res.status) {
      // throw 'test faield'
      const dashboard = yield select(state => state.app.dashboard);
      const mappingDashboard = _.cloneDeep(dashboard);
      const index = dashboard?.findIndex(item => item?.internalId === internalId);
      if (!isEmpty(mappingDashboard?.[index])) mappingDashboard[index].data = res?.data;

      return {
        ...mappingDashboard?.[index],
        show: res?.show,
        data: res?.data,
      };
    } else {
      console.error('errerrerrerrerr');
      yield put(getDashboardDetailFailed(res));
    }
  } catch (err) {
    console.error({ errerrerrerrerr: err });
    yield put(getDashboardDetailFailed(err));
  }
}

function* getSurveySaga() {
  try {
    const res = yield call(getSurvey);
    if (!isEmpty(res)) {
      if (res.status) {
        yield put(getSurveySuccess(res.data));
      } else {
        throw res.message;
      }
    } else yield put(getSurveyFailed(Constant.ERROR_NO_RESPONSE));
  } catch (err) {
    console.error(err);
    yield put(getSurveyFailed(err));
  }
}

function* CheckImportantNotificationSaga({payload, meta, error }) {
  try {
    const res = yield call(getDashboardDetail, { internalId: 'check_important_notification' });
    console.log('getListAddressSaga>>>>', res);
    if (res?.status) {
      yield put(checkImportantNotificationSuccess(res));
      if (meta) {
        yield call(meta, res);
      }
    } else {
      // @ts-ignore
      yield put(checkImportantNotificationFailed(res));
      if (error) {
        yield call(error, res);
      }
    }
  } catch (err) {
    // @ts-ignore
    yield put(checkImportantNotificationFailed(err));
    yield call(error, err);
  }
}

function* checkStatusOfDashboardSaga({payload, meta, error }) {
  try {
    const res = yield call(getDashboardDetail, { internalId: DashboardStatus.check_status });
    console.log('checkStatusOfDashboardSaga>>>>', res);
    if (res?.status) {
      yield put(checkStatusOfDashboardSuccess(res));
      if (meta) {
        yield call(meta, res);
      }
    } else {
      // @ts-ignore
      yield put(checkStatusOfDashboardFailed(res));
      if (error) {
        yield call(error, res);
      }
    }
  } catch (err) {
    // @ts-ignore
    yield put(checkStatusOfDashboardFailed(err));
    yield call(error, err);
  }
}

function* getTimelineSaga({ payload }) {
  const { type } = payload;
  try {
    const res = yield call(() => getTimeline(type));
    if (!isEmpty(res)) {
      if (res.status) {
        yield put(getTimelineSuccess(res.data));
      } else {
        throw res.message;
      }
    } else yield put(getTimelineFailed(Constant.ERROR_NO_RESPONSE));
  } catch (err) {
    console.error(err);
    yield put(getTimelineFailed(err));
  }
}

function* getReportModalSaga({ payload, meta, error }) {
  try {
    const res = yield call(getReportModalService, payload);
    if (!isEmpty(res)) {
      if (res.status) {
        yield put(getReportModalSuccess(res.data));
        if (meta) {
          yield call(meta, res);
        }
      } else {
        throw res.message;
      }
    } else yield put(getReportModalFailed(Constant.ERROR_NO_RESPONSE));
    if (error) {
      yield call(error, Constant.ERROR_NO_RESPONSE);
    }
  } catch (err) {
    console.error(err);
    yield put(getReportModalFailed(err));
    if (error) {
      yield call(error, err);
    }
  }
}

function* getMapSettingSaga({ payload, meta, error }) {
  try {
    const res = yield call(getMapSettingService, payload);
    if (!isEmpty(res) && res.status) {
      console.log('getMapSettingSaga>>>', res);

      yield put(getMapSettingSuccess(res.data));
      if (meta) {
        yield call(meta, res);
      }
    } else {
      yield put(getMapSettingFailed(Constant.ERROR_NO_RESPONSE));
      if (error) {
        yield call(error, res);
      }
    }
  } catch (err) {
    yield put(getMapSettingFailed(err));
    if (error) {
      yield call(error, err);
    }
  }
}

function* getListDispatchPointSaga({ payload, meta, error }) {
  console.log({ payload, meta, error });
  try {
    const res = yield call(getListDispatchPointService, payload);
    // const dispatchPointList = yield select(stateTemp => {
    //   return stateTemp.app.postingLocation.dispatchPointList;
    // });
    if (!isEmpty(res)) {
      if (res.status) {
        // const filteredData = _.differenceBy(res.data, dispatchPointList, 'id');
        const data = _.takeRight(res.data, POSTING_LOCATION.MARKER_AMOUNT);
        yield put(getListDispatchPointSuccess(data));
        if (meta) {
          yield call(meta, res);
        }
      } else {
        throw res.message;
      }
    } else {
      yield put(getListDispatchPointFailed(Constant.ERROR_NO_RESPONSE));
      if (error) {
        yield call(error, Constant.ERROR_NO_RESPONSE);
      }
    }
  } catch (err) {
    console.error(err);
    yield put(getListDispatchPointFailed(err));
    if (error) {
      yield call(error, err);
    }
  }
}

function* getDetailDispatchPointSaga({ payload, meta, error }) {
  console.log({ payload, meta, error });
  try {
    const res = yield call(getDetailDispatchPointService, payload);
    console.log({ resfadfsda: res });
    if (!isEmpty(res)) {
      if (res.status) {
        yield put(getDetailDispatchPointSuccess(res.data));
        if (meta) {
          yield call(meta, res);
        }
      } else {
        throw res.message;
      }
    } else {
      yield put(getDetailDispatchPointFailed(Constant.ERROR_NO_RESPONSE));
      if (error) {
        yield call(error, Constant.ERROR_NO_RESPONSE);
      }
    }
  } catch (err) {
    console.error(err);
    yield put(getDetailDispatchPointFailed(err));
    if (error) {
      yield call(error, err);
    }
  }
}

function* getValidateReportRequestSaga({ payload, meta, error }) {
  try {
    const res = yield call(validateReport, payload);
    if (!isEmpty(res)) {
      if (res.status) {
        yield put(getValidateReportSuccess(res));
        if (meta) {
          meta(res);
        }
      } else {
        yield put(getValidateReportFailed());
        throw res.message || res.data;
      }
    } else yield put(getValidateReportFailed(Constant.ERROR_NO_RESPONSE));
    if (error) {
      yield call(error, Constant.ERROR_NO_RESPONSE);
    }
  } catch (err) {
    console.error(err);
    yield put(getValidateReportFailed(err));
    if (error) {
      yield call(error, err);
    }
  }
}


export function* getPrizeDrawDetailSaga({ payload, meta, error }) {
  try {
    const res = yield call(getPrizeDrawDetailService, payload);
    console.log('getPrizeDrawDetailSaga>>>>', res);
    if (!isEmpty(res)) {
      // yield put(loginSuccess(res));
      yield put(getPrizeDrawDetailSuccess(res));
      if (meta) {
        meta(res);
      }
    } else {
      yield put(getPrizeDrawDetailFailed(res));
      if (error) {
        error(res);
      }
    }
  } catch (err) {
    yield put(getPrizeDrawDetailFailed(err));
    if (err) {
      error(err);
    }
  }
}

export function* readUnderScoreNotificationSaga({ payload, meta, error }) {
  try {
    const res = yield call(readUnderScoreNotificationService, payload);
    console.log('readUnderScoreNotificationSaga>>>>', res);
    if (!isEmpty(res)) {
      // yield put(loginSuccess(res));
      yield put(readUnderScoreNotificationSuccess(res));
      if (meta) {
        meta(res);
      }
    } else {
      yield put(readUnderScoreNotificationFailed(res));
      if (error) {
        error(res);
      }
    }
  } catch (err) {
    yield put(readUnderScoreNotificationFailed(err));
    if (err) {
      error(err);
    }
  }
}

function* appSaga() {
  yield takeLatest(getMenu.type, getMenuSaga);
  yield takeEvery(getDashboardRequest.type, getDashboardSaga);
  yield takeEvery(getReportModalRequest.type, getReportModalSaga);
  yield takeEvery(getValidateReportRequest.type, getValidateReportRequestSaga);
  yield takeEvery(getSurveyRequest.type, getSurveySaga);
  yield takeEvery(getTimelineRequest.type, getTimelineSaga);
  yield takeEvery(getMapSettingRequest.type, getMapSettingSaga);
  yield takeLatest(getListDispatchPointRequest.type, getListDispatchPointSaga);
  yield takeEvery(getDetailDispatchPoint.type, getDetailDispatchPointSaga);
  yield takeEvery(checkImportantNotificationRequest.type, CheckImportantNotificationSaga);
  yield takeEvery(checkStatusOfDashboardRequest.type, checkStatusOfDashboardSaga);
  yield takeEvery(getListDashboardItemRequest.type, getListDashboardItemSaga)
  yield takeEvery(getPrizeDrawDetailRequest.type, getPrizeDrawDetailSaga);
  yield takeEvery(readUnderScoreNotificationRequest.type, readUnderScoreNotificationSaga);
}

export default appSaga;
