import AsyncStorage from '@react-native-async-storage/async-storage';
import filmsSlice from '@redux/ghibli/filmsSlice';
import { persistCombineReducers, persistReducer } from 'redux-persist';
import { addressReducer } from './address/reducer';
import appSlice from './app/appSlice';
import authSlice from './auth/authSlice';
import commonSlice from './common/commonSlice';
import { fileReducer } from './file/reducer';
import { formReducer } from './form/reducer';
import { globalReducer } from './global/reducer';
import { holidayReducer } from './holiday/reducer';
import { quizzesReducer } from './quizzes/reducer';
import { ticketReducer } from './ticket/reducer';
import { userReducer } from './user/reducer';
import { versionReducer } from './version/reducer';
import { bundleReducer } from './bundle/reducer';
import { iCodeReducer } from './iCode/reducer';
import { packageReducer } from './package/reducer';
import { languageReducer } from './language/reducer';
import { surveyReducer } from './survey/reducer';
import { rfidReducer } from './rfid/reducer';
import { notificationReducer } from './notification/reducer';
import { ReimbursementReducer } from './Reimbursement/reducer';
import { postingLocationsReducer } from './postingLocations/reducer';


const authPersistConfig = {
  key: 'auth',
  storage: AsyncStorage,
  whitelist: ['user', 'forgotForm'],
};
const appPersistConfig = {
  key: 'app',
  storage: AsyncStorage,
  whitelist: ['isFirstTimeInApp', 'dashboard', 'dashboardDetail', 'menus', 'reportModal'],
};

const reducers = {
  films: filmsSlice,
  common: commonSlice,
  auth: persistReducer(authPersistConfig, authSlice),
  app: persistReducer(appPersistConfig, appSlice),
  ...formReducer,
  ...versionReducer,
  ...userReducer,
  ...quizzesReducer,
  ...addressReducer,
  ...globalReducer,
  ...holidayReducer,
  ...ticketReducer,
  ...fileReducer,
  ...bundleReducer,
  ...iCodeReducer,
  ...packageReducer,
  ...languageReducer,
  ...surveyReducer,
  ...rfidReducer,
  ...notificationReducer,
  ...ReimbursementReducer,
  ...postingLocationsReducer,
};
// const reducers = combineReducers({ version });

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  // There is an issue in the source code of redux-persist (default setTimeout does not cleaning)
  timeout: undefined,
  whitelist: ['get_languages'],
  // blacklist: ['getForm', 'getUser', 'activeUser', 'forceUpdate', 'global', 'updateUser', 'auth'],
};

// Setup Reducers
export const persistedRootReducer = persistCombineReducers(persistConfig, reducers);

export type RootState = ReturnType<typeof persistedRootReducer>;

export default persistedRootReducer;
