import queryString from 'query-string';
import { Platform } from 'react-native';
import {DeeplinkHelper, DeeplinkType} from "@utils/common/deeplink";

export const LinkingHelper = {
  isForceOpen: () => {
    if (Platform.OS !== 'web') {
      return false;
    }
    const urlParams = queryString.parse(window?.location?.search);
    console.log('isForceOpen>>>', urlParams);
    if (urlParams?.jwt) {
      return true;
    }
    if (urlParams?.webLink) {
      return true;
    }
    return false;
  },
  isWebDeeplink: () => {
    if (Platform.OS !== 'web') {
      return false;
    }

    const pathname = window?.location?.pathname
    return pathname?.includes(DeeplinkType.webDeeplink.pattern)
  },
  isEncoder: url => {
    return decodeURI(url) !== url;
  },
  getParamUrl: url => {
    const isDecoder = LinkingHelper.isEncoder(url);
    return queryString.parse(url, { decode: isDecoder });
  }
};
