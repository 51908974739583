import { REPORT_TYPE } from '@constant';
import { Config } from '@constant/config';
import { ReportBundleMockData } from '@constant/model/mock/report/bundle';
import { ReportICodeMockData } from '@constant/model/mock/report/iCode';
import { ReportPackageMockData } from '@constant/model/mock/report/package';
import { useRoute } from '@react-navigation/native';
import { getValidateReportRequest } from '@redux/app/appSlice';
import { reportModal as reportModalSelector } from '@redux/app/selectors';
import { loginRequest } from '@redux/auth/authSlice';
import { getICodeFormRequest } from '@redux/form/getICodeFormSlice';
import { getICodeFormCreator } from '@redux/form/selectors';
import { submitICodeRequest } from '@redux/iCode/submitICodeSlice';
import {goBack, navigate, popToTop} from '@routes/navigationUtils';
import { ReportModalTransform } from '@utils';
import { checkFormValidateExpression, getInitialFormValue, getYupStructure } from '@utils/form';
import { useLocation } from '@utils/hooks';
import { mappingStep } from '@utils/transform/form';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { lazy } from 'yup';
import * as Yup from 'yup';
import {Screens} from "@routes/route";
import {hideLoading, showLoading} from "@redux/global/globalSlice";

export const useReportICodeDetail = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { lat, long, loading } = useLocation(props);
  const [layoutInfo, setLayoutInfo] = useState({});
  const scrollViewRef = useRef(null);

  const paramsData = useRoute().params?.data;
  const paramsX = useRoute().params;

  const info = {
    id: paramsX?.id,
  };
  const formModal = useSelector(reportModalSelector);
  const headerTitle = ReportModalTransform.getReportTitle(paramsData?.reportType, formModal);
  const getICodeFormData = useSelector(getICodeFormCreator)?.data;
  // const rawData = mappingStep(ReportICodeMockData.form);
  // const rawData = mappingStep(!_.isEmpty(getICodeFormData) ? getICodeFormData[0] : null);

  const [data, setData] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const lo = Config.IsMockServer ? ReportBundleMockData.userLocation : {};
  const [userLocation, setUserLocation] = useState(lo);
  const [point, setPoint] = useState(0);
  const [summary, setSummary] = useState({
    ...info,
  });
  const [messageInfo, setMessageInfo] = useState({});

  const [loginInfo, setLoginInfo] = useState({})

  const scrollToY = y => {
    // scrollViewRef.current.scroll
    scrollViewRef.current?.scrollTo({
      x: 0,
      y: y,
      animated: true,
    });
  };

  const loginApi = () => {
    console.log('loginApi>>>', paramsX);
    dispatch(
      loginRequest(
        {
          jwt: paramsX?.jwt,
        },
        rs => {
          if (!rs.status) {
            window.parent.postMessage(
              JSON.stringify({
                message: 'panelist_not_found',
                status: true,
              }),
              paramsX?.host,
            );
            return;
          }
          console.log('loginApi>>>rs>>', rs);
          window.parent.postMessage(
            {
              status: true,
              message: 'login_success',
            },
            paramsX?.host,
          );
          setLoginInfo(rs?.data);
          validateReportApi(rs?.data);
        },
        err => {
          window.parent.postMessage(JSON.stringify(err), paramsX?.host);
        },
      ),
    );
  };

  const validateReportApi = (payload) => {
    dispatch(
      getValidateReportRequest(
        {
          type: REPORT_TYPE.icodes,
          id: paramsX?.id,
          changeBy: payload?.changeBy,
          changeType: payload?.changeType,
        },
        rs => {
          console.log('getValidateReport>>>response', rs);
          //TODO: apply data to form
          // handleNavigateReportForm(rs, REPORT_TYPE.icodes, data?.icode);
          setSummary({
            ...summary,
            // reportDetail: rs.
            ...rs?.data,
            total: rs?.data?.count_testitems,
            date: rs?.data?.target_dispatch_date,
          });
          getICodeFormApi(rs);
        },
        err => {},
      ),
    );
  };
  const getICodeFormApi = (_payload) => {
    const payload = {
      s_id: _payload?.s_id,
    };
    // dispatch(getICodeFormRequest(payload));
    dispatch(showLoading());
    dispatch(
        getICodeFormRequest(
            payload,
            res => {
              dispatch(hideLoading());
            },
            err => {
              dispatch(hideLoading());
            },
        ),
    );
  };

  const formik = useFormik({
    initialValues: getInitialFormValue(data, summary?.reportDetail),
    enableReinitialize: true,
    // validationSchema: Yup.object().shape(getYupStructure(rawData, t)),
    validationSchema: () =>
      lazy(values => {
        // console.log('lazy>>>values>>>', values);
        const ys = getYupStructure(data, t, 'attributes', values);
        console.log('getYupStructure>>>', ys, formik);
        return Yup.object().shape(ys);
      }),
    initialErrors: false,

    onSubmit: (values, { setErrors, resetForm }) => {
      console.log('onSubmit>>>', values, formik.errors, data);
      const rs = checkFormValidateExpression(data?.attributes, values, setErrors);
      if (!_.isEmpty(rs)) {
        formik.setErrors(_.merge(formik.errors, rs));
        return;
      }

      handleSubmit(values);

      //TODO: validate here
      // updateUserApi(values, isLastStep);
      // onSavePress();
    },
  });

  const submitICodeApi = payload => {
    /*
  params:
  {
      "Token": "5aa5ce7dafc519e7a16d222a436938c40624606a24b70e8168572adab8c5703d",
      "Accept": "application/json",
      "spectos-apikey": "{{spectos-apikey}}"
  }
  body:
  {
      "receipt_date_known": true,
      "receipt_date": "2022-09-08",
      "receipt_date_sure": true,
      "receipt_time_known": true,
      "receipt_time": "14:30",
      "receipt_time_sure": true,
      "franking_value": "0.55",
      "user_location": ["51.5","0.2"]
  }
   */
    // const payload = {}
    if (data?.canReview) {
      navigate(Screens.ICODE_FORM_REVIEW_SCREEN, {
        data: _.cloneDeep(data),
        values: _.cloneDeep(payload),
        header: {
          title: headerTitle,
        },
      });
      return;
    }
    dispatch(
      submitICodeRequest(
        payload,
        rs => {
          handleSuccess(rs);
        },
        err => {},
      ),
    );
  };

  const handleSuccess = rs => {
    //TODO: reload api dashboard here
    setPoint(rs?.data?.point ?? 0);
    // setMessageInfo(rs?.message)
    // setIsSuccess(true);
    navigate(Screens.SUCCESSFUL_SCREEN, {
      data: rs?.message,
      header: {
        title: headerTitle,
        backClick: () => {
          // popToTop();
        },
      },
    });

    // setTimeout(() => {
    //   popToTop();
    // }, 5000);
  };

  const handleSubmit = values => {
    let payload = {
      ...values,
      id: paramsX?.id,
      s_id: paramsData?.payload?.s_id,
      changeBy: loginInfo?.changeBy,
      changeType: loginInfo?.changeType,
    };
    if (!_.isEmpty(userLocation) && !!userLocation?.longitude) {
      payload.user_location = Object.values(userLocation);
    } else {
      payload.user_location = null;
    }
    const mock = Config.IsMockServer;
    if (mock) {
      handleSuccess();
    } else {
      submitICodeApi(payload);
    }
  };

  console.log('useReportPackageForm>>>', formik);
  useEffect(() => {
    // getICodeFormApi();
    loginApi()
    console.log('useEffect>>>>')
  }, [dispatch]);

  useEffect(() => {
    const x = _.cloneDeep(getICodeFormData);
    const rd = mappingStep(!_.isEmpty(x) ? x[0] : null);
    setData(rd);
  }, [getICodeFormData]);

  useEffect(() => {
    setUserLocation({
      latitude: lat,
      longitude: long,
    });
    console.log('location>>>', lat, long);
  }, [lat, long]);

  return { formik, data, summary, isSuccess, point, headerTitle, messageInfo,
    scrollViewRef, layoutInfo, setLayoutInfo, scrollToY };
};
