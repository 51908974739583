import { Constant, NOTIFICATION, REPORT_TYPE } from '@constant';
import { showGlobalModal } from '@redux/common/commonSlice';
import { getRFIDFormRequest } from '@redux/form/getRFIDFormSlice';
import { store } from '@redux/store';
import { isFirstTimeApp, navigate, navigatePush } from '@routes/navigationUtils';
import { DIGIT, Screens } from '@routes/route';
import { getAccessToken, getPanelistId } from '@utils';
import { DeeplinkHelper, DeeplinkType } from '@utils/common/deeplink';
import { NotificationActionHelper } from '@utils/notification/action';
import _ from 'lodash';
import moment from "moment";
import {t} from 'i18next'

export const NotificationHelper = {
  redirectRFIDScreenFromNotification: data => {
    const payload = {
      ...data,
      panelistId: getPanelistId(),
    };
    delete payload.message;
    delete payload.canDelete;
    // navigatePush(Screens.RFID_FORM, { data: data });

    store.dispatch(
        getRFIDFormRequest(
            payload,
            res => {
              navigate(Screens.RFID_FORM, {
                data: data
              });
            },
            err => {},
        ),
    );
  },
  redirectD2DScreenFromNotification: (data) => {
    const category = data?.category
    if(_.isEmpty(category)){
      return
    }
    switch (category){
      case Constant.notification_category.d2d.overdue_items:
        navigate(Screens.D2D_OVER_DUE, {
          data: {
            key: Constant.d2d.overdue_items,
            name: t('D2D_OVERDUE_ITEMS_TITLE')
          }
        });
        break;
      case Constant.notification_category.d2d.due_items:
        navigate(Screens.D2D_OVER_DUE, {
          data: {
            key: Constant.d2d.due_items,
            name: t('D2D_DUE_ITEMS_TITLE')
          }
        });
        break;
      case Constant.notification_category.d2d.daily_items:
        navigate(Screens.D2D_DAILY_ITEM, {
          data: {
            key: Constant.d2d.daily_items,
            name: t('D2D_DAILY_ITEMS_TITLE')

          }
        });
        break;
      case Constant.notification_category.d2d.more_than_once:
        navigate(Screens.D2D_OVER_DUE, {
          data: {
            key: Constant.d2d.more_than_once,
            name: t('D2D_MORE_THAN_ONCE_TITLE')
          }
        });
        break;
    }
  },
  redirectScreenFromNotification: (data) => {
    const category = data?.category
    if(_.isEmpty(category)){
      return
    }
    if([Constant.notification_category.d2d.overdue_items,
      Constant.notification_category.d2d.due_items,
      Constant.notification_category.d2d.daily_items,
      Constant.notification_category.d2d.more_than_once].includes(category)){
      NotificationHelper.redirectD2DScreenFromNotification(data)
    }
    else{
      NotificationHelper.redirectRFIDScreenFromNotification(data)
    }

  },
  openNotification: (data, duration = 100) => {
    // setTimeout(x => )
    if (_.isEmpty(data?.category)) {
      return;
    }
    // const isLogin = getAccessToken();
    // if (_.isEmpty(isLogin)) {
    //   return;
    // }
    // setTimeout(() => {
    //   navigatePush(Screens.RFID_FORM, { data: data });
    // }, duration);
    // NotificationHelper.handleOpenScreen(Screens.RFID_FORM, duration, data)

    NotificationHelper.handleOpenNotification(duration, () => {
      NotificationHelper.redirectScreenFromNotification(data)
    });
  },
  handleOpenScreen: (screenKey, duration, data) => {
    const isLogin = getAccessToken();
    if (_.isEmpty(isLogin)) {
      return;
    }
    setTimeout(() => {
      navigatePush(screenKey, { data: data });
    }, duration);
  },
  handleOpenNotification: (duration, callback, ignoreLogin = false) => {
    if (!ignoreLogin) {
      const isLogin = getAccessToken();
      if (_.isEmpty(isLogin)) {
        return;
      }
    }
    setTimeout(() => {
      // navigatePush(screenKey, { data: data });
      if (callback) {
        callback();
      }
    }, duration);
  },
  getActionsData: notification => {
    const data = notification?.data;
    let msg = '';
    const root = data?.root
    if (!_.isEmpty(root) && _.isObject(root)) {
      return root?.actions ?? []
    }
    const custom = data?.custom;
    if (_.isEmpty(custom)) {
      return [];
    }
    let actions = [];
    //NOTE: data.root is json string -> should parse to object
    if (typeof custom === 'string') {
      actions = JSON.parse(custom)?.root?.actions;
    } else {
      actions = custom?.root.actions ?? [];
    }
    return actions;
  },
  openNotificationWithAction: (actionType, notification, isBackground = false) => {
    console.log('openNotificationWithAction>>>notification>>>', notification)
    console.log('openNotificationWithAction>>>actionType>>>', actionType)

    if (_.isEmpty(actionType)) {
      return;
    }
    const data = notification?.data;
    const root = data?.root;
    const custom = data?.custom;
    if (_.isEmpty(root) && _.isEmpty(custom)) {
      //TODO: handle case with notification has category
      const duration = DeeplinkHelper.getDurationDelay(DeeplinkType.notifications.key, isFirstTimeApp.current, true);
      console.log('without_action>>>', duration)
      NotificationHelper.handleOpenNotification(duration, () => {
        NotificationHelper.redirectScreenFromNotification(data)
      });
      return;
    }
    // let actions = [];
    // //NOTE: data.root is json string -> should parse to object
    // if (typeof root === 'string') {
    //   actions = JSON.parse(root)?.actions;
    // } else {
    //   actions = root.actions ?? [];
    // }
    const actions = NotificationHelper.getActionsData(notification);
    const duration = DeeplinkHelper.getDurationDelay(DeeplinkType.notifications.key, isFirstTimeApp.current, true);
    NotificationHelper.openNotificationAction(actions, actionType, duration, false);
  },
  openNotificationAction: (actions, actionType, duration = 3500, isForceDuration = false, onSuccess = null) => {
    let msg = '';
    setTimeout(() => {
      //NOTE: call api url
      NotificationActionHelper.callApiActionUrlWithData(actionType, actions, onSuccess);
    }, duration);
    switch (actionType) {
      case NOTIFICATION.action.fill_report:
        console.log('navigatePush(DIGIT>>>>');
        NotificationHelper.handleOpenNotification(
          duration,
          () => {
            //TODO: call notification url here
            const dataAction = actions?.find(x => x.id === actionType);
            const report_type = dataAction?.data?.report_type;
            const itemId = dataAction?.data?.itemId;
            switch (report_type) {
              case REPORT_TYPE.icodes:
                navigate(DIGIT, { cellCount: 7, type: Constant.menu.report_test_items, itemId });
                return;
              case REPORT_TYPE.packages:
                navigate(DIGIT, { cellCount: 5, type: Constant.menu.posting_package, itemId });
                return;
              case REPORT_TYPE.bundles:
                navigate(DIGIT, { cellCount: 6, type: Constant.menu.report_bundle_dispatch, itemId });
                return;
              default:
                navigatePush(DIGIT, { cellCount: 6, type: Constant.menu.report_bundle_dispatch, itemId });
                break;
            }
          },
          false,
        );

        break;
      case NOTIFICATION.action.not_send_today:
        //TODO: show alert
        console.log('openNotificationWithAction>>>', actions);
        msg = actions?.find(x => x.id === actionType)?.data?.message;
        if (_.isEmpty(msg)) {
          return;
        }
        NotificationHelper.handleOpenNotification(
          //NOTE: should open popup < 1500 ms
          isForceDuration ? duration : duration > 3000 ? 1500 : duration,
          () => {
            store.dispatch(
              showGlobalModal({
                title: '',
                description: msg,
              }),
            );
          },
          false,
        );

        break;
    }
  },
  openChangeEmail: (data, duration = 100) => {
    // setTimeout(x => )
    //https://mailagents.uk/changeEmail?reset_token=
    if (_.isEmpty(data?.reset_token)) {
      return;
    }
    //TODO: call api => then logout to login if success.
  },

  filterNotification : (data, duration = 5000) => {
    if(_.isEmpty(data)){
      return []
    }
    return data.filter(x => !_.isEmpty( x.title) || !_.isEmpty( x.body))?.filter( x => moment().diff(moment(x.createdDate), 'milliseconds') < duration)
  }
};
