import { Constant, TICKET_STATUS } from '@constant';
import {
  hideListNotiActionModal,
} from '@redux/common/commonSlice';
import {
  listNotiActionModalSelector,
  listNotiActionModalVisible,
} from '@redux/common/selectors';
import { navigate } from '@routes/navigationUtils';
import { Screens, SURVEY_LIST_SCREEN } from '@routes/route';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {NotificationHelper} from "@utils/common/notification";
import {getListDashboardItemRequest} from "@redux/app/appSlice";

export const useListNotiActionModal = props => {
  const dispatch = useDispatch();
  const modalVisible = useSelector(listNotiActionModalVisible);
  const modalData = useSelector(listNotiActionModalSelector);
  console.log('showNewsModal>>>modalVisible>>>', modalVisible);
  console.log('showNewsModal>>>modalData>>>', modalData);

  // const data = useSelector()
  const handlePressButton = () => {
    modalVisible && dispatch(hideListNotiActionModal());
    //TODO: open setting
  };
  const handleHideModal = () => {
    modalVisible && dispatch(hideListNotiActionModal());
  };

  const onItemPress = item => {
    console.log('onItemPress>>>', item);
    const actions = modalData?.data?.actions
    const actionType = item.id
    // const popupTitle = item.popupTitle
    NotificationHelper.openNotificationAction(actions, actionType, 0, true, rs => {
      dispatch(
        getListDashboardItemRequest({
          internalIds: [Constant.dashboard.notification],
        }),
      );
    });
    handleHideModal();
  };
  return { handlePressButton, handleHideModal, modalData, onItemPress };
};
