import api from '@services/api-client';
import { stringify } from 'qs';

export const addFavouriteLocationService = async payload => {
  console.log('addFavouriteLocationService>>>', payload);
  const { id, bundleId } = payload;
  const response = await api.post(`/posting-locations/favourite/${id}`, {
    bundleId,
    type: 'add',
  });

  console.log('addFavouriteLocationService response>>>', response);
  return response?.data;
};

export const deleteFavouriteLocationService = async payload => {
  console.log('deleteFavouriteLocationService>>>', payload);
  const { id, bundleId } = payload;
  const response = await api.post(`/posting-locations/favourite/${id}`, {
    bundleId,
    type: 'delete',
  });

  console.log('deleteFavouriteLocationService response>>>', response);
  return response?.data;
};
