import { hideLoading } from '@redux/global/globalSlice';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Geolocation from 'react-native-geolocation-service';
import { useDispatch, useSelector } from 'react-redux';
import {useRoute} from "@react-navigation/native";
import {getListDashboardItemRequest, readUnderScoreNotificationRequest} from "@redux/app/appSlice";
import {goBack} from "@routes/navigationUtils";
import {Constant} from "@constant";

export const useNotificationDetail = (props) => {
  const dispatch = useDispatch();
  const data = useRoute().params?.data;
  const html = data?.messageFullText;
  // const html = '<p>We can see that your account is not up to date Please open this notification to see the outstanding actions we need you to process as soon as possible.</p>\n' +
  //     '                <p>Your outstanding items to post are:</p>\n' +
  //     '                <p>C06574,C82823,D16829</p>\n' +
  //     '                <p>Please send these as soon as possible remembering to enter the actual time and date of posting and no more than 2 bundles each day.</p>\n' +
  //     '                <a href="https://mailagents.uk/wp-content/uploads/2024/04/PR_Posting_stamped_test_items_at_Royal_Mail_postboxes_-_RMG_1080p-edit.mp4" target="_blank">Link Video</a>\n' +
  //     '                \n' +
  //     '                <p>0%</p>\n' +
  //     '                <p>Your mobile verification for your items is low. Please ensure you are entering your items at the posting location of choice with your data and location services on.</p>\n' +
  //     '                '

  const onConfirmPress = () => {
    const payload = {
      id: data?.id,
    };
    dispatch(
      readUnderScoreNotificationRequest(
        payload,
        res => {
          //TODO: refresh notification
          dispatch(
            getListDashboardItemRequest({
              internalIds: [Constant.dashboard.notification],
            }),
          );
          goBack();
        },
        err => {

        },
      ),
    );
  }
  return {
    html,
    onConfirmPress
  };
};
