import { AppButton, AppSafeAreaView } from '@components';
import { ForceUpdateStyles } from '@components/AppForceUpdate/ForceUpdate.style';
import GenericHeader from '@components/GenericHeader';
import { goBack, navigate } from '@routes/navigationUtils';
import { MENU_WEB_VIEW_SCREEN, Screens, WEB_VIEW_SCREEN_FULLSCREEN } from '@routes/route';
import { WebView } from '@screens';
import { AppHtmlView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
// import { styles } from '@screens/main/PostingLocation/PostingLocation.style';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle } from '@theme';
import { FontSize } from '@theme/size';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, ScrollView, StatusBar, View } from 'react-native';
import { useNotificationDetail } from './hook';
import { styles } from './styles';

export const NotificationDetailScreen = props => {
  const { html, onConfirmPress } = useNotificationDetail();
  const { t } = useTranslation();

  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={styles.areaStyle}>
        <GenericHeader
          title={t('Detail')}
          style={[{ backgroundColor: '#EDEDED' }]}
          onBackClicked={() => {
            goBack();
          }}
        />
        <View style={[globalStyle.directionColumm, RegisterFormStyles.containerView]}>
          <ScrollView>
            <AppHtmlView
              style={{ margin: theme.spacing.md, flex: 1, fontSize: FontSize.Font16 }}
              text={html}
              onClickHref={link => {
                // Linking.openURL(link);
                navigate(Screens.WEB_SCREEN, { uri: link, header: 'Video', disableLoading: true });
              }}
            />
          </ScrollView>
          <View style={[ForceUpdateStyles.row, { margin: theme.spacing.lg }]}>
            <AppButton text={t('CONFIRM')} onPress={onConfirmPress} />
          </View>
        </View>
      </AppSafeAreaView>
    </>
  );
};
