// MAIN STACK

export const MAIN = 'MAIN';
export const HOME_SCREEN = 'HOME_SCREEN';
export const DIGIT = 'DIGIT';

//AUTH STACK
export const AUTH = 'AUTH';
export const LOGIN_SCREEN = 'LOGIN_SCREEN';
export const SIGN_UP_WITH_NAME_SCREEN = 'SIGN_UP_WITH_NAME_SCREEN';
export const SCAN_QR_SCREEN = 'SCAN_QR_SCREEN';
export const FORGOT_SEND_EMAIL = 'FORGOT_SEND_EMAIL';
export const FORGOT_CHANGE_PASS = 'FORGOT_CHANGE_PASS';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';

//BOTTOM TAB
export const BOTTOM_TAB = 'BOTTOM_TAB';
export const DASHBOARD_TAB = 'DASHBOARD_TAB';
export const REPORT_TAB = 'REPORT_TAB';
export const SCAN_QR_TAB = 'SCAN_QR_TAB';
export const FAQ_TAB = 'FAQ_TAB';
export const MENU_TAB = 'MENU_TAB';
export const WEB_VIEW_SCREEN_FULLSCREEN = 'MENU_WEB_VIEW_SCREEN_FULLSCREEN';

//BOTTOM TAB STACK
export const MENU_STACK = 'MENU_STACK';
export const MENU_SCREEN = 'MENU_SCREEN';
export const MENU_WEB_VIEW_SCREEN = 'MENU_WEB_VIEW_SCREEN';
export const PROFILE_EDIT_SCREEN = 'PROFILE_EDIT_SCREEN';

export const DASHBOARD_STACK = 'DASHBOARD_STACK';
export const DASHBOARD_SCREEN = 'DASHBOARD_SCREEN';
export const LIST_QUIZ_SCREEN = 'LIST_QUIZ_SCREEN';
export const SURVEY_LIST_SCREEN = 'SURVEY_LIST_SCREEN';

export const FAQ_STACK = 'FAQ_STACK';
export const FAQ_SCREEN = 'FAQ_SCREEN';

export const Screens = {
  AUTH: 'AUTH',
  LOGIN_SCREEN: 'LOGIN_SCREEN',
  SIGN_UP_WITH_NAME_SCREEN: 'SIGN_UP_WITH_NAME_SCREEN',
  SCAN_QR_SCREEN: 'SCAN_QR_SCREEN',
  SIGN_UP_SEND_EMAIL: 'SIGN_UP_SEND_EMAIL',
  REGISTER_FORM_SCREEN: 'REGISTER_FORM_SCREEN',
  REGISTER_COMPLETED_SCREEN: 'REGISTER_COMPLETED_SCREEN',
  REGISTER_FORM_STACK: 'REGISTER_FORM_STACK',
  ACTIVE_SUCCESSFUL_SCREEN: 'ACTIVE_SUCCESSFUL_SCREEN',
  SUCCESSFUL_SCREEN: 'SUCCESSFUL_SCREEN',
  FORGOT_CHANGE_PASS: 'FORGOT_CHANGE_PASS',
  SPLASH_SCREEN: 'SPLASH_SCREEN',
  LIST_QUIZ_SCREEN: 'LIST_QUIZ_SCREEN',
  QUIZ_DETAIL_SCREEN: 'QUIZ_DETAIL_SCREEN',
  QUIZ_SUCCESSFUL_SCREEN: 'QUIZ_SUCCESSFUL_SCREEN',
  QUIZ_FAILED_SCREEN: 'QUIZ_FAILED_SCREEN',
  QUIZ_COMPLETED_SCREEN: 'QUIZ_COMPLETED_SCREEN',
  PROFILE_FORM_SCREEN: 'PROFILE_FORM_SCREEN',
  SEARCH_ADDRESS_SCREEN: 'SEARCH_ADDRESS_SCREEN',
  SEARCH_POSTCODE_SCREEN: 'SEARCH_POSTCODE_SCREEN',
  LIST_REPORT_SCREEN: 'LIST_REPORT_SCREEN',
  ADD_REPORT_SCREEN: 'ADD_REPORT_SCREEN',
  REPORT_SCREEN: 'REPORT_SCREEN',
  LIST_TICKET_SCREEN: 'LIST_TICKET_SCREEN',
  ADD_TICKET_SCREEN: 'ADD_TICKET_SCREEN',
  TICKET_SCREEN: 'TICKET_SCREEN',
  LOGIN_SCREEN_VERTICAL: 'LOGIN_SCREEN_VERTICAL',
  UNSUBSCRIBE_SCREEN: 'UNSUBSCRIBE_SCREEN',
  UNSUBSCRIBE_SUCCESSFUL_SCREEN: 'UNSUBSCRIBE_SUCCESSFUL_SCREEN',
  TICKET_DETAIL_SCREEN: 'TICKET_DETAIL_SCREEN',
  REPORT_PACKAGE_FORM_SCREEN: 'REPORT_PACKAGE_FORM_SCREEN',
  REPORT_ICODE_FORM_SCREEN: 'REPORT_ICODE_FORM_SCREEN',
  REPORT_BUNDLE_FORM_SCREEN: 'REPORT_BUNDLE_FORM_SCREEN',
  CDS_FORM_SCREEN: 'CDS_FORM_SCREEN',
  CDS_SUBFORM_SCREEN: 'CDS_SUBFORM_SCREEN',
  CDS_DETAIL_SCREEN: 'CDS_DETAIL_SCREEN',
  UNSURE_DATE_LIST_SCREEN: 'UNSURE_DATE_LIST_SCREEN',
  D2D_OVERVIEW: 'D2D_OVERVIEW',
  D2D_OVER_DUE: 'D2D_OVER_DUE',
  D2D_OVER_DUE_GROUP: 'D2D_OVER_DUE_GROUP',
  D2D_DAILY_ITEM: 'D2D_DAILY_ITEM',
  D2D_FORM: 'D2D_FORM',
  NOTIFICATION_SETTING: 'NOTIFICATION_SETTING',
  NOTIFICATIONS: 'NOTIFICATIONS',
  HISTORY_POINTS: 'HISTORY_POINTS',
  REWARD: 'REWARD',
  VOUCHER: 'VOUCHER',
  RFID_FORM: 'RFID_FORM',
  CDM_FORM_SCREEN: 'CDM_FORM_SCREEN',
  HOW_TO_FORM: 'HOW_TO_FORM',
  TIMELINE: 'TIMELINE',
  REPORT_PACKAGE_DETAIL_SCREEN: 'REPORT_PACKAGE_DETAIL_SCREEN',
  REPORT_ICODE_DETAIL_SCREEN: 'REPORT_ICODE_DETAIL_SCREEN',
  REPORT_BUNDLE_DETAIL_SCREEN: 'REPORT_BUNDLE_DETAIL_SCREEN',
  BUNDLE_TIMELINE:'BUNDLE_TIMELINE',
  PACKAGE_TIMELINE:'PACKAGE_TIMELINE',
  POSTING_LOCATION: 'POSTING_LOCATION',
  SCAN_CODE_SCREEN: 'SCAN_CODE_SCREEN',
  INPUT_SCAN_CODE_SCREEN: 'INPUT_SCAN_CODE_SCREEN',
  TRANSPONDER_FROM_SCREEN: 'TRANSPONDER_FROM_SCREEN',
  ICODE_FORM_REVIEW_SCREEN: 'ICODE_FORM_REVIEW_SCREEN',
  REIMBURSEMENT_SCREEN: 'REIMBURSEMENT_SCREEN',
  VERIFY_MAP_SCREEN: 'VERIFY_MAP_SCREEN',
  PRIZE_DRAW_SCREEN: 'PRIZE_DRAW_SCREEN',
  NOTIFICATION_DETAIL: 'NOTIFICATION_DETAIL',
  WEB_SCREEN: 'WEB_SCREEN',
};
