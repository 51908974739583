import theme, { WEB_WIDTH } from '@theme';
import { deviceWidth } from '@theme/size';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, useWindowDimensions, View } from 'react-native';
import { Tooltip } from 'react-native-elements';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { AppText } from '../AppText/AppText';

export const AppTooltip = ({ popover }) => {
  const widthScreen = Platform.OS === 'web' ? WEB_WIDTH : deviceWidth;
  const [hSize, setHSize] = useState(150);
  const scaleFont = useWindowDimensions().fontScale;

  const { t } = useTranslation();
  const popoverClone = React.cloneElement(popover, {
    onLayout: e => {
      setHSize(e.nativeEvent.layout.height + 36 * scaleFont);
    },
  });

  console.log('AppTooltip>>>popoverClone>>>', popoverClone);
  return (
    <Tooltip
      withPointer={false}
      skipAndroidStatusBar={true}
      width={widthScreen}
      height={Platform.OS === 'web' ? undefined : hSize}
      containerStyle={{
        left: 0,
        borderColor: '#C6C6C6',
        borderWidth: 1,
        backgroundColor: '#FFFFFF',
        marginTop: theme.spacing.md,
        marginLeft: 32 + (deviceWidth - widthScreen) / 2,
        width: widthScreen - 32 * 2,
        // height: hSize,
        // elevation:10,
        shadowColor: '#000000',
        shadowOpacity: 0.6,
        shadowRadius: 3,
        shadowOffset: {
          height: 1,
          width: 1,
        },
      }}
      popover={popoverClone}>
      <View
        style={[
          {
            marginHorizontal: theme.spacing.md,
          },
        ]}>
        <AppText>
          {t('BUNDLE_FAVOURITE_TITLE_1')} <FontAwesome5 name={'star'} size={24} /> {t('BUNDLE_FAVOURITE_TITLE_2')}
        </AppText>
      </View>
    </Tooltip>
  );
};
