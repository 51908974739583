import { Constant } from '@constant';
import _ from 'lodash';

export const DashboardHelper = {
  getSourceLinkAmount: dashboard => {
    if (_.isEmpty(dashboard)) {
      return [];
    }
    const rs = [];
    for (const dashboardElement of dashboard) {
      if (dashboardElement.type === 'list') {
        const data = dashboardElement.data;
        if (!_.isEmpty(data)) {
          rs.push(...data);
        }
      } else if (dashboardElement.type === 'notification') {
        const data = dashboardElement?.data?.statistics;
        if (!_.isEmpty(data)) {
          rs.push(...data);
        }
      }
    }
    console.log('getSourceLinkAmount>>>', rs);
    return rs;
    // const x = dashboard.filter(x => x.type === 'list')
  },
  getFillAmountData: (data, dashboard) => {
    if (_.isEmpty(data)) {
      return [];
    }
    const rs = [];
    // const sourceLinkData =
    // const x = [].filter(x => x.type === 'list');
    const statistics = DashboardHelper.getSourceLinkAmount(dashboard);
    for (const item of data) {
      const amount = item?.amount;
      const internalId = item?.internalId;
      if (internalId !== Constant.dashboard.quizzes) {
        if (amount === 0) {
          continue;
        }
      }

      // console.log('amount>>>', amount, typeof amount)
      if (typeof amount === 'string' && amount?.includes('{') && amount?.includes('}')) {
        const linkInternalId = amount.replace('{', '').replace('}', '');
        // console.log('linkInternalId>>>', linkInternalId)
        if (_.isEmpty(statistics)) {
          continue;
        }

        const findItem = _.find(statistics, x => x.internalId === linkInternalId);
        console.log('linkInternalId>>>', linkInternalId, findItem);
        if (_.isEmpty(findItem)) {
          continue;
        }
        const cloneItem = _.cloneDeep(item);
        cloneItem.amount = findItem.amount;
        console.log('cloneItem.amount>>>>', cloneItem.amount)
        if(cloneItem.amount > 0){
          rs.push(cloneItem);
        }
      } else {
        rs.push(item);
      }
    }
    // console.log('getListData>>>rs>>>', rs);

    return rs;
  },
  getListData: (data, statistics) => {
    console.log('getListData>>>data>>>', data);
    // console.log('getListData>>>statistics>>>', statistics);

    if (_.isEmpty(data)) {
      return [];
    }
    const rs = [];
    for (const item of data) {
      const amount = item?.amount;
      const internalId = item?.internalId;
      if (internalId !== Constant.dashboard.quizzes) {
        if (amount === 0) {
          continue;
        }
      }

      // console.log('amount>>>', amount, typeof amount)
      if (typeof amount === 'string' && amount?.includes('{') && amount?.includes('}')) {
        const linkInternalId = ''.replace('{', '').replace('}', '');
        // console.log('linkInternalId>>>', linkInternalId)
        if (_.isEmpty(statistics)) {
          continue;
        }

        const findItem = _.find(statistics, x => x.internalId === linkInternalId);
        if (_.isEmpty(findItem)) {
          continue;
        }
        const cloneItem = _.cloneDeep(item);
        cloneItem.amount = findItem.amount;
        console.log('cloneItem.amount>>>>', cloneItem.amount)
        rs.push(cloneItem);
      }

      rs.push(item);
    }
    // console.log('getListData>>>rs>>>', rs);

    return rs;
  },
};
