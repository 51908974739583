import { AppImagePicker } from '@components';
import { AppImagePickerWithReview } from '@components/AppImagePickerWithReview';
import { FORM, IMAGE_PICKER_TYPE } from '@constant/model/RegisterForm';
import { AppNoteView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import { getErrorForm } from '@utils/form';
import { FileTransform } from '@utils/transform/file';
import { checkEvalCondition, FormTransform } from '@utils/transform/form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { View } from 'react-native';
import { AppText } from '../AppText/AppText';
import { styles } from './style';
import {FormUtil} from "@utils/form/formUtil";
import { t } from 'i18next';

const getObjectValue = dictValue => {
  console.log('getObjectValue>>>start>>>', dictValue);
  const rs = {};
  for (const valueKey in dictValue) {
    const vl = dictValue[valueKey];
    // if (_.isArray(vl)) {
    //   continue;
    // }
    if (valueKey === undefined || valueKey === 'undefined') {
      continue;
    }
    rs[valueKey] = vl;
    console.log('getObjectValue>>>>dictValue', dictValue);
    console.log('getObjectValue>>>>rs', rs);
  }

  return rs;
};

export const TextReviewItem = props => {
  console.log('TextReviewItem>>>>props>>>', props);
  return (
    <View style={[styles.container, ...(props?.style ?? [])]}>
      {!!props.label && (
        <AppText style={[styles.label, { fontWeight: '700' }]}>
          {/*{props?.required && <AppText style={styles.isRequired}>* </AppText>}*/}
          {props.label}
        </AppText>
      )}
      {!!props.value && <AppText style={[styles.valueText]}>{props.value}</AppText>}
    </View>
  );
};

export const FormItemReview = props => {
  // return <></>;

  const { item, values, checkCondition, formData, folderUpload = null } = props;
  const key = `${item?.internalId}`;
  const vl = values[item?.internalId];
  // delete values.undefined
  if (_.isEmpty(key)) {
    return <></>;
  }

  console.log('FormItemReview>>>props', props);
  console.log('FormItemReview>>>vl', vl);
  console.log('FormItemReview>>>key', key);
  console.log('FormItemReview>>>type', item?.type);
  console.log('FormItemReview>>>isEmpty', _.isEmpty(vl));
  console.log('FormItemReview>>>item', item);

  if (checkCondition) {
    // console.log('FormItemView>>>', item, values, item?.condition?.react);
    const isCondition = checkEvalCondition(item?.condition?.react, values);

    if (!isCondition) {
      //TODO: reset value of field hidden

      return <></>;
    }
  } else {
    if (_.isBoolean(vl)) {
      if (!vl) {
        return <></>;
      }
    } else {
      if (_.isEmpty(vl)) {
        return <></>;
      }
    }
  }

  const getFormRequired = useCallback(() => {
    return FormTransform.getFormRequired(item, getObjectValue(values));
  }, [item, values]);

  const getFormTitle = useCallback(() => {
    return FormUtil.getTitleDynamic(item.title, values, formData)
  }, [item.title, values])

  switch (item?.type) {
    case FORM.FORM_TYPE.hidden:
      return <></>;
    case FORM.FORM_TYPE.dropdown:
    case FORM.FORM_TYPE.singlechoice:
    case FORM.FORM_TYPE.stringlistsingle:
      const find_content = item?.data?.find(x => x?.id == vl);
      console.log('stringlistsingle>>>find_content>>>', item?.data, key);
      return (
        <TextReviewItem
          // label={item?.title}
          label={getFormTitle()}
          required={item?.required}
          style={[RegisterFormStyles.formItemView, ...props?.style]}
          value={find_content?.content}
        />
      );
    case FORM.FORM_TYPE.scan:
      return (
        <TextReviewItem
          // label={item?.title}
          label={getFormTitle()}
          required={item?.required}
          style={[RegisterFormStyles.formItemView, ...props?.style]}
          value={_.isEmpty(vl) ? '' : t('SCAN_OK')}
        />
      );
    case FORM.FORM_TYPE.dispatch_point:
      return <></>;
    case FORM.FORM_TYPE.nearest_address:
      return <></>;
    case FORM.FORM_TYPE.stringlistmultiple:
    case FORM.FORM_TYPE.MultipleSelection:
      return <></>;
    case FORM.FORM_TYPE.boolean:
    case FORM.FORM_TYPE.SingleSelection:
      // hide when item?.required = false
      console.log('FORM_TYPE.boolean>>>item', item);
      const find_content_boolean = item?.data?.find(x => x?.id == vl);
      if (_.isBoolean(item?.required) && !item.required) {
        if (_.isBoolean(vl) && !vl) {
          return <></>;
        }
      }
      return (
        <TextReviewItem
          label={getFormTitle()}
          required={item?.required}
          style={[RegisterFormStyles.formItemView, ...props?.style]}
          value={item?.content ?? find_content_boolean?.content}
        />
      );
    case FORM.FORM_TYPE.postcode:
      return <></>;
    case FORM.FORM_TYPE.address:
      return <></>;
    case FORM.FORM_TYPE.bundle_postcode:
      return <></>;
    case FORM.FORM_TYPE.bundle_address:
      return <></>;
    case FORM.FORM_TYPE.emptying_time:
      return <></>;
    case FORM.FORM_TYPE.input:
    // case FORM.FORM_TYPE.text:
    case FORM.FORM_TYPE.string:
    case FORM.FORM_TYPE.textArea:
    case FORM.FORM_TYPE.textarea:
    case FORM.FORM_TYPE.text:
      return (
        <TextReviewItem
          label={getFormTitle()}
          required={item?.required}
          style={[RegisterFormStyles.formItemView, ...props?.style]}
          value={vl}
        />
      );
    case FORM.FORM_TYPE.integer:
      return <></>;

    case FORM.FORM_TYPE.password:
      return <></>;

    case FORM.FORM_TYPE.tel:
      return <></>;
    case FORM.FORM_TYPE.email:
      return <></>;
    case FORM.FORM_TYPE.birtday:
    case FORM.FORM_TYPE.birthday:
      return <></>;

    case FORM.FORM_TYPE.date:
      return (
        <TextReviewItem
          // label={item?.title}
          label={getFormTitle()}
          required={item?.required}
          style={[RegisterFormStyles.formItemView, ...props?.style]}
          value={vl}
        />
      );
    case FORM.FORM_TYPE.datetime:
      return (
        <TextReviewItem
          // label={item?.title}
          label={getFormTitle()}
          required={item?.required}
          style={[RegisterFormStyles.formItemView, ...props?.style]}
          value={vl}
        />
      );
    case FORM.FORM_TYPE.time:
      return (
        <TextReviewItem
          // label={item?.title}
          label={getFormTitle()}
          required={item?.required}
          style={[RegisterFormStyles.formItemView, ...props?.style]}
          value={vl}
        />
      );
    case FORM.FORM_TYPE.checkbox:
      if (_.isBoolean(vl)) {
        if (!vl) {
          return <></>;
        }
      }
      return (
        <TextReviewItem
          // label={item?.title}
          label={getFormTitle()}
          required={item?.required}
          style={[RegisterFormStyles.formItemView, ...props?.style]}
          value={item?.content}
        />
      );
    case FORM.FORM_TYPE.calendar:
      return <></>;
    case FORM.FORM_TYPE.inputPicker:
      return <></>;
    case FORM.FORM_TYPE.imagePicker:
    case FORM.FORM_TYPE.camera:
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      const uploadPath = FileTransform.fixUploadPath(item?.uploadPath);
      return (
        <AppImagePickerWithReview
          type={item.type === FORM.FORM_TYPE.camera ? IMAGE_PICKER_TYPE.camera : IMAGE_PICKER_TYPE.gallery}
          key={key}
          style={[RegisterFormStyles.formItemView, ...props?.style]}
          // data={item.data}
          // value={formik.values[item.internalId]}
          // isRequired={item.required}
          // isRequired={getFormRequired()}
          // title={item?.title}
          title={getFormTitle()}
          disabled={true}
          selected={vl ? vl : []}
          // error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          isBoldTitle={true}
          // folderUpload={folderUpload}
          folderUpload={!_.isEmpty(uploadPath) ? uploadPath : folderUpload}
          uploadFileName={FileTransform.fixUploadName(item?.uploadFileName, {
            // ...getObjectValue(values),
            internalId: item.internalId,
          })}
          s3config={item?.s3config}
          maxFile={item.maxFile}
          // onLayout={onLayout}
        />
      );
    case FORM.FORM_TYPE.note:
      // console.log('FORM.FORM_TYPE.note>>>', item)
      return <></>;
    // const styleCSS = item?.styleCSS ? item?.styleCSS : {};
    // return (
    //   <AppNoteView
    //     key={key}
    //     style={{
    //       ...RegisterFormStyles.formItemView,
    //       ...styleCSS,
    //     }}
    //     title={item?.title}
    //     data={values}
    //     structure={formData}
    //   />
    // );
    case FORM.FORM_TYPE.misdelivered_item_form:
      return <></>;
    case FORM.FORM_TYPE.toggleSwitch:
      return <></>;
  }
};

FormItemReview.propTypes = {
  values: PropTypes.array,
  isSuccess: PropTypes.bool,
  title: PropTypes.string,
  onBackClicked: PropTypes.func,
  item: PropTypes.any,
  folderUpload: PropTypes.string,
};

FormItemReview.defaultProps = {
  style: [],
  folderUpload: '',
};
