import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  payload: null,
  error: null,
};

export const deleteFavouriteLocationRequest = createAction(
  'deleteFavouriteLocation/deleteFavouriteLocationRequest',
  (payload, onSuccess, onFailed) => {
    console.log('prepareAction>>>', payload, onSuccess);
    return {
      payload: payload,
      meta: onSuccess,
      error: onFailed,
    };
  },
);

const deleteFavouriteLocationSlice = createSlice({
  name: 'deleteFavouriteLocation',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // getReportPackageFormRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    deleteFavouriteLocationSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    deleteFavouriteLocationFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { deleteFavouriteLocationSuccess, deleteFavouriteLocationFailed } = deleteFavouriteLocationSlice.actions;

export default deleteFavouriteLocationSlice.reducer;
