import React from 'react';
import Svg, { Path, Circle, Rect, G, Defs, ClipPath, Polygon } from 'react-native-svg';

export const MailIcon = () => (
  <Svg width="15" height="12" viewBox="0 0 15 12" fill="none">
    <Path
      d="M15 4.08594C14.7489 4.36216 14.4643 4.60491 14.1629 4.81417C12.7651 5.76004 11.3588 6.71429 9.99442 7.70201C9.2913 8.22098 8.42076 8.85714 7.50837 8.85714H7.5H7.49163C6.57924 8.85714 5.70871 8.22098 5.00558 7.70201C3.64118 6.70591 2.23493 5.76004 0.845424 4.81417C0.535714 4.60491 0.251116 4.36216 0 4.08594V10.7321C0 11.4688 0.602679 12.0714 1.33929 12.0714H13.6607C14.3973 12.0714 15 11.4688 15 10.7321V4.08594ZM15 1.625C15 0.888392 14.389 0.285714 13.6607 0.285714H1.33929C0.443638 0.285714 0 0.988839 0 1.80915C0 2.57087 0.845424 3.51674 1.43973 3.91853C2.73717 4.82254 4.05134 5.72656 5.34877 6.63895C5.89286 7.01562 6.81362 7.78571 7.49163 7.78571H7.5H7.50837C8.18638 7.78571 9.10714 7.01562 9.65123 6.63895C10.9487 5.72656 12.2628 4.82254 13.5686 3.91853C14.3052 3.40792 15 2.5625 15 1.625Z"
      fill="#666666"
    />
  </Svg>
);

export const MarkerDashboardIcon = ({ style, color = 'white', width = 22, height=33 }) => {
  return (
    // <Svg style={style} width={`${width}`} height={`${height}`} viewBox={`0 0 ${width} ${height}`} fill="none">
    <Svg style={style} width={`${width}`} height={`${height}`} viewBox={`0 0 22 33`} fill="none">
        <G clip-path="url(#clip0_6778_1858)">
        <Path
          d="M21.597 10.4C21.397 4.7 16.597 0.1 10.997 0C10.897 0 10.897 0 10.797 0C10.697 0 10.697 0 10.597 0C4.99699 0.1 0.196993 4.7 -0.00300678 10.4C-0.103007 12.4 0.396993 14.3 1.29699 16L10.797 33L20.297 16C21.197 14.3 21.697 12.4 21.597 10.4ZM10.797 20.2C5.59699 20.2 1.39699 16 1.39699 10.8C1.39699 5.6 5.59699 1.4 10.797 1.4C15.997 1.4 20.197 5.6 20.197 10.8C20.197 16 15.997 20.2 10.797 20.2Z"
          fill={color}
        />
        <Path
          d="M10.8016 15.0001C13.3973 15.0001 15.5016 12.8958 15.5016 10.3001C15.5016 7.70436 13.3973 5.6001 10.8016 5.6001C8.20582 5.6001 6.10156 7.70436 6.10156 10.3001C6.10156 12.8958 8.20582 15.0001 10.8016 15.0001Z"
          stroke={color}
          strokeWidth="1.3"
          strokeMiterlimit="10"
        />
        <Path d="M10.7969 3.8999V16.7999" stroke={color} strokeWidth="1.3" strokeMiterlimit="10" />
        <Path d="M17.1984 10.2998H4.39844" stroke={color} strokeWidth="1.3" strokeMiterlimit="10" />
      </G>
      <Defs>
        <ClipPath id="clip0_6778_1858">
          <Rect width="21.6" height="33" fill={color} />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export const PadLockIcon = () => (
  <Svg width="12" height="20" viewBox="0 0 12 20" fill="none">
    <Path
      d="M10.6071 8.28571H3.21429V5.07143C3.21429 3.65513 4.36942 2.5 5.78571 2.5C7.20201 2.5 8.35714 3.65513 8.35714 5.07143C8.35714 5.42299 8.64844 5.71429 9 5.71429H9.64286C9.99442 5.71429 10.2857 5.42299 10.2857 5.07143C10.2857 2.5904 8.26674 0.571427 5.78571 0.571427C3.30469 0.571427 1.28571 2.5904 1.28571 5.07143V8.28571H0.964286C0.43192 8.28571 0 8.71763 0 9.25V15.0357C0 15.5681 0.43192 16 0.964286 16H10.6071C11.1395 16 11.5714 15.5681 11.5714 15.0357V9.25C11.5714 8.71763 11.1395 8.28571 10.6071 8.28571Z"
      fill="#666666"
    />
  </Svg>
);

export const EyeInactiveIcon = () => (
  <Svg width="18" height="16" viewBox="0 0 18 16" fill="none">
    <Path
      d="M5.57478 10.981C3.80692 10.1875 2.33036 8.83147 1.28571 7.21429C2.23996 5.73772 3.54576 4.4721 5.11272 3.66853C4.71094 4.35156 4.5 5.13504 4.5 5.92857C4.5 7.36496 5.19308 8.72098 6.35826 9.56473L5.57478 10.981ZM9.48214 3.35714C9.48214 3.6183 9.26116 3.83929 9 3.83929C7.85491 3.83929 6.91071 4.77344 6.91071 5.92857C6.91071 6.18973 6.68973 6.41071 6.42857 6.41071C6.16741 6.41071 5.94643 6.18973 5.94643 5.92857C5.94643 4.25112 7.32254 2.875 9 2.875C9.26116 2.875 9.48214 3.09598 9.48214 3.35714ZM13.1283 1.43862C13.1283 1.32812 13.0681 1.22768 12.9676 1.16741C12.7567 1.04688 11.8125 0.464285 11.6317 0.464285C11.5112 0.464285 11.4107 0.524553 11.3504 0.624999L10.808 1.59933C10.2154 1.48884 9.60268 1.42857 9 1.42857C5.29353 1.42857 2.18973 3.46763 0.200893 6.52121C0.0703125 6.7221 0 6.97321 0 7.21429C0 7.4654 0.0703125 7.70647 0.200893 7.90737C1.35603 9.7154 2.9933 11.2221 4.94196 12.106C4.84152 12.2768 4.5 12.8192 4.5 12.9799C4.5 13.1004 4.56027 13.2009 4.66071 13.2612C4.87165 13.3817 5.82589 13.9643 6.0067 13.9643C6.11719 13.9643 6.22768 13.904 6.28795 13.8036L6.78013 12.9096C8.89955 9.12277 10.9989 5.31585 13.1183 1.52902C13.1283 1.50893 13.1283 1.4587 13.1283 1.43862ZM13.5 5.92857C13.5 5.64732 13.4699 5.36607 13.4196 5.08482L10.6071 10.1272C12.3449 9.46429 13.5 7.79688 13.5 5.92857ZM18 7.21429C18 6.95312 17.9297 6.74219 17.7991 6.52121C17.0156 5.22545 15.76 3.96987 14.5145 3.13616L13.8817 4.26116C15.0167 5.04464 15.971 6.05915 16.7143 7.21429C15.1473 9.64509 12.6663 11.423 9.7433 11.6741L9 13C12.0234 13 14.7355 11.6239 16.7042 9.36384C17.096 8.91183 17.4877 8.41964 17.7991 7.90737C17.9297 7.68638 18 7.47545 18 7.21429Z"
      fill="#888888"
    />
  </Svg>
);

export const EyeActiveIcon = () => (
  <Svg width="18" height="14" viewBox="0 0 18 14" fill="none">
    <Path
      d="M16.7143 6.21429C14.9967 8.86607 12.2243 10.7143 9 10.7143C5.77567 10.7143 3.00335 8.86607 1.28571 6.21429C2.23996 4.73772 3.54576 3.4721 5.11272 2.66853C4.71094 3.35156 4.5 4.13504 4.5 4.92857C4.5 7.4096 6.51897 9.42857 9 9.42857C11.481 9.42857 13.5 7.4096 13.5 4.92857C13.5 4.13504 13.2891 3.35156 12.8873 2.66853C14.4542 3.4721 15.76 4.73772 16.7143 6.21429ZM9.48214 2.35714C9.48214 2.6183 9.26116 2.83929 9 2.83929C7.85491 2.83929 6.91071 3.78348 6.91071 4.92857C6.91071 5.18973 6.68973 5.41071 6.42857 5.41071C6.16741 5.41071 5.94643 5.18973 5.94643 4.92857C5.94643 3.25112 7.32254 1.875 9 1.875C9.26116 1.875 9.48214 2.09598 9.48214 2.35714ZM18 6.21429C18 5.96317 17.9196 5.73214 17.7991 5.52121C15.9509 2.48772 12.5658 0.428571 9 0.428571C5.43415 0.428571 2.04911 2.48772 0.200893 5.52121C0.0803571 5.73214 0 5.96317 0 6.21429C0 6.4654 0.0803571 6.69643 0.200893 6.90737C2.04911 9.94085 5.43415 12 9 12C12.5658 12 15.9509 9.95089 17.7991 6.90737C17.9196 6.69643 18 6.4654 18 6.21429Z"
      fill="#888888"
    />
  </Svg>
);

export const NumPadIcon = () => (
  <Svg width="15" height="12" viewBox="0 0 15 12" fill="none">
    <Path
      d="M4.28571 9.58929C4.28571 9.14565 3.92578 8.78571 3.48214 8.78571H0.803571C0.359933 8.78571 0 9.14565 0 9.58929V11.1964C0 11.6401 0.359933 12 0.803571 12H3.48214C3.92578 12 4.28571 11.6401 4.28571 11.1964V9.58929ZM4.28571 5.30357C4.28571 4.85993 3.92578 4.5 3.48214 4.5H0.803571C0.359933 4.5 0 4.85993 0 5.30357V6.91071C0 7.35435 0.359933 7.71429 0.803571 7.71429H3.48214C3.92578 7.71429 4.28571 7.35435 4.28571 6.91071V5.30357ZM9.64286 9.58929C9.64286 9.14565 9.28292 8.78571 8.83929 8.78571H6.16071C5.71708 8.78571 5.35714 9.14565 5.35714 9.58929V11.1964C5.35714 11.6401 5.71708 12 6.16071 12H8.83929C9.28292 12 9.64286 11.6401 9.64286 11.1964V9.58929ZM4.28571 1.01786C4.28571 0.574218 3.92578 0.214285 3.48214 0.214285H0.803571C0.359933 0.214285 0 0.574218 0 1.01786V2.625C0 3.06864 0.359933 3.42857 0.803571 3.42857H3.48214C3.92578 3.42857 4.28571 3.06864 4.28571 2.625V1.01786ZM9.64286 5.30357C9.64286 4.85993 9.28292 4.5 8.83929 4.5H6.16071C5.71708 4.5 5.35714 4.85993 5.35714 5.30357V6.91071C5.35714 7.35435 5.71708 7.71429 6.16071 7.71429H8.83929C9.28292 7.71429 9.64286 7.35435 9.64286 6.91071V5.30357ZM15 9.58929C15 9.14565 14.6401 8.78571 14.1964 8.78571H11.5179C11.0742 8.78571 10.7143 9.14565 10.7143 9.58929V11.1964C10.7143 11.6401 11.0742 12 11.5179 12H14.1964C14.6401 12 15 11.6401 15 11.1964V9.58929ZM9.64286 1.01786C9.64286 0.574218 9.28292 0.214285 8.83929 0.214285H6.16071C5.71708 0.214285 5.35714 0.574218 5.35714 1.01786V2.625C5.35714 3.06864 5.71708 3.42857 6.16071 3.42857H8.83929C9.28292 3.42857 9.64286 3.06864 9.64286 2.625V1.01786ZM15 5.30357C15 4.85993 14.6401 4.5 14.1964 4.5H11.5179C11.0742 4.5 10.7143 4.85993 10.7143 5.30357V6.91071C10.7143 7.35435 11.0742 7.71429 11.5179 7.71429H14.1964C14.6401 7.71429 15 7.35435 15 6.91071V5.30357ZM15 1.01786C15 0.574218 14.6401 0.214285 14.1964 0.214285H11.5179C11.0742 0.214285 10.7143 0.574218 10.7143 1.01786V2.625C10.7143 3.06864 11.0742 3.42857 11.5179 3.42857H14.1964C14.6401 3.42857 15 3.06864 15 2.625V1.01786Z"
      fill="#666666"
    />
  </Svg>
);

export const QRIcon = ({color = "#DA202A"}) => (
  <Svg width="26" height="26" viewBox="0 0 26 26" fill="none">
    <Path
      d="M6.85714 19.1429H4.57143V21.4286H6.85714V19.1429ZM6.85714 5.42857H4.57143V7.71428H6.85714V5.42857ZM20.5714 5.42857H18.2857V7.71428H20.5714V5.42857ZM2.28571 23.6964V16.8571H9.14286V23.6964H2.28571ZM2.28571 10V3.14286H9.14286V10H2.28571ZM16 10V3.14286H22.8571V10H16ZM11.4286 14.5714H0V26H11.4286V14.5714ZM20.5714 23.7143H18.2857V26H20.5714V23.7143ZM25.1429 23.7143H22.8571V26H25.1429V23.7143ZM25.1429 14.5714H22.8571V16.8571H20.5714V14.5714H13.7143V26H16V19.1429H18.2857V21.4286H25.1429V14.5714ZM11.4286 0.857141H0V12.2857H11.4286V0.857141ZM25.1429 0.857141H13.7143V12.2857H25.1429V0.857141Z"
      fill={color}
    />
  </Svg>
);

export const QRIconBigger = ({color = "#DA202A"}) => (
  <Svg width="38" height="38" viewBox="0 0 38 38" fill="none">
    <Path
      d="M10.5098 27.6357V31.0642H7.08126V27.6357H10.5098ZM10.5098 7.06424V10.4928H7.08126V7.06424H10.5098ZM31.0813 7.06424V10.4928H27.6527V7.06424H31.0813ZM3.65269 34.466H13.9384V24.2071H3.65269V34.466ZM3.65269 13.9214H13.9384V3.63567H3.65269V13.9214ZM24.2241 13.9214H34.5098V3.63567H24.2241V13.9214ZM17.367 20.7785V37.9214H0.224121V20.7785H17.367ZM31.0813 34.4928V37.9214H27.6527V34.4928H31.0813ZM37.9384 34.4928V37.9214H34.5098V34.4928H37.9384ZM37.9384 20.7785V31.0642H27.6527V27.6357H24.2241V37.9214H20.7956V20.7785H31.0813V24.2071H34.5098V20.7785H37.9384ZM17.367 0.207099V17.35H0.224121V0.207099H17.367ZM37.9384 0.207099V17.35H20.7956V0.207099H37.9384Z"
      fill={color}
    />
  </Svg>
);

export const FrameIcon = () => (
  <Svg width="192" height="192" viewBox="0 0 192 192" fill="none">
    <Path d="M0 46.1955V0H46.1955V6.6406H6.6406V46.1955H0Z" fill="#DCDCDC" />
    <Path d="M0 192V145.805H6.6406V185.359H46.1955V192H0Z" fill="#DCDCDC" />
    <Path d="M185.359 46.1955V6.6406H145.805V0H192V46.1955H185.359Z" fill="#DCDCDC" />
    <Path d="M145.805 192V185.359H185.359V145.805H192V192H145.805Z" fill="#DCDCDC" />
  </Svg>
);

export const RadioIcon = () => (
  <Svg width="15" height="15" viewBox="0 0 15 15" fill="none">
    <Rect x="0.5" y="0.5" width="14" height="14" rx="7" fill="white" stroke="#C6C6C6" />
  </Svg>
);

export const ActiveRadioIcon = () => (
  <Svg width="15" height="15" viewBox="0 0 15 15" fill="none">
    <Rect x="0.5" y="0.5" width="14" height="14" rx="7" fill="white" stroke="#C6C6C6" />
    <Rect width="5" height="5" rx="2.5" transform="matrix(1 0 0 -1 5 10)" fill="#666666" />
  </Svg>
);

export const CheckIcon = () => (
  <Svg width="15" height="15" viewBox="0 0 15 15" fill="none">
    <Rect x="0.5" y="0.5" width="14" height="14" rx="1.5" fill="white" stroke="#C6C6C6" />
  </Svg>
);

export const ActiveCheckIcon = () => (
  <Svg width="15" height="15" viewBox="0 0 15 15" fill="none">
    <Rect x="0.5" y="0.5" width="14" height="14" rx="1.5" fill="white" stroke="#666666" />
    <Path
      d="M12.2573 6.04576C12.2573 5.8923 12.1959 5.73884 12.0854 5.62835L11.2506 4.79353C11.1401 4.68304 10.9866 4.62165 10.8331 4.62165C10.6797 4.62165 10.5262 4.68304 10.4157 4.79353L6.38895 8.82645L4.58426 7.01562C4.47377 6.90513 4.32031 6.84375 4.16685 6.84375C4.01339 6.84375 3.85993 6.90513 3.74944 7.01562L2.91462 7.85045C2.80413 7.96094 2.74275 8.1144 2.74275 8.26786C2.74275 8.42132 2.80413 8.57478 2.91462 8.68527L5.13672 10.9074L5.97154 11.7422C6.08203 11.8527 6.23549 11.9141 6.38895 11.9141C6.54241 11.9141 6.69587 11.8527 6.80636 11.7422L7.64118 10.9074L12.0854 6.46317C12.1959 6.35268 12.2573 6.19922 12.2573 6.04576Z"
      fill="#666666"
    />
  </Svg>
);

export const ChevronDownIcon = ({ style }) => (
  <Svg style={style} width="10" height="6" viewBox="0 0 10 6" fill="none">
    <Path
      d="M9.59822 0.857142C9.59822 0.785714 9.5625 0.705357 9.50893 0.651785L9.0625 0.205357C9.00893 0.151785 8.92857 0.116071 8.85714 0.116071C8.78572 0.116071 8.70536 0.151785 8.65179 0.205357L5.14286 3.71429L1.63393 0.205357C1.58036 0.151785 1.5 0.116071 1.42857 0.116071C1.34821 0.116071 1.27679 0.151785 1.22321 0.205357L0.776786 0.651785C0.723214 0.705357 0.6875 0.785714 0.6875 0.857142C0.6875 0.928571 0.723214 1.00893 0.776786 1.0625L4.9375 5.22321C4.99107 5.27679 5.07143 5.3125 5.14286 5.3125C5.21429 5.3125 5.29464 5.27679 5.34821 5.22321L9.50893 1.0625C9.5625 1.00893 9.59822 0.928571 9.59822 0.857142Z"
      fill="black"
    />
  </Svg>
);

export const CalendarIcon = () => (
  <Svg width="14" height="16" viewBox="0 0 14 16" fill="none">
    <Path
      d="M1.07143 14.0714V11.6607H3.48214V14.0714H1.07143ZM4.01786 14.0714V11.6607H6.69643V14.0714H4.01786ZM1.07143 11.125V8.44643H3.48214V11.125H1.07143ZM4.01786 11.125V8.44643H6.69643V11.125H4.01786ZM1.07143 7.91071V5.5H3.48214V7.91071H1.07143ZM7.23214 14.0714V11.6607H9.91071V14.0714H7.23214ZM4.01786 7.91071V5.5H6.69643V7.91071H4.01786ZM10.4464 14.0714V11.6607H12.8571V14.0714H10.4464ZM7.23214 11.125V8.44643H9.91071V11.125H7.23214ZM4.28571 3.89286C4.28571 4.03516 4.16016 4.16071 4.01786 4.16071H3.48214C3.33984 4.16071 3.21429 4.03516 3.21429 3.89286V1.48214C3.21429 1.33984 3.33984 1.21429 3.48214 1.21429H4.01786C4.16016 1.21429 4.28571 1.33984 4.28571 1.48214V3.89286ZM10.4464 11.125V8.44643H12.8571V11.125H10.4464ZM7.23214 7.91071V5.5H9.91071V7.91071H7.23214ZM10.4464 7.91071V5.5H12.8571V7.91071H10.4464ZM10.7143 3.89286C10.7143 4.03516 10.5887 4.16071 10.4464 4.16071H9.91071C9.76842 4.16071 9.64286 4.03516 9.64286 3.89286V1.48214C9.64286 1.33984 9.76842 1.21429 9.91071 1.21429H10.4464C10.5887 1.21429 10.7143 1.33984 10.7143 1.48214V3.89286ZM13.9286 3.35714C13.9286 2.7712 13.4431 2.28571 12.8571 2.28571H11.7857V1.48214C11.7857 0.745535 11.183 0.142856 10.4464 0.142856H9.91071C9.17411 0.142856 8.57143 0.745535 8.57143 1.48214V2.28571H5.35714V1.48214C5.35714 0.745535 4.75446 0.142856 4.01786 0.142856H3.48214C2.74554 0.142856 2.14286 0.745535 2.14286 1.48214V2.28571H1.07143C0.485491 2.28571 0 2.7712 0 3.35714V14.0714C0 14.6574 0.485491 15.1429 1.07143 15.1429H12.8571C13.4431 15.1429 13.9286 14.6574 13.9286 14.0714V3.35714Z"
      fill="#666666"
    />
  </Svg>
);

export const ClockIcon = () => (
  <Svg width="13" height="14" viewBox="0 0 13 14" fill="none">
    <Path
      d="M7.5 3.69643C7.5 3.54576 7.38281 3.42857 7.23214 3.42857H6.69643C6.54576 3.42857 6.42857 3.54576 6.42857 3.69643V6.64286H4.55357C4.4029 6.64286 4.28571 6.76004 4.28571 6.91071V7.44643C4.28571 7.5971 4.4029 7.71429 4.55357 7.71429H7.23214C7.38281 7.71429 7.5 7.5971 7.5 7.44643V3.69643ZM10.9821 6.64286C10.9821 9.15402 8.93973 11.1964 6.42857 11.1964C3.91741 11.1964 1.875 9.15402 1.875 6.64286C1.875 4.1317 3.91741 2.08929 6.42857 2.08929C8.93973 2.08929 10.9821 4.1317 10.9821 6.64286ZM12.8571 6.64286C12.8571 3.09375 9.97768 0.214285 6.42857 0.214285C2.87946 0.214285 0 3.09375 0 6.64286C0 10.192 2.87946 13.0714 6.42857 13.0714C9.97768 13.0714 12.8571 10.192 12.8571 6.64286Z"
      fill="#666666"
    />
  </Svg>
);

export const TickIcon = () => (
  <Svg width="13" height="11" viewBox="0 0 13 11" fill="none">
    <Path
      d="M12.9872 2.88058C12.9872 2.67132 12.9035 2.46205 12.7528 2.31138L11.6144 1.17299C11.4637 1.02232 11.2545 0.938616 11.0452 0.938616C10.8359 0.938616 10.6267 1.02232 10.476 1.17299L4.98493 6.67243L2.524 4.20312C2.37333 4.05246 2.16406 3.96875 1.9548 3.96875C1.74554 3.96875 1.53627 4.05246 1.3856 4.20312L0.24721 5.34152C0.0965402 5.49219 0.0128349 5.70145 0.0128349 5.91071C0.0128349 6.11998 0.0965402 6.32924 0.24721 6.47991L3.27734 9.51004L4.41574 10.6484C4.56641 10.7991 4.77567 10.8828 4.98493 10.8828C5.1942 10.8828 5.40346 10.7991 5.55413 10.6484L6.69252 9.51004L12.7528 3.44978C12.9035 3.29911 12.9872 3.08984 12.9872 2.88058Z"
      fill="#666666"
    />
  </Svg>
);

export const CrossIcon = ({ color = '#666666', size = 11 }) => (
  <Svg width={size} height={size} viewBox="0 0 11 11" fill="none">
    <Path
      d="M10.865 8.20871C10.865 7.99944 10.7813 7.79018 10.6306 7.63951L8.16964 5.17857L10.6306 2.71763C10.7813 2.56696 10.865 2.3577 10.865 2.14844C10.865 1.93917 10.7813 1.72991 10.6306 1.57924L9.49219 0.440848C9.34152 0.290178 9.13225 0.206473 8.92299 0.206473C8.71373 0.206473 8.50446 0.290178 8.3538 0.440848L5.89286 2.90179L3.43192 0.440848C3.28125 0.290178 3.07199 0.206473 2.86272 0.206473C2.65346 0.206473 2.4442 0.290178 2.29353 0.440848L1.15513 1.57924C1.00446 1.72991 0.920759 1.93917 0.920759 2.14844C0.920759 2.3577 1.00446 2.56696 1.15513 2.71763L3.61607 5.17857L1.15513 7.63951C1.00446 7.79018 0.920759 7.99944 0.920759 8.20871C0.920759 8.41797 1.00446 8.62723 1.15513 8.7779L2.29353 9.91629C2.4442 10.067 2.65346 10.1507 2.86272 10.1507C3.07199 10.1507 3.28125 10.067 3.43192 9.91629L5.89286 7.45536L8.3538 9.91629C8.50446 10.067 8.71373 10.1507 8.92299 10.1507C9.13225 10.1507 9.34152 10.067 9.49219 9.91629L10.6306 8.7779C10.7813 8.62723 10.865 8.41797 10.865 8.20871Z"
      fill={color}
    />
  </Svg>
);

export const MarkerQRIcon2 = ({ width = 375, height = 812 }) => (
  <Svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M375 0H0V812H375V0ZM285 309H90V504H285V309Z"
      fill="#2C2C2C"
      fillOpacity="0.80"
    />
  </Svg>
);

export const ChevronPickerIcon = () => (
  <Svg width="9" height="6" viewBox="0 0 9 6" fill="none">
    <Path
      d="M8.57143 1.03571C8.57143 0.742745 8.32868 0.5 8.03571 0.5H0.535714C0.242746 0.5 0 0.742745 0 1.03571C0 1.17801 0.0585938 1.31194 0.15904 1.41239L3.90904 5.16239C4.00949 5.26283 4.14342 5.32143 4.28571 5.32143C4.42801 5.32143 4.56194 5.26283 4.66239 5.16239L8.41239 1.41239C8.51284 1.31194 8.57143 1.17801 8.57143 1.03571Z"
      fill="#666666"
    />
  </Svg>
);

export const UserIcon = () => (
  <Svg width="11" height="14" viewBox="0 0 11 14" fill="none">
    <Path
      d="M10.7143 10.8532C10.7143 8.65179 10.1702 6.10714 7.97712 6.10714C7.29911 6.76841 6.37835 7.17857 5.35714 7.17857C4.33594 7.17857 3.41518 6.76841 2.73717 6.10714C0.544085 6.10714 0 8.65179 0 10.8532C0 12.0753 0.803571 13.0714 1.78292 13.0714H8.93136C9.91071 13.0714 10.7143 12.0753 10.7143 10.8532ZM8.57143 3.42857C8.57143 1.65402 7.1317 0.214285 5.35714 0.214285C3.58259 0.214285 2.14286 1.65402 2.14286 3.42857C2.14286 5.20312 3.58259 6.64286 5.35714 6.64286C7.1317 6.64286 8.57143 5.20312 8.57143 3.42857Z"
      fill="#666666"
    />
  </Svg>
);

export const MarkerQRIcon = ({ width = 280, height = 280, opacity=0.5 }) => (
  <Svg width={width} height={height} viewBox="0 0 231 231" fill="none">
    <Path d="M0 51.5308V0H51.5308V2.84308H2.84308V51.5308H0Z" fill="white" fill-opacity={`${opacity}`} />
    <Path d="M0 231V179.469H2.84308V228.157H51.5308V231H0Z" fill="white" fill-opacity={`${opacity}`} />
    <Path d="M228.156 51.5308V2.84308H179.469V0H231V51.5308H228.156Z" fill="white" fill-opacity={`${opacity}`} />
    <Path d="M179.469 231V228.157H228.156V179.469H231V231H179.469Z" fill="white" fill-opacity={`${opacity}`} />
  </Svg>
);

export const LogoIcon = ({ logoColor = '#DA202A', textColor = '#333333', textColor2 = '#2A2A2D' }) => {
  return (
    <Svg width="190" height="86" viewBox="0 0 190 86" fill="none">
      <Path
        d="M47.4208 27.3281L52.5742 23.7885L46.7962 21.342L50.7523 16.501L44.5058 15.7722L46.9524 9.99429L40.81 11.0354L41.4867 4.78893L35.8649 7.54777L34.8239 1.40545L30.139 5.62179L27.3802 0L24.1529 5.36152L19.8845 0.728751L18.2708 6.81902L12.9093 3.5917L13.0655 9.89019L6.97518 8.32858L8.90117 14.3147L2.65473 14.523L6.19438 19.7283L0.312322 21.6022L5.15331 25.5583L0 29.098L5.77795 31.5445L1.82188 36.4375L8.06831 37.1663L5.62179 42.9442L11.8162 41.8511L11.0874 48.0975L16.7092 45.2866L17.8023 51.481L22.4351 47.2126L25.1939 52.8344L28.4213 47.4729L32.6897 52.1057L34.3033 46.0674L39.1964 49.0345L32.8458 41.2785C24.8296 44.9743 15.4079 41.4347 11.7121 33.4705C8.01626 25.5063 11.5559 16.0325 19.5201 12.3367C27.4843 8.6409 36.9581 12.1805 40.6539 20.1447C43.0483 25.4022 42.4237 31.5445 39.0402 36.1773L45.2866 43.8291L43.6209 38.6238L49.9194 38.4156L46.3277 33.2623L52.3139 31.2842L47.4208 27.3281ZM25.9227 14.2106C19.1037 14.2106 13.5339 19.7804 13.5339 26.5994C13.5339 33.4184 19.0516 38.9361 25.8706 38.9361C32.6897 38.9361 38.2074 33.4184 38.2074 26.5994C38.2594 19.7804 32.7417 14.2106 25.9227 14.2106ZM23.6844 16.7092C20.353 17.7503 17.7503 20.2489 16.6051 23.5282C15.564 26.8076 16.1366 30.4514 18.1667 33.2623C18.3749 33.6787 18.6352 34.0951 18.8955 34.5115C15.6681 31.7007 14.2106 27.3281 15.564 23.2159C16.9174 19.1037 20.7173 16.501 24.9857 16.1366C24.5693 16.2928 24.1008 16.501 23.6844 16.7092ZM36.3855 30.3473C35.5526 32.7938 33.4184 34.5115 30.8157 34.7718C34.8759 33.1061 36.7499 28.4213 35.0841 24.4131C34.928 24.1008 34.7718 23.7364 34.6157 23.4762C36.4896 25.1939 37.1663 27.9007 36.3855 30.3473Z"
        fill={logoColor}
      />
      <Path
        d="M68.0871 66.9412C68.0871 67.3055 67.9309 67.6699 67.6706 67.8781C67.1501 68.3987 66.3172 68.3987 65.7967 67.8781C65.5364 67.6178 65.4323 67.3055 65.4323 66.9412V57.5715C65.4323 56.8428 65.1721 56.1661 64.6515 55.6455C64.183 55.125 63.5063 54.8647 62.7776 54.8647H61.6845C61.0078 54.8647 60.3311 55.177 59.8626 55.6976C59.3421 56.2181 59.0818 56.8948 59.0818 57.6236V66.9932C59.0818 67.722 58.4571 68.3466 57.7284 68.3466C56.9996 68.3466 56.375 67.722 56.375 66.9932V57.6236C56.375 56.8948 56.1147 56.2181 55.5942 55.6976C55.1257 55.177 54.449 54.9168 53.7203 54.9168H52.6271C51.8984 54.9168 51.2217 55.177 50.7532 55.6976C50.2327 56.2181 49.9724 56.8948 49.9724 57.6236V66.9932C49.9724 67.722 49.3478 68.3466 48.619 68.3466C47.8903 68.3466 47.2656 67.722 47.2656 66.9932V53.5634C47.2656 53.199 47.4218 52.8867 47.6821 52.6264C47.9423 52.3661 48.2546 52.262 48.619 52.262C49.1396 52.21 49.6601 52.5744 49.8162 53.0428C50.6491 52.5223 51.6381 52.262 52.6271 52.262H53.7203C55.2298 52.21 56.7394 52.8867 57.7284 54.0839C58.7174 52.8867 60.1749 52.262 61.7365 52.262H62.8296C65.7967 52.262 68.1912 54.6565 68.1391 57.6236V66.9412H68.0871Z"
        fill={textColor2}
      />
      <Path
        d="M84.2756 56.8947V66.9411C84.2756 67.3054 84.1194 67.6178 83.9112 67.878C83.6509 68.1383 83.3386 68.2945 82.9742 68.2945C82.4537 68.2945 81.9332 67.9821 81.777 67.4616L81.6729 67.5136C80.8921 68.0342 79.9551 68.2945 78.9661 68.2945H74.5936C72.043 68.2945 69.9088 66.2644 69.9088 63.6617V63.6096C69.9088 62.4124 70.3773 61.2672 71.2622 60.4864C72.1991 59.6536 73.3443 59.133 74.5416 58.9769L81.5688 57.6235C81.5688 55.8536 80.8921 54.9167 79.5387 54.9167H75.1662C74.4375 54.9167 73.7608 55.1769 73.2923 55.6975L72.1991 56.7906C71.9389 57.0509 71.5745 57.207 71.2101 57.207C70.8458 57.207 70.4814 57.0509 70.2211 56.8427C69.9608 56.5824 69.8047 56.2701 69.8047 55.9057C69.8047 55.5413 69.9608 55.1769 70.2732 54.9687L71.3663 53.8756C72.3553 52.8345 73.7087 52.2619 75.1142 52.2619H79.4867C82.1934 52.2099 84.2756 54.292 84.2756 56.8947ZM81.6209 63.0891V60.2261L74.5936 61.5795C73.2402 61.8398 72.6156 62.5165 72.6156 63.6096C72.6156 64.1302 72.8238 64.6507 73.1882 65.0151C73.5525 65.3794 74.0731 65.5877 74.5936 65.5877H78.9661C79.6428 65.5877 80.3195 65.3274 80.8401 64.8589C81.3606 64.4425 81.6209 63.7658 81.6209 63.0891Z"
        fill={textColor2}
      />
      <Path
        d="M89.2211 46.6399C89.2211 47.0042 89.065 47.3166 88.8047 47.5768C88.2842 48.0974 87.4513 48.0974 86.9308 47.5768C86.6705 47.3166 86.5664 47.0042 86.5664 46.6399C86.5664 46.2755 86.7226 45.9632 86.9828 45.7029C87.5034 45.1824 88.3362 45.1824 88.8568 45.7029C89.065 45.9632 89.2211 46.2755 89.2211 46.6399ZM89.2211 66.9408C89.2211 67.3052 89.065 67.6175 88.8047 67.8777C88.2842 68.3983 87.4513 68.3983 86.9308 67.8777C86.6705 67.6175 86.5664 67.3052 86.5664 66.9408V53.5109C86.5664 53.1466 86.7226 52.8342 86.9828 52.574C87.5034 52.0534 88.3362 52.0534 88.8568 52.574C89.117 52.8342 89.2732 53.1466 89.2732 53.5109V66.9408H89.2211Z"
        fill={textColor2}
      />
      <Path
        d="M96.4568 66.0037C96.7171 66.2639 96.8732 66.5763 96.8732 66.9406C96.8732 67.305 96.7171 67.6173 96.5089 67.8776C95.9883 68.3981 95.1555 68.3981 94.6349 67.8776L93.0733 66.316C92.0323 65.327 91.5117 63.9736 91.5117 62.5681V46.6918C91.5638 45.963 92.1884 45.3904 92.9172 45.4425C93.5939 45.4946 94.1144 46.0151 94.1664 46.6918V62.5681C94.1664 63.2448 94.4267 63.9215 94.8952 64.4421L96.4568 66.0037Z"
        fill={textColor2}
      />
      <Path
        d="M112.229 56.8945V66.9408C112.229 67.3052 112.125 67.6175 111.864 67.8778C111.604 68.1381 111.292 68.2942 110.927 68.2942C110.407 68.3463 109.886 67.9819 109.73 67.4614L109.626 67.5134C108.845 68.034 107.908 68.2942 106.919 68.2942H102.547C99.9961 68.2942 97.8619 66.2641 97.8619 63.6615V63.6094C97.8619 62.4122 98.3304 61.267 99.2153 60.4862C100.152 59.6533 101.297 59.1328 102.495 58.9766L109.522 57.6232C109.522 55.8534 108.845 54.9165 107.492 54.9165H103.119C102.391 54.9165 101.714 55.1767 101.245 55.6973L100.152 56.7904C99.892 57.0506 99.5797 57.2068 99.1633 57.2068C98.7989 57.2068 98.4345 57.0506 98.1742 56.8424C97.914 56.5822 97.7578 56.2698 97.7578 55.9055C97.7578 55.5411 97.914 55.1767 98.2263 54.9685L99.3194 53.8754C100.308 52.8343 101.662 52.2617 103.067 52.2617H107.44C109.99 52.2617 112.125 54.2918 112.125 56.8424C112.229 56.8945 112.229 56.8945 112.229 56.8945ZM109.522 63.0889V60.2259L102.495 61.5793C101.141 61.8396 100.517 62.5163 100.517 63.6094C100.517 64.1299 100.725 64.6505 101.089 65.0149C101.454 65.3792 101.974 65.5874 102.495 65.5874H106.867C107.544 65.5874 108.221 65.3272 108.741 64.8587C109.262 64.4423 109.522 63.7656 109.522 63.0889Z"
        fill={textColor2}
      />
      <Path
        d="M128.417 60.2263C128.417 61.6317 127.896 63.0372 126.855 64.0262C125.866 65.0673 124.513 65.5878 123.107 65.5878H120.453C119.932 65.5878 119.412 65.796 119.047 66.1604C118.683 66.5248 118.423 67.0453 118.475 67.5658C118.475 68.0864 118.683 68.6069 119.047 68.9192C119.412 69.2836 119.932 69.5439 120.453 69.4918H123.107C125.658 69.4918 127.74 71.626 127.74 74.1766C127.74 74.541 127.584 74.9054 127.324 75.1657C126.803 75.6862 125.97 75.6862 125.45 75.1657C125.19 74.9054 125.033 74.541 125.033 74.1766C125.033 73.6561 124.825 73.1356 124.461 72.7712C124.096 72.4068 123.576 72.1465 123.055 72.1986H120.401C117.85 72.1986 115.716 70.1165 115.716 67.5658C115.716 66.5768 116.028 65.5878 116.601 64.807C114.935 63.87 113.946 62.1002 113.998 60.1742V57.5195C113.998 54.5524 116.393 52.21 119.308 52.158H123.055C126.022 52.158 128.365 54.5524 128.365 57.4674L128.417 60.2263ZM125.71 60.2263V57.6236C125.71 56.1661 124.565 54.9689 123.055 54.9689C123.055 54.9689 123.055 54.9689 123.003 54.9689H119.308C118.631 54.9689 117.954 55.2291 117.434 55.7497C116.913 56.2702 116.653 56.9469 116.653 57.6236V60.2263C116.653 60.955 116.913 61.6317 117.434 62.1523C117.902 62.6728 118.579 62.9331 119.308 62.9331H123.003C124.513 62.881 125.71 61.7358 125.71 60.2263Z"
        fill={textColor2}
      />
      <Path
        d="M144.606 56.8945C144.658 58.0918 144.138 59.1849 143.305 59.9657C142.368 60.7986 141.223 61.3712 139.974 61.5794L132.946 62.9328V63.6095C132.946 64.13 133.155 64.6505 133.519 65.067C133.883 65.4313 134.404 65.6916 134.924 65.6395H140.13C140.858 65.6395 141.535 65.3793 142.004 64.8587C142.472 64.3382 143.253 64.2862 143.773 64.8067C143.826 64.8067 143.826 64.8587 143.826 64.8587C144.086 65.119 144.19 65.4313 144.19 65.7957C144.19 66.1601 144.086 66.4724 143.826 66.7327C142.837 67.7737 141.483 68.3463 140.078 68.2943H134.872C132.322 68.2943 130.188 66.2121 130.188 63.6615V63.6095V57.5712C130.188 54.6042 132.582 52.2097 135.497 52.2097H139.869C142.524 52.2097 144.606 54.2919 144.606 56.8945C144.606 56.8425 144.606 56.8425 144.606 56.8945ZM141.952 56.8945C141.952 56.374 141.743 55.8535 141.379 55.4891C141.015 55.1247 140.494 54.8644 139.974 54.8644H135.601C134.144 54.8644 132.946 56.0617 132.946 57.5712V60.226L139.974 58.9246C141.275 58.6644 141.952 57.9877 141.952 56.8945Z"
        fill={textColor2}
      />
      <Path
        d="M160.791 66.9412C160.791 67.3055 160.635 67.6178 160.427 67.8781C160.167 68.1384 159.802 68.2945 159.438 68.2945C159.074 68.2945 158.761 68.1384 158.501 67.8781C158.241 67.6178 158.085 67.3055 158.137 66.9412V57.5715C158.137 56.114 156.939 54.8647 155.482 54.8647H151.786C151.057 54.8647 150.433 55.177 149.912 55.6976C149.392 56.2181 149.131 56.8948 149.131 57.6236V66.9932C149.079 67.722 148.455 68.2946 147.726 68.2425C147.049 68.1904 146.529 67.6699 146.477 66.9932V53.5634C146.477 53.199 146.633 52.8867 146.893 52.6264C147.153 52.3661 147.466 52.262 147.83 52.262C148.35 52.21 148.871 52.5744 149.027 53.0428C149.86 52.5223 150.849 52.262 151.838 52.262H155.586C158.553 52.262 160.947 54.6565 160.895 57.6236V66.9412H160.791Z"
        fill={textColor2}
      />
      <Path
        d="M174.431 66.7325C173.442 67.7735 172.088 68.3461 170.683 68.2941H168.028C165.061 68.2941 162.719 65.8996 162.719 62.9326V46.6398C162.719 45.911 163.343 45.2864 164.072 45.2864C164.437 45.2864 164.749 45.4425 165.009 45.7028C165.269 45.9631 165.426 46.2754 165.426 46.6398V52.2095H171.828C172.193 52.2095 172.505 52.3657 172.765 52.5739C173.025 52.8341 173.182 53.1465 173.182 53.5108C173.182 54.2396 172.609 54.8642 171.828 54.8642H165.426V62.9326C165.426 64.3901 166.623 65.5873 168.08 65.5873H170.735C171.464 65.5873 172.14 65.327 172.609 64.8065C173.077 64.2859 173.91 64.2339 174.379 64.7544L174.431 64.8065C174.691 65.0667 174.795 65.3791 174.795 65.7434C174.795 66.1078 174.639 66.4722 174.431 66.7325Z"
        fill={textColor2}
      />
      <Path
        d="M189.997 63.6096C189.997 64.8589 189.529 66.0562 188.644 66.9411C187.811 67.826 186.614 68.3465 185.364 68.3465H180.992C179.586 68.3986 178.233 67.826 177.244 66.7849L176.151 65.6397C175.63 65.1192 175.63 64.2863 176.151 63.7658C176.411 63.5055 176.723 63.3494 177.088 63.3494C177.452 63.3494 177.764 63.5055 178.025 63.7658L179.118 64.911C179.586 65.4315 180.263 65.6918 180.992 65.6918H185.364C185.885 65.6918 186.405 65.4836 186.77 65.1192C187.134 64.7548 187.394 64.2343 187.342 63.7138C187.342 63.3494 187.134 63.0371 186.874 62.7768C186.562 62.5165 186.197 62.3083 185.781 62.2563L179.534 61.1631C178.493 60.9549 177.556 60.4344 176.828 59.7056C176.099 58.9769 175.734 58.0399 175.734 57.0509C175.734 55.8016 176.203 54.6044 177.088 53.7715C177.921 52.8866 179.118 52.3661 180.367 52.4181H184.74C186.145 52.4181 187.498 52.9907 188.488 53.9797L189.581 55.0729C189.841 55.3331 189.997 55.6454 189.997 56.0098C189.997 56.3742 189.841 56.6865 189.581 56.9468C189.112 57.4673 188.279 57.4673 187.759 56.9988C187.759 56.9988 187.759 56.9988 187.707 56.9468L186.562 55.8537C186.093 55.3331 185.416 55.0729 184.688 55.0729H180.367C179.847 55.0729 179.326 55.2811 178.962 55.6454C178.597 56.0098 178.389 56.5304 178.389 57.0509C178.389 57.4153 178.597 57.7796 178.91 57.9879C179.222 58.2481 179.586 58.4563 180.003 58.5604L186.249 59.6536C187.29 59.8097 188.175 60.3303 188.904 61.1111C189.633 61.6316 190.049 62.6206 189.997 63.6096Z"
        fill={textColor2}
      />
      <Path
        d="M48.4096 83.1821H47.4727V76.1028H48.4617V78.6014C48.8781 78.1329 49.3986 77.9247 50.0753 77.9247C50.4397 77.9247 50.752 77.9767 51.1164 78.1329C51.4287 78.237 51.689 78.4452 51.8972 78.6534C52.1054 78.8616 52.2616 79.1739 52.3657 79.4863C52.4698 79.7986 52.5219 80.163 52.5219 80.5273C52.5219 81.4123 52.2616 82.089 51.7931 82.5574C51.2726 83.0259 50.7 83.2862 50.0233 83.2862C49.3466 83.2862 48.774 83.0259 48.4096 82.5054V83.1821ZM48.4096 80.5794C48.4096 81.204 48.5137 81.6205 48.6699 81.9328C48.9822 82.4013 49.3986 82.6095 49.9192 82.6095C50.3356 82.6095 50.7 82.4533 51.0644 82.141C51.3767 81.8287 51.5328 81.3081 51.5328 80.6835C51.5328 80.0068 51.3767 79.5383 51.0644 79.226C50.752 78.9137 50.3877 78.7575 49.9712 78.7575C49.5548 78.7575 49.1384 78.9137 48.8261 79.226C48.5658 79.4863 48.4096 79.9548 48.4096 80.5794Z"
        fill={textColor}
      />
      <Path
        d="M54.2393 85.1599L54.1352 84.327C54.3434 84.3791 54.5516 84.3791 54.7078 84.3791C54.916 84.3791 55.1242 84.327 55.2283 84.2749C55.3845 84.2229 55.4886 84.1188 55.5406 84.0147C55.5927 83.9106 55.6968 83.7024 55.853 83.39C55.853 83.338 55.905 83.2859 55.9571 83.1818L53.7188 78.0806H54.8119L56.0091 81.0476C56.1653 81.412 56.3214 81.8284 56.4255 82.2449C56.5296 81.8284 56.6858 81.4641 56.842 81.0997L58.0913 78.1326H59.0803L56.842 83.338C56.5817 83.9106 56.4255 84.2749 56.2694 84.4832C56.1132 84.7434 55.905 85.0037 55.6447 85.1078C55.4365 85.264 55.1242 85.316 54.8119 85.316C54.6557 85.264 54.4475 85.2119 54.2393 85.1599Z"
        fill={textColor}
      />
      <Path
        d="M63.7656 80.8916L64.7546 80.8395C64.8067 81.2039 64.9108 81.4642 65.067 81.7244C65.2231 81.9847 65.5354 82.1409 65.8998 82.297C66.2642 82.4532 66.6806 82.5052 67.1491 82.5052C67.5655 82.5052 67.9299 82.4532 68.2422 82.3491C68.5546 82.245 68.7628 82.0888 68.9189 81.9326C69.0751 81.7765 69.1271 81.5683 69.1271 81.308C69.1271 81.0998 69.0751 80.8916 68.9189 80.7354C68.7628 80.5793 68.5546 80.4231 68.1902 80.319C67.982 80.2669 67.5135 80.1108 66.7327 79.9546C66.0039 79.7985 65.4834 79.6423 65.1711 79.5382C64.8067 79.382 64.4944 79.1218 64.2862 78.8615C64.0779 78.6012 64.0259 78.2889 64.0259 77.9766C64.0259 77.6122 64.13 77.2999 64.3903 76.9876C64.5985 76.6752 64.9629 76.467 65.3793 76.2588C65.7957 76.0506 66.3162 75.9985 66.8368 75.9985C67.4094 75.9985 67.982 76.1026 68.3984 76.2588C68.8669 76.415 69.2313 76.6752 69.4395 76.9876C69.6997 77.2999 69.8038 77.6643 69.8559 78.0807L68.8148 78.1327C68.7628 77.6643 68.5546 77.3519 68.2422 77.1437C67.9299 76.9355 67.4614 76.7793 66.8368 76.7793C66.2121 76.7793 65.7437 76.8834 65.4313 77.0917C65.119 77.2999 64.9629 77.5601 64.9629 77.8204C64.9629 78.0807 65.067 78.2889 65.2752 78.4451C65.4834 78.6012 66.0039 78.7574 66.8888 78.9656C67.7738 79.1218 68.3463 79.2779 68.6587 79.4341C69.1271 79.6423 69.4915 79.8505 69.6997 80.1628C69.9079 80.4751 70.0121 80.7875 70.0121 81.1518C70.0121 81.5162 69.908 81.8806 69.6477 82.1929C69.3874 82.5052 69.0751 82.7655 68.6066 82.9737C68.1381 83.1819 67.6176 83.234 67.045 83.234C66.3163 83.234 65.6916 83.1299 65.2231 82.9737C64.7546 82.7655 64.3382 82.5052 64.0779 82.1409C63.9218 81.8285 63.7656 81.3601 63.7656 80.8916Z"
        fill={textColor}
      />
      <Path
        d="M71.9883 85.1597V78.0804H72.8732V78.7571C73.0814 78.4969 73.3417 78.2886 73.6019 78.1845C73.8622 78.0804 74.1745 77.9763 74.591 77.9763C75.1115 77.9763 75.5279 78.0804 75.8923 78.2886C76.2567 78.4968 76.569 78.8092 76.7772 79.2256C76.9854 79.642 77.0895 80.0585 77.0895 80.579C77.0895 81.0995 76.9854 81.568 76.7772 81.9844C76.569 82.4009 76.2567 82.7132 75.8402 82.9214C75.4238 83.1296 75.0074 83.2337 74.5389 83.2337C74.2266 83.2337 73.9143 83.1817 73.654 83.0255C73.3937 82.9214 73.1855 82.7653 73.0294 82.557V85.0556H71.9883V85.1597ZM72.8732 80.631C72.8732 81.3077 73.0293 81.7762 73.3417 82.0886C73.654 82.4009 74.0184 82.557 74.4348 82.557C74.8512 82.557 75.2677 82.4009 75.58 82.0886C75.8923 81.7762 76.0485 81.2557 76.0485 80.579C76.0485 79.9023 75.8923 79.4338 75.58 79.1215C75.2677 78.8092 74.9033 78.653 74.4869 78.653C74.0704 78.653 73.706 78.8092 73.3417 79.1736C73.0293 79.4859 72.8732 80.0064 72.8732 80.631Z"
        fill={textColor}
      />
      <Path
        d="M82.7137 81.5163L83.7548 81.6204C83.5986 82.1409 83.2863 82.5573 82.8699 82.8176C82.4534 83.0779 81.8809 83.234 81.2042 83.234C80.3713 83.234 79.6946 83.0258 79.1741 82.5573C78.6535 82.0889 78.4453 81.4642 78.4453 80.6313C78.4453 79.7464 78.7056 79.1218 79.2261 78.6533C79.7467 78.1848 80.3713 77.9246 81.2042 77.9246C81.985 77.9246 82.6096 78.1328 83.1301 78.6013C83.6507 79.0697 83.8589 79.7464 83.8589 80.5793C83.8589 80.6314 83.8589 80.6834 83.8589 80.7875H79.4864C79.5384 81.3601 79.6946 81.7765 80.0069 82.0888C80.3192 82.4012 80.7357 82.5573 81.2042 82.5573C81.5685 82.5573 81.8809 82.4532 82.1411 82.2971C82.4014 82.1409 82.6096 81.8806 82.7137 81.5163ZM79.4864 80.1108H82.7658C82.7137 79.6944 82.6096 79.3821 82.4014 79.1218C82.0891 78.8095 81.6726 78.6013 81.1521 78.6013C80.6836 78.6013 80.3192 78.7574 80.0069 79.0177C79.6946 79.33 79.5384 79.6944 79.4864 80.1108Z"
        fill={textColor}
      />
      <Path
        d="M89.3232 81.308L90.3122 81.4122C90.2081 81.9847 89.9478 82.4532 89.4793 82.7655C89.0629 83.0779 88.4903 83.2861 87.8657 83.2861C87.0849 83.2861 86.4082 83.0779 85.9397 82.6094C85.4712 82.1409 85.2109 81.5163 85.2109 80.6313C85.2109 80.0588 85.315 79.5903 85.5233 79.1739C85.7315 78.7574 86.0438 78.4451 86.4602 78.2369C86.8767 78.0287 87.3451 77.9246 87.8657 77.9246C88.4903 77.9246 89.0109 78.0807 89.4273 78.341C89.8437 78.6013 90.104 79.0177 90.2081 79.5382L89.2711 79.6423C89.167 79.278 89.0109 79.0177 88.8026 78.8615C88.5944 78.7054 88.2821 78.6013 87.9698 78.6013C87.4492 78.6013 87.0849 78.7574 86.7725 79.0697C86.4602 79.3821 86.3041 79.9026 86.3041 80.5793C86.3041 81.256 86.4602 81.7765 86.7725 82.0888C87.0849 82.4012 87.4492 82.5573 87.9698 82.5573C88.3342 82.5573 88.6985 82.4532 88.9588 82.245C89.0629 82.0368 89.2191 81.7245 89.3232 81.308Z"
        fill={textColor}
      />
      <Path
        d="M93.7485 82.4012L93.9046 83.182C93.6444 83.234 93.3841 83.234 93.1759 83.234C92.8115 83.234 92.5512 83.182 92.343 83.0779C92.1348 82.9738 92.0307 82.8697 91.9266 82.6614C91.8225 82.4532 91.8225 82.1409 91.8225 81.6204V78.6533H91.0938V77.9766H91.8225V76.7273L92.8115 76.2068V77.9766H93.8005V78.6533H92.8115V81.6204C92.8115 81.8806 92.8115 82.0368 92.8636 82.0888C92.9156 82.1409 92.9677 82.193 93.0197 82.245C93.1238 82.2971 93.2279 82.2971 93.3321 82.2971C93.4362 82.4532 93.5923 82.4012 93.7485 82.4012Z"
        fill={textColor}
      />
      <Path
        d="M94.8438 80.631C94.8438 79.6941 95.1561 78.9653 95.7287 78.5489C96.2492 78.1845 96.8218 77.9763 97.5505 77.9763C98.3313 77.9763 99.008 78.1845 99.5286 78.653C100.049 79.1215 100.309 79.7461 100.309 80.579C100.309 81.2557 100.205 81.7762 99.945 82.1406C99.7368 82.505 99.3724 82.8173 98.956 83.0255C98.5396 83.2337 98.0711 83.3378 97.5505 83.3378C96.7177 83.3378 96.093 83.1296 95.5725 82.6611C95.104 82.1406 94.8438 81.4639 94.8438 80.631ZM95.8848 80.631C95.8848 81.3077 96.041 81.7762 96.3533 82.0886C96.6656 82.4009 97.0821 82.557 97.6026 82.557C98.0711 82.557 98.4875 82.4009 98.8519 82.0886C99.2163 81.7762 99.3204 81.2557 99.3204 80.579C99.3204 79.9544 99.1642 79.4859 98.8519 79.1215C98.5396 78.8092 98.1231 78.653 97.6026 78.653C97.0821 78.653 96.7177 78.8092 96.3533 79.1215C96.041 79.4859 95.8848 79.9544 95.8848 80.631Z"
        fill={textColor}
      />
      <Path
        d="M101.555 81.6724L102.544 81.5163C102.596 81.8806 102.752 82.1409 103.012 82.2971C103.272 82.5053 103.637 82.5573 104.053 82.5573C104.522 82.5573 104.834 82.4532 105.042 82.2971C105.25 82.1409 105.355 81.9327 105.355 81.7245C105.355 81.5163 105.25 81.3601 105.042 81.256C104.886 81.2039 104.574 81.0998 104.053 80.9437C103.325 80.7875 102.804 80.6313 102.544 80.5272C102.283 80.4231 102.075 80.267 101.919 80.0588C101.763 79.8505 101.711 79.6423 101.711 79.3821C101.711 79.1738 101.763 78.9656 101.867 78.7574C101.971 78.5492 102.127 78.393 102.335 78.2889C102.492 78.1848 102.7 78.0807 102.96 78.0287C103.22 77.9766 103.533 77.9246 103.793 77.9246C104.261 77.9246 104.678 77.9766 104.99 78.0807C105.303 78.1848 105.615 78.341 105.771 78.5492C105.927 78.7574 106.031 79.0177 106.135 79.33L105.146 79.4341C105.094 79.1739 104.99 78.9656 104.782 78.8095C104.574 78.6533 104.261 78.6013 103.897 78.6013C103.429 78.6013 103.116 78.6533 102.908 78.8095C102.7 78.9656 102.596 79.0697 102.596 79.278C102.596 79.3821 102.648 79.4862 102.7 79.5903C102.804 79.6944 102.908 79.7464 103.064 79.7985C103.168 79.8505 103.481 79.9026 103.949 80.0067C104.626 80.1629 105.146 80.319 105.407 80.4231C105.667 80.5272 105.875 80.6834 106.083 80.8916C106.24 81.0998 106.344 81.3601 106.344 81.6204C106.344 81.9327 106.24 82.193 106.031 82.4532C105.823 82.7135 105.563 82.9217 105.198 83.0258C104.834 83.182 104.418 83.234 103.949 83.234C103.168 83.234 102.596 83.0779 102.231 82.8176C101.919 82.6094 101.659 82.193 101.555 81.6724Z"
        fill={textColor}
      />
    </Svg>
  );
};

export const MessageCheckIcon = ({ color = '#DA202A', width = 66, height = 66 }) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 66 66" fill="none">
      <G clip-path="url(#clip0_358_1040)">
        <Path
          d="M66 6.30882C66 19.4118 66 32.5147 66 45.8603V46.1029C65.0294 49.9853 62.6029 52.1691 58.4779 52.1691C50.4706 52.1691 42.4632 52.1691 34.2132 52.1691C33.4853 52.1691 32.7574 52.4118 32.2721 52.8971C27.6618 57.0221 23.2941 61.1471 18.6838 65.0294C18.1985 65.2721 17.4706 65.5147 16.9853 66C16.7426 66 16.2574 66 16.0147 66C14.5588 65.2721 14.0735 64.5441 14.3162 63.0882C14.5588 60.9044 14.8015 58.9632 15.0441 56.7794C15.2868 55.0809 15.2868 53.625 15.5294 51.9265C15.2868 51.9265 15.0441 51.9265 14.8015 51.9265C12.375 51.9265 9.94853 51.9265 7.27941 51.9265C3.15441 51.9265 0 48.7721 0 44.6471C0 32.2721 0 19.6544 0 7.27941C0 6.55147 0 6.06618 0.242647 5.33824C0.970588 2.18382 3.88235 0 7.27941 0C23.0515 0 38.8235 0 54.5956 0C56.0515 0 57.5074 0 59.2059 0C61.3897 0 63.3309 0.970588 64.7868 2.91176C65.2721 3.88235 65.7574 5.09559 66 6.30882ZM19.4118 57.75C19.6544 57.5074 19.8971 57.2647 20.1397 57.2647C23.5368 54.3529 26.6912 51.1985 30.0882 48.2868C30.8162 47.5588 31.5441 47.3162 32.7574 47.3162C41.25 47.3162 49.7426 47.3162 58.4779 47.3162C60.4191 47.3162 61.3897 46.3456 61.3897 44.4044C61.3897 32.0294 61.3897 19.8971 61.3897 7.52206C61.3897 5.58088 60.4191 4.61029 58.4779 4.61029C41.4926 4.61029 24.5074 4.61029 7.52206 4.61029C5.58088 4.85294 4.61029 5.58088 4.61029 7.52206C4.61029 19.8971 4.61029 32.0294 4.61029 44.4044C4.61029 46.3456 5.58088 47.3162 7.52206 47.3162C10.9191 47.3162 14.3162 47.3162 17.7132 47.3162C19.4118 47.3162 20.3824 48.2868 20.1397 49.9853C20.1397 50.9559 19.8971 52.1691 19.8971 53.1397C19.8971 54.5956 19.6544 56.0515 19.4118 57.75Z"
          fill={color}
        />
        <G clip-path="url(#clip1_358_1040)">
          <Path
            d="M30.0397 28.8167C30.195 28.6614 30.195 28.5061 30.3503 28.3508C33.7668 24.9344 37.1833 21.5179 40.5997 18.1014C41.0656 17.6355 41.5315 17.325 42.1527 17.325C42.9291 17.325 43.395 17.6355 43.7056 18.2567C44.0162 18.8779 44.0162 19.6544 43.5503 20.1202C43.395 20.2755 43.2397 20.4308 43.0844 20.5861C39.2021 24.4685 35.3197 28.3508 31.2821 32.3885C30.195 33.4755 29.2633 33.4755 28.1762 32.3885C26.6233 30.525 24.7597 28.8167 22.8962 26.9532C22.275 26.1767 22.1197 25.4002 22.4303 24.7791C23.0515 23.5367 24.4491 23.3814 25.5362 24.3132C26.9338 25.7108 28.1762 26.9532 29.5738 28.3508C29.5738 28.5061 29.7291 28.6614 30.0397 28.8167Z"
            fill={color}
          />
        </G>
      </G>
      <Defs>
        <ClipPath id="clip0_358_1040">
          <Rect width="66" height="66" fill="white" />
        </ClipPath>
        <ClipPath id="clip1_358_1040">
          <Rect width="21.7412" height="15.84" fill="white" transform="translate(22.275 17.325)" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export const ThankYouIcon = ({ color = '#DA202A', width = 66, height = 66 }) => {
  return (
    <Svg width="68" height="68" viewBox="0 0 68 68" fill="none">
      <Path
        d="M16.2161 68C15.0066 67.3733 14.8722 67.0151 15.0066 65.8512C15.141 64.3739 15.3202 62.8966 15.4993 61.4194C15.5889 60.7031 15.6785 59.9421 15.7681 59.2258C15.9025 58.0171 16.0369 56.8532 16.1713 55.5997L16.4401 53.0033H9.81028C8.82477 53.0033 7.79447 53.0033 6.80896 53.0033C2.82213 53.0033 0 50.2278 0 46.2436C0 33.1271 0 20.0105 0 6.84924C0 6.22252 0.0447958 5.59579 0.179183 5.10336C0.940711 2.01448 3.53887 0 6.85375 0C15.1858 0 56.8906 0 56.8906 0C57.3386 0 57.8314 0 58.2793 0C58.7273 0 59.22 0 59.668 0C60.4295 0 61.0567 0 61.5942 0.0447663C63.6996 0.134299 65.357 0.984859 66.7009 2.68598C67.4177 3.5813 67.7312 4.70046 68 5.77485V47.1837C67.9552 47.2284 67.9552 47.318 67.9104 47.4075C67.0145 51.2126 64.6851 53.0481 60.8327 53.0481H49.5889C44.8406 53.0481 40.0474 53.0481 35.2991 53.0033C34.2688 53.0033 33.4625 53.3167 32.6561 54.0329C28.3557 57.9724 23.6522 62.1804 18.3215 66.9256C18.0079 67.1942 17.6047 67.4628 17.2016 67.7314C17.0672 67.8209 16.9328 67.9105 16.8432 67.9552H16.2161V68ZM13.6179 49.8697C15.0962 49.8697 16.5744 49.8697 18.0079 49.8697C18.4559 49.8697 19.0382 49.9592 19.3966 50.3621C19.7549 50.765 19.7997 51.3469 19.7549 51.7946C19.7101 52.6004 19.6206 53.4062 19.531 54.1672C19.4862 54.4806 19.4414 54.7939 19.4414 55.1521L18.635 62.3147L20.6957 60.5688C20.8748 60.4345 21.0092 60.3002 21.0988 60.2107L23.7418 57.8381C26.3399 55.5102 29.0277 53.0928 31.6258 50.7202C32.253 50.1383 32.9249 49.8697 33.776 49.8697C38.614 49.8697 43.4071 49.8697 48.2451 49.8697H60.9223C63.4756 49.8697 64.8195 48.5267 64.8195 46.0198C64.8195 33.0375 64.8195 20.1001 64.8195 7.11784C64.8195 4.5214 63.5204 3.22317 60.9223 3.22317H7.07773C4.47958 3.22317 3.1805 4.5214 3.1805 7.11784C3.1805 20.1001 3.1805 33.0375 3.1805 46.0198C3.1805 48.5714 4.52437 49.8697 7.07773 49.9144L13.6179 49.8697Z"
        fill="#8BB73B"
      />
      <Path d="M14.8092 25.4419V18.4281H12V17H19.5235V18.4281H16.7208V25.4419H14.8092Z" fill="#8BB73B" />
      <Path
        d="M20.7699 25.4419V17H22.6815V20.3226H26.4271V17H28.3386V25.4419H26.4271V21.7507H22.6815V25.4419H20.7699Z"
        fill="#8BB73B"
      />
      <Path
        d="M38.8586 25.4419H36.7792L35.9525 23.5244H32.1682L31.3868 25.4419H29.359L33.0465 17H35.0678L38.8586 25.4419ZM35.339 22.102L34.0345 18.9694L32.7559 22.102H35.339Z"
        fill="#8BB73B"
      />
      <Path
        d="M39.8984 25.4419V17H41.7582L45.633 22.6375V17H47.409V25.4419H45.4909L41.6743 19.9368V25.4419H39.8984Z"
        fill="#8BB73B"
      />
      <Path
        d="M49.4626 25.4419V17H51.3741V20.7488L55.236 17H57.8063L54.2415 20.2881L58 25.4419H55.5266L52.924 21.4801L51.3741 22.8909V25.4419H49.4626Z"
        fill="#8BB73B"
      />
      <Path
        d="M23.986 39.856V36.3031L20.518 31.4141H22.759L24.9869 34.754L27.1697 31.4141H29.3719L25.8911 36.3146V39.856H23.986Z"
        fill="#8BB73B"
      />
      <Path
        d="M29.9337 35.6869C29.9337 34.827 30.078 34.1052 30.3664 33.5217C30.5817 33.0917 30.8744 32.7059 31.2447 32.3643C31.6193 32.0226 32.0283 31.7692 32.4717 31.6041C33.0615 31.3815 33.7418 31.2701 34.5124 31.2701C35.9073 31.2701 37.0224 31.656 37.8576 32.4276C38.6972 33.1992 39.1169 34.2722 39.1169 35.6466C39.1169 37.0094 38.7015 38.0767 37.8706 38.8483C37.0396 39.6161 35.9289 40 34.5383 40C33.1304 40 32.011 39.618 31.1801 38.8541C30.3492 38.0863 29.9337 37.0305 29.9337 35.6869ZM31.9034 35.6293C31.9034 36.5852 32.151 37.3108 32.6461 37.806C33.1412 38.2974 33.7698 38.5431 34.5318 38.5431C35.2938 38.5431 35.9181 38.2993 36.4046 37.8118C36.8954 37.3204 37.1408 36.5852 37.1408 35.6063C37.1408 34.6389 36.9019 33.9171 36.424 33.4411C35.9504 32.9651 35.3197 32.727 34.5318 32.727C33.7439 32.727 33.1089 32.9689 32.6267 33.4526C32.1445 33.9325 31.9034 34.6581 31.9034 35.6293Z"
        fill="#8BB73B"
      />
      <Path
        d="M40.6023 31.4141H42.5138V35.9863C42.5138 36.7119 42.5375 37.1822 42.5849 37.3972C42.6667 37.7427 42.8604 38.021 43.1661 38.2321C43.4761 38.4395 43.898 38.5431 44.4318 38.5431C44.9743 38.5431 45.3833 38.4452 45.6589 38.2494C45.9344 38.0498 46.1001 37.806 46.1561 37.5181C46.2121 37.2302 46.2401 36.7522 46.2401 36.0842V31.4141H48.1516V35.8481C48.1516 36.8616 48.1 37.5776 47.9966 37.996C47.8933 38.4145 47.7017 38.7677 47.4219 39.0556C47.1463 39.3435 46.7761 39.5739 46.3111 39.7466C45.8461 39.9155 45.2391 40 44.49 40C43.5858 40 42.8992 39.9079 42.4299 39.7236C41.9649 39.5355 41.5968 39.2936 41.3256 38.998C41.0543 38.6986 40.8757 38.3857 40.7896 38.0594C40.6647 37.5757 40.6023 36.8616 40.6023 35.9172V31.4141Z"
        fill="#8BB73B"
      />
    </Svg>
  );
};

export const WarningIcon = ({ color = '#DA202A', width = 38, height = 38 }) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 38 38" fill="none">
      <Path
        d="M18.2617 17.6777L17.6201 11.1387V8.11523H20.292V11.1387L19.6592 17.6777H18.2617ZM17.7256 21V18.5303H20.1953V21H17.7256Z"
        fill={color}
      />
      <Path
        d="M38 3.63235C38 11.1765 38 18.7206 38 26.4044V26.5441C37.4412 28.7794 36.0441 30.0368 33.6691 30.0368C29.0588 30.0368 24.4485 30.0368 19.6985 30.0368C19.2794 30.0368 18.8603 30.1765 18.5809 30.4559C15.9265 32.8309 13.4118 35.2059 10.7574 37.4412C10.4779 37.5809 10.0588 37.7206 9.77941 38C9.63971 38 9.36029 38 9.22059 38C8.38235 37.5809 8.10294 37.1618 8.24265 36.3235C8.38235 35.0662 8.52206 33.9485 8.66176 32.6912C8.80147 31.7132 8.80147 30.875 8.94118 29.8971C8.80147 29.8971 8.66176 29.8971 8.52206 29.8971C7.125 29.8971 5.72794 29.8971 4.19118 29.8971C1.81618 29.8971 0 28.0809 0 25.7059C0 18.5809 0 11.3162 0 4.19118C0 3.77206 0 3.49265 0.139706 3.07353C0.558824 1.25735 2.23529 0 4.19118 0C13.2721 0 22.3529 0 31.4338 0C32.2721 0 33.1103 0 34.0882 0C35.3456 0 36.4632 0.558824 37.3015 1.67647C37.5809 2.23529 37.8603 2.93382 38 3.63235ZM11.1765 33.25C11.3162 33.1103 11.4559 32.9706 11.5956 32.9706C13.5515 31.2941 15.3676 29.4779 17.3235 27.8015C17.7426 27.3824 18.1618 27.2426 18.8603 27.2426C23.75 27.2426 28.6397 27.2426 33.6691 27.2426C34.7868 27.2426 35.3456 26.6838 35.3456 25.5662C35.3456 18.4412 35.3456 11.4559 35.3456 4.33088C35.3456 3.21323 34.7868 2.65441 33.6691 2.65441C23.8897 2.65441 14.1103 2.65441 4.33088 2.65441C3.21323 2.79412 2.65441 3.21323 2.65441 4.33088C2.65441 11.4559 2.65441 18.4412 2.65441 25.5662C2.65441 26.6838 3.21323 27.2426 4.33088 27.2426C6.28676 27.2426 8.24265 27.2426 10.1985 27.2426C11.1765 27.2426 11.7353 27.8015 11.5956 28.7794C11.5956 29.3382 11.4559 30.0368 11.4559 30.5956C11.4559 31.4338 11.3162 32.2721 11.1765 33.25Z"
        fill={color}
      />
    </Svg>
  );
};

export const QRIcon2 = ({color = 'white'}) => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <Path
        d="M4.28571 11.7143H2.85714V13.1429H4.28571V11.7143ZM4.28571 3.14286H2.85714V4.57143H4.28571V3.14286ZM12.8571 3.14286H11.4286V4.57143H12.8571V3.14286ZM1.42857 14.5603V10.2857H5.71429V14.5603H1.42857ZM1.42857 6V1.71429H5.71429V6H1.42857ZM10 6V1.71429H14.2857V6H10ZM7.14286 8.85714H0V16H7.14286V8.85714ZM12.8571 14.5714H11.4286V16H12.8571V14.5714ZM15.7143 14.5714H14.2857V16H15.7143V14.5714ZM15.7143 8.85714H14.2857V10.2857H12.8571V8.85714H8.57143V16H10V11.7143H11.4286V13.1429H15.7143V8.85714ZM7.14286 0.285713H0V7.42857H7.14286V0.285713ZM15.7143 0.285713H8.57143V7.42857H15.7143V0.285713Z"
        fill={color}
      />
    </Svg>
  );
};

export const HomeIcon = ({ color = '#404044' }) => {
  return (
    <Svg width="18" height="14" viewBox="0 0 18 14" fill="none">
      <Path
        d="M14.9286 8.23214C14.9286 8.21094 14.9286 8.18973 14.918 8.16853L8.82143 3.14286L2.72489 8.16853C2.72489 8.18973 2.71429 8.21094 2.71429 8.23214V13.3214C2.71429 13.6925 3.02176 14 3.39286 14H7.46429V9.92857H10.1786V14H14.25C14.6211 14 14.9286 13.6925 14.9286 13.3214V8.23214ZM17.293 7.50056C17.4096 7.36272 17.3884 7.14007 17.2506 7.02344L14.9286 5.09375V0.767857C14.9286 0.577009 14.7801 0.428571 14.5893 0.428571H12.5536C12.3627 0.428571 12.2143 0.577009 12.2143 0.767857V2.83538L9.62723 0.672432C9.18192 0.301338 8.46094 0.301338 8.01563 0.672432L0.392299 7.02344C0.254464 7.14007 0.233259 7.36272 0.349888 7.50056L1.00725 8.28516C1.06027 8.34877 1.14509 8.39118 1.22991 8.40179C1.32533 8.41239 1.41016 8.38058 1.48438 8.32757L8.82143 2.20982L16.1585 8.32757C16.2221 8.38058 16.2963 8.40179 16.3811 8.40179C16.3917 8.40179 16.4023 8.40179 16.4129 8.40179C16.4978 8.39118 16.5826 8.34877 16.6356 8.28516L17.293 7.50056Z"
        fill={color}
      />
    </Svg>
  );
};

export const TaskIcon = ({ color = '#898989' }) => {
  return (
    <Svg width="18" height="18" viewBox="0 0 18 18" fill="none">
      <G clipPath="url(#clip0_315_863)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3818 17.2909V15.3818C16.0364 15.3818 16.6909 15.3818 17.2909 15.3273C17.7273 15.3273 18 15.0545 18 14.6182V0.709091C18 0.272727 17.7818 0 17.2909 0H3.38182C2.89091 0 2.67273 0.272727 2.67273 0.709091V2.45455V2.67273H0.763636C0.218182 2.67273 0 2.89091 0 3.43636V17.2909C0 17.8364 0.218182 18.0545 0.763636 18.0545H14.6182C15.1636 18.0545 15.3818 17.8364 15.3818 17.2909ZM1.36364 16.6909V4.03636H14.0182V16.6909H1.36364ZM4.03636 1.36364H16.6909V13.9636H15.3818V13.7455V3.49091C15.3818 2.89091 15.1636 2.67273 14.5636 2.67273H4.30909H4.03636V1.36364ZM4.03636 11.3453H6.21818H6.65454V11.509V13.909C6.65454 14.2363 6.81818 14.4544 7.09091 14.5635C7.58182 14.7272 8.01818 14.3999 8.01818 13.909V11.509V11.2908H8.29091H10.6364C11.1273 11.2908 11.4 11.0726 11.4 10.6363C11.4 10.1999 11.1273 9.98171 10.6364 9.98171H8.34545H8.01818V9.76353V7.36353C8.01818 6.98171 7.74545 6.70898 7.36363 6.70898C6.98182 6.70898 6.70909 6.98171 6.70909 7.41808V9.81808V10.0363H6.43636H4.03636C3.6 10.0363 3.32727 10.2544 3.32727 10.6908C3.32727 11.0726 3.6 11.3453 4.03636 11.3453Z"
          fill={color}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_315_863">
          <Rect width="18" height="18" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
// #DA1F2A
export const FAQIcon = ({ color = '#404044', colorText = 'white' }) => {
  return (
    <Svg width="22" height="17" viewBox="0 0 22 17" fill="none">
      <Path
        d="M20.9028 4.71802V10.4786C20.9028 11.4113 20.1347 12.1794 19.2021 12.1794H7.7906L5.59608 13.9898H16.2943L19.3667 16.4587L19.3118 13.9898H20.2993C21.232 13.9898 22.0001 13.3315 22.0001 12.5634V6.08959C22.0001 5.43123 21.5612 4.93747 20.9028 4.71802Z"
        fill={color}
      />
      <Path
        d="M17.995 0H1.70075C0.76808 0 0 0.76808 0 1.70075V9.32668C0 10.2045 0.76808 10.9726 1.70075 10.9726H3.23691V13.99L6.80299 10.9726H18.0499C18.9825 10.9726 19.7506 10.2045 19.7506 9.27182V1.70075C19.6958 0.76808 18.9277 0 17.995 0Z"
        fill={color}
      />
      {/*<Path*/}
      {/*  d="M5.92508 3.62104H3.78543V4.99261H5.5959C5.76049 4.99261 5.87022 5.04747 5.97994 5.10234C6.03481 5.1572 6.08967 5.26692 6.08967 5.37665C6.08967 5.48638 6.03481 5.5961 5.97994 5.65096C5.92508 5.70583 5.76049 5.76069 5.5959 5.76069H3.78543V7.5163C3.78543 7.73575 3.73057 7.90034 3.62084 8.01007C3.51111 8.17465 3.40139 8.22952 3.2368 8.22952C3.07221 8.22952 2.96249 8.17465 2.85276 8.06493C2.74303 7.9552 2.68817 7.79062 2.68817 7.57116V3.45645C2.68817 3.29186 2.68817 3.18214 2.74303 3.07241C2.7979 2.96268 2.85276 2.90782 2.96249 2.85296C3.07221 2.7981 3.18194 2.7981 3.34653 2.7981H5.92508C6.08967 2.7981 6.25426 2.85296 6.30912 2.90782C6.41885 2.96268 6.41885 3.07241 6.41885 3.237C6.41885 3.34672 6.36398 3.45645 6.30912 3.56618C6.25426 3.6759 6.08967 3.62104 5.92508 3.62104Z"*/}
      {/*  fill={colorText}*/}
      {/*/>*/}
      {/*<Path*/}
      {/*  d="M10.1495 7.51609L9.87524 6.85774H7.68072L7.40641 7.57096C7.29668 7.84527 7.24182 8.00986 7.13209 8.11959C7.07723 8.22931 6.9675 8.28417 6.80292 8.28417C6.69319 8.28417 6.5286 8.22931 6.41888 8.11959C6.30915 8.00986 6.25429 7.90013 6.25429 7.79041C6.25429 7.73555 6.25429 7.62582 6.30915 7.57096C6.36401 7.51609 6.36401 7.40637 6.41888 7.24178L7.79045 3.73056C7.84531 3.62083 7.90017 3.51111 7.95504 3.34652C8.0099 3.18193 8.06476 3.0722 8.11962 3.01734C8.22935 2.90761 8.28421 2.79789 8.39394 2.74303C8.50366 2.68816 8.61339 2.6333 8.77798 2.6333C8.94257 2.6333 9.05229 2.68816 9.16202 2.74303C9.27174 2.79789 9.32661 2.85275 9.38147 2.96248C9.43633 3.0722 9.4912 3.12707 9.54606 3.23679C9.60092 3.34652 9.65578 3.45624 9.71065 3.67569L11.1371 7.13205C11.2468 7.40637 11.3017 7.57096 11.3017 7.68068C11.3017 7.79041 11.2468 7.90013 11.1371 8.00986C11.0274 8.11959 10.9176 8.22931 10.753 8.22931C10.6433 8.22931 10.5885 8.22931 10.5336 8.17445C10.4787 8.11959 10.4239 8.11959 10.369 8.06472C10.3141 8.00986 10.2593 7.955 10.2593 7.84527C10.2593 7.73555 10.1495 7.62582 10.1495 7.51609ZM7.95504 6.0348H9.54606L8.77798 3.84028L7.95504 6.0348Z"*/}
      {/*  fill={colorText}*/}
      {/*/>*/}
      {/*<Path*/}
      {/*  d="M16.2941 7.46123C16.4587 7.57096 16.6233 7.68068 16.8427 7.79041C17.0073 7.90013 17.1719 7.955 17.2268 8.00986C17.2816 8.06472 17.3365 8.17445 17.3365 8.28417C17.3365 8.33904 17.2816 8.44876 17.2268 8.50362C17.1719 8.55849 17.0622 8.61335 16.9524 8.61335C16.8427 8.61335 16.7879 8.61335 16.6233 8.55849C16.5135 8.50363 16.349 8.44876 16.1844 8.33904C16.0198 8.22931 15.8552 8.11959 15.6357 7.955C15.3066 8.11959 14.8677 8.22931 14.319 8.22931C13.8801 8.22931 13.551 8.17445 13.2218 8.00986C12.8926 7.90013 12.6183 7.68068 12.3988 7.46123C12.1794 7.24178 12.0148 6.9126 11.9051 6.58343C11.7953 6.25425 11.7405 5.87021 11.7405 5.43131C11.7405 4.9924 11.7953 4.66323 11.9051 4.27919C12.0148 3.95001 12.1794 3.62083 12.3988 3.40138C12.6183 3.18193 12.8926 2.96248 13.2218 2.85275C13.551 2.74303 13.935 2.6333 14.319 2.6333C14.8677 2.6333 15.3614 2.74303 15.7455 2.96248C16.1295 3.18193 16.4587 3.51111 16.6781 3.95001C16.8976 4.38891 17.0073 4.88268 17.0073 5.43131C17.0073 6.30911 16.7879 6.96747 16.2941 7.46123ZM15.4712 6.9126C15.6357 6.74801 15.7455 6.52856 15.8003 6.30911C15.8552 6.0348 15.9101 5.76048 15.9101 5.43131C15.9101 5.04727 15.8552 4.71809 15.7455 4.38891C15.6357 4.1146 15.4163 3.89515 15.1968 3.73056C14.9774 3.56597 14.7031 3.51111 14.3739 3.51111C14.1544 3.51111 13.935 3.56597 13.7704 3.62083C13.6058 3.67569 13.4412 3.84028 13.2766 4.00487C13.1669 4.16946 13.0572 4.38891 12.9475 4.60836C12.8926 4.82781 12.8377 5.10213 12.8377 5.43131C12.8377 6.0348 13.0023 6.52856 13.2766 6.85774C13.551 7.18692 13.935 7.35151 14.3739 7.35151C14.5385 7.35151 14.7579 7.29664 14.9225 7.24178C14.8128 7.13205 14.6482 7.07719 14.4836 6.96747C14.319 6.85774 14.2093 6.80288 14.1544 6.74801C14.0996 6.69315 14.0447 6.63829 14.0447 6.52856C14.0447 6.4737 14.0996 6.36397 14.1544 6.30911C14.2093 6.25425 14.2642 6.19939 14.3739 6.19939C14.5385 6.30911 14.9225 6.52856 15.4712 6.9126Z"*/}
      {/*  fill={colorText}*/}
      {/*/>*/}
    </Svg>
  );
};

export const MenuIcon = ({ color = '#898989' }) => {
  return (
    <Svg width="22" height="17" viewBox="0 0 22 17" fill="none">
      <G clipPath="url(#clip0_145_18)">
        <Path
          d="M11 13.7C14.0905 13.7 17.1809 13.7 20.2714 13.7C21.2667 13.7 22 14.4333 22 15.4286C21.9476 16.2667 21.2667 16.9476 20.4286 17C20.3762 17 20.2714 17 20.219 17C14.0905 17 7.90952 17 1.72857 17C0.942853 17 0.314282 16.581 0.0523773 15.8476C-0.261908 14.8 0.523806 13.7 1.62381 13.7C3.03809 13.7 4.4 13.7 5.81428 13.7C7.54285 13.7 9.27142 13.7 11 13.7Z"
          fill={color}
          fillOpacity="0.8"
        />
        <Path
          d="M12.5714 7.0999C15.1381 7.0999 17.7571 7.0999 20.3238 7.0999C21.319 7.0999 22.0524 7.83324 22 8.82847C21.9476 9.66657 21.319 10.3475 20.4809 10.3999C20.4286 10.3999 20.3238 10.3999 20.2714 10.3999C15.1381 10.3999 10.0048 10.3999 4.87143 10.3999C4.03333 10.3999 3.35238 9.87609 3.19524 9.14276C2.98571 8.09514 3.71905 7.0999 4.76666 7.0999C6.12857 7.0999 7.49047 7.0999 8.85238 7.0999C10.0571 7.0999 11.3143 7.0999 12.5714 7.0999Z"
          fill={color}
          fillOpacity="0.8"
        />
        <Path
          d="M15.6619 0.500049C17.2333 0.500049 18.8048 0.500049 20.3762 0.500049C21.0571 0.500049 21.5286 0.814335 21.8429 1.44291C22.1048 2.0191 22.0524 2.59529 21.6857 3.1191C21.3714 3.59053 20.8476 3.80005 20.3238 3.80005C17.8619 3.80005 15.4 3.80005 12.9381 3.80005C12.3095 3.80005 11.681 3.80005 11.1048 3.80005C10.1619 3.80005 9.42857 3.06672 9.42857 2.12386C9.42857 1.181 10.1619 0.500049 11.1048 0.500049C12.5714 0.500049 14.1429 0.500049 15.6619 0.500049Z"
          fill={color}
          fillOpacity="0.8"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_145_18">
          <Rect width="22" height="16.5" fill="white" transform="matrix(1 0 0 -1 0 17)" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export const BackIcon = () => {
  return (
    <Svg width="25" height="21" viewBox="0 0 25 21" fill="none">
      <Path
        d="M0 10.3233C0 9.89224 0.172415 9.54741 0.517242 9.375C0.517242 9.375 0.517242 9.28879 0.603449 9.28879L9.48276 0.323276C9.91379 -0.107759 10.6897 -0.107759 11.1207 0.323276C11.5517 0.75431 11.5517 1.53017 11.1207 1.96121L3.96552 9.11638H6.63793H10.5172H13.6207H15.9483H18.3621H22.2414H23.7931C24.4828 9.11638 25 9.63362 25 10.3233C25 11.0129 24.4828 11.5302 23.7931 11.5302H22.2414H18.3621H16.1207H13.7931H10.6897H6.81034H4.13793L11.2931 18.6853C11.7241 19.1164 11.7241 19.8922 11.2931 20.3233C10.8621 20.7543 10.0862 20.7543 9.65517 20.3233L0.775862 11.444L0.689655 11.3578C0.172413 11.0991 0 10.6681 0 10.3233Z"
        fill="#666666"
      />
    </Svg>
  );
};

export const NoAvatarIcon = ({ width = 40, height = 40 }) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 40 41" fill="none">
      <Path
        d="M20 0.714283C8.95089 0.714283 0 9.66518 0 20.7143C0 31.7188 8.92857 40.7143 20 40.7143C31.0938 40.7143 40 31.6964 40 20.7143C40 9.66518 31.0491 0.714283 20 0.714283ZM33.817 30.8705C33.0134 26.875 31.0714 23.5714 26.9866 23.5714C25.1786 25.3348 22.7232 26.4286 20 26.4286C17.2768 26.4286 14.8214 25.3348 13.0134 23.5714C8.92857 23.5714 6.98661 26.875 6.18304 30.8705C4.10714 28.0134 2.85714 24.5089 2.85714 20.7143C2.85714 11.2723 10.558 3.57143 20 3.57143C29.442 3.57143 37.1429 11.2723 37.1429 20.7143C37.1429 24.5089 35.8929 28.0134 33.817 30.8705ZM28.5714 16.4286C28.5714 21.1607 24.7321 25 20 25C15.2679 25 11.4286 21.1607 11.4286 16.4286C11.4286 11.6964 15.2679 7.85714 20 7.85714C24.7321 7.85714 28.5714 11.6964 28.5714 16.4286Z"
        fill="#B3B3B3"
      />
    </Svg>
  );
};

export const ChevronRightIcon = () => {
  return (
    <Svg width="6" height="11" viewBox="0 0 6 11" fill="none">
      <Path
        d="M5.97656 5.21429C5.97656 5.13393 5.93638 5.04353 5.87612 4.98326L1.19531 0.302455C1.13504 0.242188 1.04464 0.202008 0.964286 0.202008C0.883929 0.202008 0.793527 0.242188 0.733259 0.302455L0.231027 0.804688C0.170759 0.864955 0.13058 0.955357 0.13058 1.03571C0.13058 1.11607 0.170759 1.20647 0.231027 1.26674L4.17857 5.21429L0.231027 9.16183C0.170759 9.2221 0.13058 9.3125 0.13058 9.39286C0.13058 9.48326 0.170759 9.56362 0.231027 9.62388L0.733259 10.1261C0.793527 10.1864 0.883929 10.2266 0.964286 10.2266C1.04464 10.2266 1.13504 10.1864 1.19531 10.1261L5.87612 5.44531C5.93638 5.38504 5.97656 5.29464 5.97656 5.21429Z"
        fill="#666666"
      />
    </Svg>
  );
};

export const PencilIcon = ({color = '#666666', size = 16}) => {
  return (
    <Svg width={size} height={size} viewBox={`0 0 12 12`} fill="none">
      <Path
        d="M2.83594 11H2V10H1V9.16406L1.71094 8.45312L3.54688 10.2891L2.83594 11ZM6.92188 3.75C6.92188 3.79687 6.90625 3.84375 6.86719 3.88281L2.63281 8.11719C2.59375 8.15625 2.54688 8.17187 2.5 8.17187C2.39844 8.17187 2.32813 8.10156 2.32813 8C2.32813 7.95312 2.34375 7.90625 2.38281 7.86719L6.61719 3.63281C6.65625 3.59375 6.70313 3.57812 6.75 3.57812C6.85156 3.57812 6.92188 3.64844 6.92188 3.75ZM6.5 2.25L0 8.75V12H3.25L9.75 5.5L6.5 2.25ZM11.8359 3C11.8359 2.73437 11.7266 2.47656 11.5469 2.28906L9.71094 0.460937C9.52344 0.273437 9.26563 0.164062 9 0.164062C8.73438 0.164062 8.47656 0.273437 8.29688 0.460937L7 1.75L10.25 5L11.5469 3.70312C11.7266 3.52344 11.8359 3.26562 11.8359 3Z"
        fill={color}
      />
    </Svg>
  );
};

export const PassedIcon = () => {
  return (
    <Svg width="16" height="17" viewBox="0 0 16 17" fill="none">
      <Path
        d="M12.8973 6.9442C12.8973 7.11496 12.8371 7.27567 12.7165 7.39621L7.26228 12.8504C7.14174 12.971 6.97098 13.0413 6.80022 13.0413C6.63951 13.0413 6.46875 12.971 6.34821 12.8504L2.71205 9.21429C2.59152 9.09375 2.53125 8.93304 2.53125 8.76228C2.53125 8.59152 2.59152 8.42076 2.71205 8.30022L3.62612 7.39621C3.74665 7.27567 3.90737 7.20536 4.07813 7.20536C4.24888 7.20536 4.4096 7.27567 4.53013 7.39621L6.80022 9.66629L10.8984 5.57812C11.019 5.45759 11.1797 5.38728 11.3504 5.38728C11.5212 5.38728 11.6819 5.45759 11.8025 5.57812L12.7165 6.48214C12.8371 6.60268 12.8973 6.77344 12.8973 6.9442ZM15.4286 8.57143C15.4286 4.3125 11.9732 0.857142 7.71429 0.857142C3.45536 0.857142 0 4.3125 0 8.57143C0 12.8304 3.45536 16.2857 7.71429 16.2857C11.9732 16.2857 15.4286 12.8304 15.4286 8.57143Z"
        fill="#8BB73B"
      />
    </Svg>
  );
};

export const FailedIcon = () => {
  return (
    <Svg width="16" height="17" viewBox="0 0 16 17" fill="none">
      <Path
        d="M11.5413 10.8415C11.5413 11.0123 11.471 11.183 11.3504 11.3036L10.4464 12.2076C10.3259 12.3281 10.1551 12.3984 9.98438 12.3984C9.81362 12.3984 9.6529 12.3281 9.53237 12.2076L7.71429 10.3895L5.89621 12.2076C5.77567 12.3281 5.61496 12.3984 5.4442 12.3984C5.27344 12.3984 5.10268 12.3281 4.98214 12.2076L4.07813 11.3036C3.95759 11.183 3.88728 11.0123 3.88728 10.8415C3.88728 10.6708 3.95759 10.51 4.07813 10.3895L5.89621 8.57143L4.07813 6.75335C3.95759 6.63281 3.88728 6.4721 3.88728 6.30134C3.88728 6.13058 3.95759 5.95982 4.07813 5.83929L4.98214 4.93527C5.10268 4.81473 5.27344 4.74442 5.4442 4.74442C5.61496 4.74442 5.77567 4.81473 5.89621 4.93527L7.71429 6.75335L9.53237 4.93527C9.6529 4.81473 9.81362 4.74442 9.98438 4.74442C10.1551 4.74442 10.3259 4.81473 10.4464 4.93527L11.3504 5.83929C11.471 5.95982 11.5413 6.13058 11.5413 6.30134C11.5413 6.4721 11.471 6.63281 11.3504 6.75335L9.53237 8.57143L11.3504 10.3895C11.471 10.51 11.5413 10.6708 11.5413 10.8415ZM15.4286 8.57143C15.4286 4.3125 11.9732 0.857142 7.71429 0.857142C3.45536 0.857142 0 4.3125 0 8.57143C0 12.8304 3.45536 16.2857 7.71429 16.2857C11.9732 16.2857 15.4286 12.8304 15.4286 8.57143Z"
        fill="#DA202A"
      />
    </Svg>
  );
};

export const ArrowRightIcon = () => {
  return (
    <Svg width="5" height="9" viewBox="0 0 5 9" fill="none">
      <Path
        d="M4.98047 4.17857C4.98047 4.11161 4.94699 4.03627 4.89676 3.98605L0.996094 0.0853788C0.945871 0.0351562 0.870536 0.00167334 0.803571 0.00167334C0.736607 0.00167334 0.661272 0.0351562 0.611049 0.0853788L0.192522 0.503906C0.142299 0.554129 0.108817 0.629464 0.108817 0.696428C0.108817 0.763392 0.142299 0.838727 0.192522 0.888951L3.48214 4.17857L0.192522 7.46819C0.142299 7.51842 0.108817 7.59375 0.108817 7.66071C0.108817 7.73605 0.142299 7.80301 0.192522 7.85324L0.611049 8.27176C0.661272 8.32199 0.736607 8.35547 0.803571 8.35547C0.870536 8.35547 0.945871 8.32199 0.996094 8.27176L4.89676 4.37109C4.94699 4.32087 4.98047 4.24554 4.98047 4.17857Z"
        fill="#666666"
      />
    </Svg>
  );
};

export const ArrowLeftIcon = () => {
  return (
    <Svg width="25" height="21" viewBox="0 0 25 21" fill="none">
      <Path
        d="M0 10.3233C0 9.89224 0.172415 9.54741 0.517242 9.375C0.517242 9.375 0.517242 9.28879 0.603449 9.28879L9.48276 0.323276C9.91379 -0.107759 10.6897 -0.107759 11.1207 0.323276C11.5517 0.75431 11.5517 1.53017 11.1207 1.96121L3.96552 9.11638H6.63793H10.5172H13.6207H15.9483H18.3621H22.2414H23.7931C24.4828 9.11638 25 9.63362 25 10.3233C25 11.0129 24.4828 11.5302 23.7931 11.5302H22.2414H18.3621H16.1207H13.7931H10.6897H6.81034H4.13793L11.2931 18.6853C11.7241 19.1164 11.7241 19.8922 11.2931 20.3233C10.8621 20.7543 10.0862 20.7543 9.65517 20.3233L0.775862 11.444L0.689655 11.3578C0.172413 11.0991 0 10.6681 0 10.3233Z"
        fill="#666666"
      />
    </Svg>
  );
};

export const ArrowBorderIcon = ({
  fillColor = '#F7F7F7',
  strokeColor = '#C6C6C6',
  height = 12,
  width = 13,
  strokeWidth = 2,
}) => {
  return (
    <Svg width={width} height={height} fill="none">
      <Polygon
        points={[width / 2, strokeWidth, width, height + strokeWidth, 0, height + strokeWidth]}
        fill={fillColor}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </Svg>
  );
};

export const ClearIcon = ({ color = '#666666' }) => {
  return (
    <Svg width="13" height="14" viewBox="0 0 13 14" fill="none">
      <Path
        d="M9.61775 8.5346C9.61775 8.6769 9.55915 8.8192 9.45871 8.91964L8.70536 9.67299C8.60491 9.77344 8.46261 9.83203 8.32031 9.83203C8.17801 9.83203 8.04409 9.77344 7.94364 9.67299L6.42857 8.15792L4.9135 9.67299C4.81306 9.77344 4.67913 9.83203 4.53683 9.83203C4.39453 9.83203 4.25223 9.77344 4.15179 9.67299L3.39844 8.91964C3.29799 8.8192 3.2394 8.6769 3.2394 8.5346C3.2394 8.3923 3.29799 8.25837 3.39844 8.15792L4.9135 6.64286L3.39844 5.12779C3.29799 5.02734 3.2394 4.89341 3.2394 4.75112C3.2394 4.60882 3.29799 4.46652 3.39844 4.36607L4.15179 3.61272C4.25223 3.51228 4.39453 3.45368 4.53683 3.45368C4.67913 3.45368 4.81306 3.51228 4.9135 3.61272L6.42857 5.12779L7.94364 3.61272C8.04409 3.51228 8.17801 3.45368 8.32031 3.45368C8.46261 3.45368 8.60491 3.51228 8.70536 3.61272L9.45871 4.36607C9.55915 4.46652 9.61775 4.60882 9.61775 4.75112C9.61775 4.89341 9.55915 5.02734 9.45871 5.12779L7.94364 6.64286L9.45871 8.15792C9.55915 8.25837 9.61775 8.3923 9.61775 8.5346ZM12.8571 6.64286C12.8571 3.09375 9.97768 0.214285 6.42857 0.214285C2.87946 0.214285 0 3.09375 0 6.64286C0 10.192 2.87946 13.0714 6.42857 13.0714C9.97768 13.0714 12.8571 10.192 12.8571 6.64286Z"
        fill={color}
      />
    </Svg>
  );
};

export const FilterIcon = ({ color = '#666666' }) => {
  return (
    <Svg width="13" height="13" viewBox="0 0 13 13" fill="none">
      <Path
        d="M12.7439 0.612165C12.6602 0.419642 12.4676 0.285714 12.25 0.285714H1.53571C1.31808 0.285714 1.12556 0.419642 1.04185 0.612165C0.958147 0.813058 1 1.04743 1.15904 1.1981L5.28571 5.32478V9.39286C5.28571 9.53516 5.34431 9.66908 5.44475 9.76953L7.58761 11.9124C7.68806 12.0212 7.82199 12.0714 7.96429 12.0714C8.03125 12.0714 8.10659 12.0547 8.17355 12.0296C8.36607 11.9459 8.5 11.7533 8.5 11.5357V5.32478L12.6267 1.1981C12.7857 1.04743 12.8276 0.813058 12.7439 0.612165Z"
        fill={color}
      />
    </Svg>
  );
};

export const ImageIcon = ({ color = '#666666', style }) => {
  return (
    <Svg style={style} width="18" height="21" viewBox="0 0 18 21" fill="none">
      <Path
        d="M16.3839 5.09821L12.9018 1.61607C12.4888 1.20312 11.6629 0.857141 11.0714 0.857141H1.07143C0.479911 0.857141 0 1.33705 0 1.92857V19.7857C0 20.3772 0.479911 20.8571 1.07143 20.8571H16.0714C16.6629 20.8571 17.1429 20.3772 17.1429 19.7857V6.92857C17.1429 6.33705 16.7969 5.51116 16.3839 5.09821ZM11.4286 2.375C11.6183 2.44196 11.808 2.54241 11.8862 2.62054L15.3795 6.11384C15.4576 6.19196 15.558 6.3817 15.625 6.57143H11.4286V2.375ZM15.7143 19.4286H1.42857V2.28571H10V6.92857C10 7.52009 10.4799 8 11.0714 8H15.7143V19.4286ZM14.2857 14.4286L10.7143 10.8571L6.42857 15.1429L5 13.7143L2.85714 15.8571V18H14.2857V14.4286ZM5 12.2857C6.18304 12.2857 7.14286 11.3259 7.14286 10.1429C7.14286 8.95982 6.18304 8 5 8C3.81696 8 2.85714 8.95982 2.85714 10.1429C2.85714 11.3259 3.81696 12.2857 5 12.2857Z"
        fill="#666666"
        fillOpacity="0.5"
      />
    </Svg>
  );
};

export const CirclePlusIcon = ({ color = '#C6C6C6' }) => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Circle cx="12" cy="12" r="11.5" fill={color} stroke={color} />
      <Path
        d="M16.4286 11.6429C16.4286 11.2879 16.1406 11 15.7857 11H13V8.21429C13 7.85937 12.7121 7.57143 12.3571 7.57143H11.0714C10.7165 7.57143 10.4286 7.85937 10.4286 8.21429V11H7.64286C7.28795 11 7 11.2879 7 11.6429V12.9286C7 13.2835 7.28795 13.5714 7.64286 13.5714H10.4286V16.3571C10.4286 16.7121 10.7165 17 11.0714 17H12.3571C12.7121 17 13 16.7121 13 16.3571V13.5714H15.7857C16.1406 13.5714 16.4286 13.2835 16.4286 12.9286V11.6429Z"
        fill="white"
      />
    </Svg>
  );
};

export const SentIcon = () => {
  return (
    <Svg width="33" height="33" viewBox="0 0 33 33" fill="none">
      <Rect width="33" height="33" rx="16.5" fill="#2AB7EB" />
      <Path
        d="M23.7656 9.23493C23.6819 9.17634 23.5731 9.14286 23.4643 9.14286C23.3722 9.14286 23.2801 9.16797 23.1964 9.21819L9.26786 17.2539C9.09208 17.3544 8.99163 17.5469 9 17.7478C9.01674 17.957 9.1423 18.1328 9.33482 18.2081L12.6412 19.5642L21.5893 11.8214L14.3571 20.6858V23.6071C14.3571 23.8331 14.4994 24.034 14.7087 24.1094C14.7673 24.1345 14.8343 24.1429 14.8929 24.1429C15.0519 24.1429 15.2026 24.0759 15.303 23.9503L17.3287 21.481L21.1205 23.0296C21.1875 23.0547 21.2545 23.0714 21.3214 23.0714C21.4135 23.0714 21.5056 23.0463 21.5809 23.0045C21.7232 22.9208 21.8237 22.7868 21.8488 22.6278L23.9916 9.77065C24.0251 9.56138 23.9414 9.36049 23.7656 9.23493Z"
        fill="white"
      />
    </Svg>
  );
};

export const LogoBigIcon = ({ color = 'black', style, width = 99, height = 100, opacity = 0.05 }) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 99 100" fill="none">
      <Path
        d="M88.7262 51.3308L98.1651 44.4867L87.216 39.924L94.7671 30.7985L83.0628 29.6578L87.5935 18.6312L75.8892 20.5323L77.3994 8.74525L66.8278 14.0684L64.94 2.6616L56.2562 10.6464L51.3479 0L45.307 10.2662L37.3783 1.52091L34.3578 12.9278L24.1637 6.84411L24.5413 18.6312L13.2145 15.5894L16.9901 26.9962L5.28582 27.3764L12.0819 37.2624L0.755116 41.0646L9.81651 48.6692L0 54.7529L10.9492 59.3156L3.39802 68.4411L15.1023 69.962L10.5716 80.9886L22.2759 79.0875L20.7657 90.8745L31.3373 85.5513L33.2251 97.3384L41.909 89.3536L47.1948 100L53.2357 89.7338L61.1644 98.4791L64.1849 87.0722L73.2463 92.3954L61.542 77.9468C57.0113 80.2281 51.7255 80.9886 46.0621 80.6084C29.4495 79.0875 17.7452 64.2586 19.2555 47.9087C21.1433 30.7985 35.868 19.0114 52.103 20.5323C68.338 22.0532 80.4199 36.8821 78.9097 53.2319C78.1546 58.5551 76.2668 63.8783 73.2463 68.0608L84.9506 82.5095L81.9301 72.6236L93.6344 72.2434L86.8384 62.3574L98.1651 58.5551L88.7262 51.3308ZM48.3275 26.616C35.4905 26.616 25.2964 36.8821 25.2964 49.8099C25.2964 62.7376 35.4905 73.0038 48.3275 73.0038C61.1644 73.0038 71.3585 62.7376 71.3585 49.8099C71.7361 37.2624 61.1644 26.616 48.3275 26.616ZM44.1743 31.5589C38.1334 33.4601 32.8476 38.0228 30.9598 44.4867C29.072 50.5703 30.2047 57.0342 33.9802 62.7376C34.3578 63.4981 34.7354 64.2586 35.4905 65.019C29.4495 59.6958 26.8066 51.3308 29.4495 43.7262C32.0925 36.1217 39.2661 31.1787 47.1948 30.4183C46.0621 30.7985 45.307 31.1787 44.1743 31.5589ZM67.9605 57.0342C66.4502 61.597 62.2971 65.019 57.3888 65.3992C61.1644 63.8783 64.1849 60.8365 65.6951 56.2738C67.2054 51.711 66.4502 47.5285 64.5625 44.1065C67.9605 47.1483 69.4707 52.4715 67.9605 57.0342Z"
        fill={color}
        fillOpacity={opacity}
      />
    </Svg>
  );
};

export const OverdueIcon = ({ color = 'black', style, width = 99, height = 100, opacity = 0.05 }) => {
  return (
    <Svg width="13" height="14" viewBox="0 0 13 14" fill="none">
      <Path
        d="M8.70424 10.6603C8.70424 10.811 8.58706 10.9282 8.43639 10.9282H4.68639C4.53572 10.9282 4.41853 10.811 4.41853 10.6603V9.32101C4.41853 9.17034 4.53572 9.05315 4.68639 9.05315H5.48995V6.37458H4.68639C4.53572 6.37458 4.41853 6.25739 4.41853 6.10672V4.76744C4.41853 4.61677 4.53572 4.49958 4.68639 4.49958H7.36495C7.51562 4.49958 7.63281 4.61677 7.63281 4.76744V9.05315H8.43639C8.58706 9.05315 8.70424 9.17034 8.70424 9.32101V10.6603ZM7.63281 3.1603C7.63281 3.31097 7.51562 3.42815 7.36495 3.42815H5.75781C5.60714 3.42815 5.48995 3.31097 5.48995 3.1603V1.82101C5.48995 1.67034 5.60714 1.55315 5.75781 1.55315H7.36495C7.51562 1.55315 7.63281 1.67034 7.63281 1.82101V3.1603ZM12.99 6.64244C12.99 3.09333 10.1105 0.213867 6.56139 0.213867C3.01228 0.213867 0.132812 3.09333 0.132812 6.64244C0.132812 10.1915 3.01228 13.071 6.56139 13.071C10.1105 13.071 12.99 10.1915 12.99 6.64244Z"
        fill="#DA202A"
      />
    </Svg>
  );
};

export const AnswerIcon = ({ color = 'black', style, width = 99, height = 100, opacity = 0.05 }) => {
  return (
    <Svg width="13" height="14" viewBox="0 0 13 14" fill="none">
      <Path
        d="M10.8884 5.28641C10.8884 5.42871 10.8382 5.56264 10.7377 5.66309L6.19252 10.2083C6.09208 10.3087 5.94978 10.3673 5.80748 10.3673C5.67355 10.3673 5.53125 10.3087 5.43081 10.2083L2.40067 7.17815C2.30022 7.07771 2.25 6.94378 2.25 6.80148C2.25 6.65918 2.30022 6.51688 2.40067 6.41643L3.16239 5.66309C3.26283 5.56264 3.39676 5.50405 3.53906 5.50405C3.68136 5.50405 3.81529 5.56264 3.91574 5.66309L5.80748 7.55483L9.22266 4.14802C9.3231 4.04757 9.45703 3.98898 9.59933 3.98898C9.74163 3.98898 9.87556 4.04757 9.97601 4.14802L10.7377 4.90137C10.8382 5.00181 10.8884 5.14411 10.8884 5.28641ZM12.9978 6.64244C12.9978 3.09333 10.1183 0.213867 6.5692 0.213867C3.02009 0.213867 0.140625 3.09333 0.140625 6.64244C0.140625 10.1915 3.02009 13.071 6.5692 13.071C10.1183 13.071 12.9978 10.1915 12.9978 6.64244Z"
        fill="#589F28"
      />
    </Svg>
  );
};

export const AwaitingIcon = ({ color = 'black', style, width = 99, height = 100, opacity = 0.05 }) => {
  return (
    <Svg width="13" height="13" viewBox="0 0 13 13" fill="none">
      <Path
        d="M10.7478 5.07255C10.7478 5.21484 10.6975 5.34877 10.5971 5.44922L6.0519 9.99442C5.95145 10.0949 5.80915 10.1535 5.66685 10.1535C5.53292 10.1535 5.39063 10.0949 5.29018 9.99442L2.26004 6.96429C2.1596 6.86384 2.10938 6.72991 2.10938 6.58761C2.10938 6.44531 2.1596 6.30301 2.26004 6.20257L3.02176 5.44922C3.12221 5.34877 3.25614 5.29018 3.39844 5.29018C3.54074 5.29018 3.67467 5.34877 3.77511 5.44922L5.66685 7.34096L9.08203 3.93415C9.18248 3.83371 9.31641 3.77511 9.45871 3.77511C9.60101 3.77511 9.73493 3.83371 9.83538 3.93415L10.5971 4.6875C10.6975 4.78795 10.7478 4.93025 10.7478 5.07255ZM12.8571 6.42857C12.8571 2.87946 9.97768 0 6.42857 0C2.87947 0 0 2.87946 0 6.42857C0 9.97768 2.87947 12.8571 6.42857 12.8571C9.97768 12.8571 12.8571 9.97768 12.8571 6.42857Z"
        fill="#999999"
      />
    </Svg>
  );
};

export const LongArrowIcon = ({color = '#666666'}) =>
    (
        <Svg width="20" height="14" viewBox="0 0 20 14" fill="none">
            <Path
                  d="M20 6.9457C20 7.10989 19.9311 7.27408 19.8278 7.38354L13.2139 13.8417C13.0416 14.0059 12.8177 14.0424 12.6111 13.9511C12.4216 13.8599 12.2838 13.6592 12.2838 13.4221V9.33558H0.551157C0.241131 9.33558 0 9.08017 0 8.75179V5.24908C0 4.9207 0.241131 4.66529 0.551157 4.66529H12.2838V0.578787C12.2838 0.341623 12.4044 0.140947 12.6111 0.0497312C12.8177 -0.0414865 13.0416 -0.00499901 13.2139 0.140947L19.8278 6.52611C19.9311 6.63557 20 6.78151 20 6.9457Z"
                  fill={color}/>
        </Svg>
    )
