import {
  addFavouriteLocationFailed,
  addFavouriteLocationSuccess,
} from '@redux/postingLocations/addFavouriteLocationSlice';
import { addFavouriteLocationService } from '@services/postingLocations';
import { call, put } from 'redux-saga/effects';

export function* addFavouriteLocationSaga({ payload, meta, error }) {
  try {
    const res = yield call(addFavouriteLocationService, payload);
    console.log('deleteFavouriteLocationSaga>>>>', res);
    if (res?.status) {
      yield put(addFavouriteLocationSuccess(res));
      if (meta) {
        yield call(meta, res);
      }
    } else {
      yield put(addFavouriteLocationFailed(res));
      if (error) {
        yield call(error, res);
      }
    }
  } catch (err) {
    yield put(addFavouriteLocationFailed(err));
    yield call(error, err);
  }
}
