import { AppButton, AppSafeAreaView, AppText } from '@components';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import { CollapseView } from '@screens/main/prizeDraw/collapseView';
// import { styles } from '@screens/main/PostingLocation/PostingLocation.style';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle } from '@theme';
import { Font } from '@theme/size';
import { ObjectUtil } from '@utils/common/object';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View } from 'react-native';
import { usePrizeDraw } from './hook';
import { styles } from './styles';

export const PrizeDrawScreen = props => {
  const { title, data, toDataContent } = usePrizeDraw();
  const { t } = useTranslation();
  const renderContentDetail = item => {
    const rs = toDataContent(item);
    return (
      <View
        style={[
          {
            flexDirection: 'column',
          },
        ]}>
        {rs.map((x, index) => {
          return (
            <>
              <AppText style={[styles.textTitle, index == 0 ? {} : { marginTop: theme.spacing.md }]}>{x.key}</AppText>
              {x.value?.map(txt => {
                return <AppText>{txt}</AppText>;
              })}
            </>
          );
        })}
      </View>
    );
  };
  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={styles.areaStyle}>
        <GenericHeader
          title={t('PRIZE_DRAW_TITLE')}
          style={[{ backgroundColor: '#EDEDED' }]}
          onBackClicked={() => {
            goBack();
          }}
        />
        <View style={[styles.container]}>
          <View style={[globalStyle.directionColumm, { margin: theme.spacing.md }]}>
            {data.map((item, index) => {
              // console.log('item>>>', item);
              return <CollapseView title={item.name}>{renderContentDetail(item?.items)}</CollapseView>;
            })}
          </View>
          {/* TODO add content view here */}
        </View>
      </AppSafeAreaView>
    </>
  );
};
