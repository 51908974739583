import { AppKAView, AppSafeAreaView, AppText } from '@components';
import GenericHeader from '@components/GenericHeader';
import { TIME_TABLE } from '@constant';
import { navigate } from '@routes/navigationUtils';
import { DASHBOARD_SCREEN } from '@routes/route';
import { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { Font, FontSize, Spacing } from '@theme/size';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {FlatList, RefreshControl, View, Platform, useWindowDimensions, TouchableOpacity} from 'react-native';
import { BundleTimelineStyle } from './BundleTimeline.style';
import {max} from "lodash";
import {useBundleTimeline} from "@screens/main/Timeline/bundle/BundleTimeline.hook";
import _ from 'lodash'

const TimelineItem = ({ item, textWidth, setTextWidth, t, isLast, type, onItemIdPress, isFirst }) => {
    const scaleFont = useWindowDimensions().fontScale;

    return (
      <View style={[
          {
              paddingHorizontal: Spacing.width20,
          },
          // type === TIME_TABLE.BUNDLE
          // ? { marginTop: item?.status === 'today' ? 16 : 16, marginBottom: item?.status === 'today' ? 5 : 0 }
          // : { marginTop: item?.status === 'today' ? 28 : 25, marginBottom: item?.status === 'today' ? 5 : 0 },
      ]}>
          {!_.isEmpty(item?.title) && <AppText
              style={{
                  ...Font.Bold_700,
                  textAlign: 'left',
                  color: item?.status === 'today' ? item?.color : palette.MAIN_BLACK,
                  // marginBottom: 10,
                  position: !item?.isFirst ? 'absolute' : undefined,
                  transform: [{ translateY: !item?.isFirst ? -28 * scaleFont : 0 }],
                  paddingHorizontal: !item?.isFirst ? Spacing.width20 : undefined,
              }}>
              {item?.title ? `${t(item?.title)}:` : ''}
          </AppText>}
          <TouchableOpacity
              onPress={onItemIdPress}
              style={[
                  globalStyle.directionRow,
                  BundleTimelineStyle.viewContainer,
                  {
                      // paddingLeft: 60,
                      // marginLeft: 10,
                      backgroundColor: '#FAFAFA',
                      // paddingTop:12,
                      marginTop:12,
                      borderRadius:4,
                      // zIndex:2,

                  }
              ]}>
              {isFirst && (<View style={{
                  position: 'absolute',
                  width: 2,
                  // height: '100%',
                  alignContent: 'center',
                  top:10*scaleFont + 24,
                  bottom:0,
                  left: Device.width/2 - Spacing.width20 - 1,
                  backgroundColor: '#C6C6C6',
              }}>

              </View>)}
              {!isFirst && (<View style={{
                  position: 'absolute',
                  width: 2,
                  backgroundColor: '#C6C6C6',
                  // height: '100%',
                  top:-12,
                  alignContent: 'center',
                  bottom:0,
                  left: Device.width/2 - Spacing.width20 - 1,
              }}>
              </View>)}
              <View style={{ flexDirection: 'row', flex: 1,
                  // backgroundColor: 'green'
                  marginTop:12,
              }}>
                  <AppText
                      // allowFontScaling={false}
                      numberOfLines={2}
                      style={[
                          { flex: 1, textAlign: 'right' },
                          item?.status === 'today' ? { ...Font.Bold_700, color: item?.color } : {},
                      ]}>
                      {item?.sendDateTarget}
                  </AppText>
                  <View
                      style={{
                          alignItems: 'center',
                          // position: 'relative',
                          flexDirection: 'column',
                          paddingHorizontal: 12,
                          width: 40,
                          marginTop: 5,
                          // backgroundColor: 'blue'
                      }}>
                      <View
                          style={
                              item?.status === 'today'
                                  ? { width: 10*scaleFont, height: 10*scaleFont, borderRadius: 1000, backgroundColor: item?.color }
                                  : { width: 10*scaleFont, height: 10*scaleFont, borderRadius: 1000, backgroundColor: item?.color }
                          }
                      />
                      <View
                          style={{
                              height: 60 * scaleFont,
                              width: scaleFont*1.5,
                              // backgroundColor: '#C6C6C6',
                              marginTop: 10,
                              marginBottom: 5,
                              // transform: [{ translateY: item?.status === 'today' ? 11 : 5 }],
                              // transform: [{ translateY: item?.isLast ? 35 : 0 }],

                          }}
                      />
                  </View>
                  <View style={[{ flex: 1 }]}>
                      <AppText
                          // allowFontScaling={false}
                          // onPress={onItemIdPress}
                          numberOfLines={1}
                          style={[{ color: '#33333' }, item?.status === 'today' ? { ...Font.Bold_700, color: item?.color } : {}]}>
                          {item?.bundleId}
                      </AppText>
                      <AppText
                          // allowFontScaling={false}
                          numberOfLines={1}
                          style={[{ color: '#888888', fontSize: FontSize.Font14 }]}>
                          {item?.numberOfTestItems} {item?.numberOfTestItems > 1 ? t('TEST_ITEMS') : t('TEST_ITEM')}
                      </AppText>
                      <AppText
                          // allowFontScaling={false}
                          numberOfLines={1}
                          style={[{ color: '#888888', fontSize: FontSize.Font14 }]}>
                          {!!item?.testFormat ? item?.testFormat : ' '}
                      </AppText>
                  </View>
              </View>
          </TouchableOpacity>
          {/*{!isFirst && (<View style={{*/}
          {/*    position: 'absolute',*/}
          {/*    width: 2,*/}
          {/*    backgroundColor: '#C6C6C6',*/}
          {/*    height: '100%',*/}
          {/*    alignContent: 'center',*/}
          {/*    zIndex:1,*/}
          {/*    left: Device.width/2 - 1,*/}
          {/*}}>*/}
          {/*</View>)}*/}
      </View>

  );
};

export const BundleTimelineScreen = props => {
  const { t } = useTranslation();
  const { data, handleRefresh, refresh, type, onItemIdPress } = useBundleTimeline(props);
  console.log('TimelineScreen>>>', data)
  const [textWidth, setTextWidth] = useState(0);
  const renderItem = ({ item, index, ...rest }) => {
    return (
      <TimelineItem
        item={item}
        textWidth={textWidth}
        setTextWidth={setTextWidth}
        t={t}
        isLast={index === data.length - 1}
        isFirst={index === 0}
        type={type}
        onItemIdPress={() => {
          onItemIdPress(item);
        }}
      />
    );
  };
  return (
    <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
      <AppKAView>
        <GenericHeader
          onBackClicked={() => navigate(DASHBOARD_SCREEN)}
          title={t('BUNDLE_TIMELINE')}
          style={[{ backgroundColor: palette.BACKGROUND_GREY }]}
        />
        <View style={[BundleTimelineStyle.containerView]}>
          {/*<AppText>{t('LIST_QUIZ_SUBTITLE')}</AppText>*/}
          <FlatList
            data={data}
            keyExtractor={item => {
              return item?.bundleId;
            }}
            renderItem={renderItem}
            refreshControl={
              <RefreshControl
                refreshing={refresh}
                onRefresh={handleRefresh}
                tintColor={palette.LOADING_COLOR}
                colors={[palette.LOADING_COLOR]}
                titleColor={palette.LOADING_COLOR}
              />
            }
          />
        </View>
      </AppKAView>
    </AppSafeAreaView>
  );
};
