import theme from '@theme';
import { Device } from '@theme/device';
import { Spacing } from '@theme/size';
import { absoluteFill, Platform, StyleSheet } from 'react-native';
import {palette} from "@theme/colors";

export const styles = StyleSheet.create({
  areaStyle: { backgroundColor: '#EDEDED' },
  container: {
    // width: '100%',
    // borderRadius: 3,
    // // alignItems: Platform.OS === 'web' ? '' : 'center',
    // backgroundColor: '#E3E3E3',
    // marginBottom: 10,
    borderTopRightRadius: theme.spacing.lg,
    borderTopLeftRadius: theme.spacing.lg,
    backgroundColor: palette.WHITE,
    flex: 1,
  },
  textTitle: {
    fontWeight: '700',
    color: '#333333',
    marginTop: theme.spacing.sm,
    marginBottom: 4,
  },
});
