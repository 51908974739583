import { AppAlert, PermissionModal, ReportModal } from '@components';
import { AppFlashMessage } from '@components/AppFlashMessage';
import { ForceUpdateView } from '@components/AppForceUpdate/ForceUpdate.view';
import {LoadingModal, LoadingView} from '@components/Loading/Loading.view';
import { DeeplinkModal } from '@components/modal/DeeplinkModal';
import { PERMISSION_MODEL } from '@constant';
import { Config } from '@constant/config';
import { RootStackScreen } from '@routes/index';
import { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import { Layout } from '@ui-kitten/components';
import PropTypes from 'prop-types';
import React from 'react';
import {StatusBar, View} from 'react-native';
import FlashMessage from 'react-native-flash-message';
import {GlobalModal} from "@components/GlobalModal";
import {GlobalSuccessModal} from "@components/GlobalSuccessModal";
import {NotificationImportantModal} from "@components/NotificationImportantModal/NotificationImportantModal.view";
import {Device} from "@theme/device";

import {NewsModal} from "@components/modal/NewsModal";
import {ListNotificationModal} from "@components/modal/ListNotificationModal";
import {ListNotiActionModal} from "@components/modal/ListNotiActionModal";

export const Container = props => {
  const {
    isShowVersion,
    isLoaded,
    isLoading,
    isRegisterForm,
    showModalDeeplinkVisible,
    cameraPermissionModalVisible,
    locationPermissionModalVisible,
    notificationPermissionModalVisible,
    notificationImportantModalVisible,
    showNewsModal,
    showListNotificationModal,
    showListNotiActionModal,
  } = props;
  // useWebDeeplink(props);
  const getTypeModal = () => {
    if(cameraPermissionModalVisible){
      return PERMISSION_MODEL.CAMERA
    }
    if(locationPermissionModalVisible){
      return PERMISSION_MODEL.LOCATION
    }

    if(notificationPermissionModalVisible){
      return PERMISSION_MODEL.NOTIFICATION
    }
    return PERMISSION_MODEL.CAMERA
  }
  return (
    <>
      <StatusBar barStyle="dark-content" backgroundColor={palette.WHITE} />
      {Device.deviceType === 'web' && <View style={[globalStyle.webBg]} />}
      <Layout style={[globalStyle.flex1, globalStyle.justifyCenter, globalStyle.width]}>
        {isLoaded && (
            <RootStackScreen
                // key={`key_${isLoaded}`}
                isLoaded={isLoaded}
                isRegisterForm={isRegisterForm}
            />
        )}
        {Config.IsDebug || (isShowVersion && <ForceUpdateView key={`key_${isLoaded}`} />)}
        {isLoading && <LoadingModal />}
        <AppAlert />
        <FlashMessage position="top" />
        <AppFlashMessage />
        <ReportModal />
        <PermissionModal
            show={cameraPermissionModalVisible || locationPermissionModalVisible || notificationPermissionModalVisible}
            type={getTypeModal()}
        />
        {showModalDeeplinkVisible && <DeeplinkModal show={showModalDeeplinkVisible} />}
        <NotificationImportantModal show={notificationImportantModalVisible} />
        <GlobalModal />
        <GlobalSuccessModal />
        {showNewsModal && <NewsModal show={showNewsModal} />}
        {showListNotificationModal && <ListNotificationModal show={showListNotificationModal} />}
        {showListNotiActionModal && <ListNotiActionModal show={showListNotiActionModal} />}
      </Layout>
    </>
  );
};

Container.propTypes = {
  isShowVersion: PropTypes.bool,
  isLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRegisterForm: PropTypes.bool,
  showModalDeeplinkVisible: PropTypes.bool,
  cameraPermissionModalVisible: PropTypes.bool,
  locationPermissionModalVisible: PropTypes.bool,
  notificationPermissionModalVisible: PropTypes.bool,
  notificationImportantModalVisible: PropTypes.bool,
};

Container.defaultProps = {};
