import { Constant } from '@constant';
import { LoginMockData } from '@constant/model/mock/loginMockData';
import { CommonActions } from '@react-navigation/native';
import { updateFirstTimeLogin } from '@redux/app/appSlice';
import { getFormSaga } from '@redux/form/getFormSaga';
import { getFormRequest } from '@redux/form/getFormSlice';
import { getUserSaga } from '@redux/user/getUserSaga';
import { getUserRequest } from '@redux/user/getUserSlice';
import { logoutFailed, logoutSuccess } from '@redux/user/logoutSlice';
import { navDispatch, navigatePush } from '@routes/navigationUtils';
import { LOGIN_SCREEN, Screens } from '@routes/route';
import { signUpWithNameAndCode, activateUser, login } from '@services/auth';
import { getForgotFormService } from '@services/form';
import {loginService, logoutService} from '@services/user';
import isEmpty from 'lodash/isEmpty';
import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects';
import {
  getUserValidationRequest,
  resetUserValidation,
  getUserValidationSuccess,
  getActiveUserSuccess,
  getActiveUserRequest,
  getUserValidationFailed,
  getActiveUserFailed,
  getLoginSuccess,
  getLoginRequest,
  getLoginFailed,
  getLogoutSuccess,
  getLogoutRequest,
  getLogoutFailed,
  getForgotFormRequest,
  getForgotFormSuccess,
  getForgotFormFailed, loginRequest,
} from './authSlice';
import {loginSaga} from "@redux/user/loginSaga";

function* getUserValidationSaga({ payload }) {
  const { firstName, lastName, iCode } = payload;
  try {
    const res = yield call(signUpWithNameAndCode, {
      firstName: firstName,
      lastName: lastName,
      iCode: iCode,
    });
    if (res?.status) {
      //TODO: load user + form
      const panelistID = res?.data?.panelistID;
      const rs = yield all([
        call(getUserSaga, getUserRequest({ id: panelistID })),
        call(getFormSaga, getFormRequest({ panelistId: panelistID })),
      ]);
      console.log('rs>>>', rs);
      for (const r of rs) {
        if (!r?.status) {
          return;
        }
      }
      yield put(getUserValidationSuccess(res.data));
      //TODO navigate to screen
      navDispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            {
              name: Screens.REGISTER_FORM_SCREEN,
            },
          ],
        }),
      );
    } else yield put(getUserValidationFailed(res?.message ?? Constant.ERROR_NO_RESPONSE));
  } catch (err) {
    console.error(err);
    yield put(getUserValidationFailed(err));
  }
}
function* resetUserValidationSaga({ payload }) {
  // yield put(resetUserValidation());
}

function* getActiveUserSaga({ payload, meta, error }) {
  try {
    const res = yield call(activateUser, payload);
    console.log({ res });
    // navigatePush(Screens.ACTIVE_SUCCESSFUL_SCREEN)
    if (!res.status) {
      throw res;
    }
    if (!isEmpty(res)) {
      // alert(res.message.description);
      if (meta) {
        yield call(meta, res);
      }
      yield put(getActiveUserSuccess(res.message.description));
    } else {
      if (error) {
        // yield call(error, Constant.ERROR_NO_RESPONSE);
      }
      yield put(getActiveUserFailed(Constant.ERROR_NO_RESPONSE));
    }
  } catch (err) {
    console.error(err);
    if (error) {
      // yield call(error, err);
    }
    yield put(getActiveUserFailed(err));
  }
}

function* getLoginSaga({ payload, meta, error }) {
  // const { username, password, rememberMe, deviceToken } = payload;
  try {
    // const formData = new FormData();
    // formData.append('email', username);
    // formData.append('password', password);
    // formData.append('device_token', deviceToken);
    const res = yield call(loginService, payload);
    // console.log({ rememberMe });
    if (!isEmpty(res)) {
      console.log({ res });
      yield put(
        getLoginSuccess({
          ...res.data,
          accessToken: res.data.access_token,
          email: res.data.email,
          rememberMe: payload?.rememberMe,
          isOTP: payload?.isOTP,
        }),
      );
      yield put(
        updateFirstTimeLogin({
          isFirsTimeLogin: res.data?.isFirstLogin,
          // isFirsTimeLogin: true,
        }),
      );
      if (meta) {
        yield call(meta, res);
      }
    } else {
      yield put(getLoginFailed(res));
      if (error) {
        yield call(error, res);
      }
    }
    if (res.status === false) {
      if (error) {
        yield call(error, res);
      }
      throw res.message;
    }

    // if (username === LoginMockData.username && password === LoginMockData.password)
    //   yield put(
    //     getLoginSuccess({
    //       ...payload,
    //       accessToken: 123,
    //     }),
    //   );
    // else yield put(getLoginFailed('Email/Password is incorrect'));
  } catch (err) {
    console.error(err);
    yield put(getLoginFailed(err));
    if (error) {
      yield call(error, err);
    }
  }
}

function* getForgotFormSaga({ payload, meta, error }) {
  try {
    const res = yield call(getForgotFormService, payload);
    if (!isEmpty(res)) {
      yield put(getForgotFormSuccess(res.form_data));
      if (meta) {
        yield call(meta, res);
      }
    } else {
      // @ts-ignore
      yield put(getForgotFormFailed(Constant.ERROR_NO_RESPONSE));
      if (error) {
        yield call(error, Constant.ERROR_NO_RESPONSE);
      }
    }
  } catch (err) {
    // @ts-ignore
    yield put(getForgotFormFailed(err));
    if (error) {
      yield call(error, err);
    }
  }
}

function* getLogoutSaga({ payload, meta, error }) {
  // try {
  //   yield put(getLogoutSuccess());
  // } catch (err) {
  //   console.error(err);
  //   yield put(getLogoutFailed(err));
  // }
  try {
    const res = yield call(logoutService, payload);
    console.log('logoutSaga>>>>', res);
    if (!isEmpty(res)) {
      yield put(getLogoutSuccess(res));
      if (meta) {
        yield call(meta, res);
      }
    } else {
      // @ts-ignore
      yield put(getLogoutFailed(Constant.ERROR_NO_RESPONSE));
      if (error) {
        yield call(error, Constant.ERROR_NO_RESPONSE);
      }
    }
  } catch (err) {
    // @ts-ignore
    yield put(getLogoutFailed(err));
    if (error) {
      yield call(error, err);
    }
  }
}

function* authSaga() {
  yield takeLatest(getUserValidationRequest.type, getUserValidationSaga);
  yield takeLatest(resetUserValidation.type, resetUserValidationSaga);
  yield takeLatest(getLoginRequest.type, getLoginSaga);
  yield takeLatest(getLogoutRequest.type, getLogoutSaga);
  yield takeEvery(getActiveUserRequest.type, getActiveUserSaga);
  yield takeEvery(getForgotFormRequest.type, getForgotFormSaga);
  yield takeEvery(loginRequest.type, getLoginSaga);
}

export default authSaga;
