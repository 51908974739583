import { showNotificationPermissionModal } from '@redux/common/commonSlice';
import { getProfileRequest } from '@redux/user/getProfileSlice';
import { updateProfileRequest } from '@redux/user/updateProfileSlice';
import { showErrorMsg } from '@utils/api';
import { isAllowSyncAppVersion } from '@utils/common/deviceUtil';
import { NotificationPermissionHelper } from '@utils/common/permission/notification';
import { handleShowError } from '@utils/transform/form';
import { t } from 'i18next';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import {isOTPUser} from "@utils";

const allowUpdateProfile = async (profileData, token) => {
  if (profileData?.device_token !== token || profileData?.deviceType !== Platform.OS) {
    return true;
  }
  const push_notification = profileData?.push_notification;
  const allowNotification = await NotificationPermissionHelper.hasPermission();
  if (push_notification && !allowNotification) {
    return true;
  }

  return false;
};

export const updateDeviceToken = (dispatch, token) => {
  dispatch(
    getProfileRequest(
      {},
      async res => {
        console.log('GET device token', token);
        const profileData = res?.message?.data;
        const push_notification = profileData?.push_notification;
        const allowNotification = await NotificationPermissionHelper.hasPermission();
        // FDB-28768
        const disableSystemNotification = push_notification && !allowNotification;
        const allowSyncAppVersion = isAllowSyncAppVersion(profileData);
        const isOTP = isOTPUser();
        if (
          profileData?.device_token !== token ||
          profileData?.deviceType !== Platform.OS ||
          disableSystemNotification ||
          allowSyncAppVersion
        ) {
          const payload = {
            device_token: token,
            deviceType: Platform.OS,
            push_notification: disableSystemNotification ? false : push_notification,
          };
          if (['android', 'ios'].includes(Platform.OS) && !isOTP) {
            payload.app_version = DeviceInfo.getVersion();
          }
          dispatch(
            updateProfileRequest(
              payload,
              rs => {
                //NOTE: show
                if (!allowNotification) {
                  dispatch(showNotificationPermissionModal({ description: t('NOTIFICATION_DEVICE_OFF') }));
                }
              },
              err => {
                handleShowError(err);
              },
            ),
          );
        }
      },
      err => {
        handleShowError(err);
      },
    ),
  );
};
