import { ActiveCheckIcon, CheckIcon, LogoIcon, MessageCheckIcon } from '@assets';
import { AppKAView, AppRadio, AppSafeAreaView, AppText } from '@components';
import { navigate } from '@routes/navigationUtils';
import { MENU_WEB_VIEW_SCREEN } from '@routes/route';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import { getContentTitle } from '@utils/transform/form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, TouchableOpacity } from 'react-native';
import RenderHtml from 'react-native-render-html';
import { URL } from 'react-native-url-polyfill';
import { useAppHtmlRadio } from './AppHtmlRadio.hook';
import { AppHtmlRadioStyle as styles } from './AppHtmlRadio.style';

export const AppHtmlRadioView = React.memo(props => {
  const { style, data, selected = false, onSelect, isRequired, title, error, styleTitle, hint = '' } = props;

  const dataRef = useRef(selected);

  return (
    <View style={[styles.container, style]}>
        {!!title && <AppText style={[styles.textTitle, styleTitle]}>
        {isRequired && <AppText style={styles.isRequired}>* </AppText>}
        {title}
      </AppText>}
      <TouchableOpacity
        onPress={() => {
          dataRef.current = !dataRef.current;
          onSelect(dataRef.current);
        }}
        style={styles.radioBox}
        activeOpacity={0.7}>
        {selected ? <ActiveCheckIcon /> : <CheckIcon />}
        {/*<AppText style={styles.text}>{item.content}</AppText>*/}
        <View style={[{ marginLeft: 10, marginRight: 4 }]}>
          <RenderHtml
            defaultTextProps={{ allowFontScaling: true }}
            source={{ html: data.content }}
            baseStyle={styles.text}
            renderersProps={{
              a: {
                onPress: (evt, href, htmlAttribs, target) => {
                  // Linking.openURL(href)
                  const url = new URL(href);
                  console.log('href>>>111', href, evt, htmlAttribs, target, url, url.hostname, data);
                  const extraInfo = data.extraInfo;
                  if (_.isEmpty(extraInfo)) {
                    navigate(MENU_WEB_VIEW_SCREEN, {
                      uri: url.href,
                      header: url.hostname,
                    });
                    return;
                  }
                  const x = extraInfo.find(x => x.internalId === url.hostname);
                  if (_.isEmpty(x)) {
                    return;
                  }
                  navigate(MENU_WEB_VIEW_SCREEN, {
                    uri: getContentTitle(x.content),
                    header: getContentTitle(x.title),
                  });
                },
              },
              img: {
                enableExperimentalPercentWidth: true,
              },
            }}
          />
        </View>
      </TouchableOpacity>
      {!!hint && <AppText style={styles.hint}>{hint}</AppText>}
      {!!error && <AppText style={styles.error}>{error}</AppText>}
    </View>
  );
});
