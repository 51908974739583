import {CommonActions, StackActions, useNavigation} from '@react-navigation/native';
import { getListenDeepLink } from '@redux/app/appSlice';
import {getActiveUserRequest, getLogoutRequest, getUserValidationRequest} from '@redux/auth/authSlice';
import { authUser } from '@redux/auth/selectors';
import { showGlobalSuccessModal, showModalDeeplink } from '@redux/common/commonSlice';
import { hideLoading, showLoading } from '@redux/global/globalSlice';
import { store } from '@redux/store';
import { changeEmailRequest } from '@redux/user/changeEmailSlice';
import {isFirstTimeApp, navigate, popToTop} from '@routes/navigationUtils';
import {
  Screens,
  FORGOT_CHANGE_PASS,
  FORGOT_SEND_EMAIL,
  LOGIN_SCREEN,
  SIGN_UP_WITH_NAME_SCREEN,
  DIGIT
} from '@routes/route';
import { validateResetToken } from '@services/auth';
import { getAccessToken } from '@utils';
import { ApiHelper } from '@utils/api';
import { DeeplinkHelper, DeeplinkType } from '@utils/common/deeplink';
import { NotificationHelper } from '@utils/common/notification';
import React, { createRef, useRef } from 'react';
import { Linking } from 'react-native';
import { URL } from 'react-native-url-polyfill';
import { useDispatch, useSelector } from 'react-redux';
import {LinkingHelper} from "@utils/common/linking";
import {Constant} from "@constant";

const urlTemp = createRef(null);
const hasInit = createRef(false);

export const useDeepLink = deps => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const accessToken = useSelector(authUser)?.accessToken;

  // const hasInit = useRef(false);
  // const urlTemp = useRef(null);

  React.useEffect(() => {
    console.log('useEffect>>>useDeepLink>>>', urlTemp.current, urlTemp.current?.href);
    // if (urlTemp.current) return;
    Linking.getInitialURL().then(url => {
      console.log('Linking.getInitialURL>>>>>>111>>>', hasInit.current, urlTemp.current, urlTemp.current?.href);
      if (!url) return;
      if (hasInit.current) {
        return;
      }
      hasInit.current = true;
      urlTemp.current = new URL(url);
      console.log('Linking.getInitialURL>>>222', url, hasInit.current, urlTemp.current?.href, isFirstTimeApp.current);
      const deeplinkType = DeeplinkHelper.getDeeplinkType(url);
      const duration = DeeplinkHelper.getDurationDelay(deeplinkType, isFirstTimeApp.current, true);
      // alert(`init>>>at:${!!accessToken} ___ hi:${hasInit.current} ___ft:${isFirstTimeApp.current}__du:${duration}`)
      setTimeout(() => {
        handleDeepLinkCases(deeplinkType);
        hasInit.current = false;
      }, duration);
    });

    const linkingSubscription = Linking.addEventListener('url', url => {
      console.log('Linking.addEventListener>>>111', url);
      if (!url) return;
      if (hasInit.current) return;
      urlTemp.current = new URL(url?.url);

      const deeplinkType = DeeplinkHelper.getDeeplinkType(url?.url);
      const isFirstTime = isFirstTimeApp.current;
      const duration = DeeplinkHelper.getDurationDelay(deeplinkType, isFirstTime, false);
      console.log(
        'Linking.addEventListener>>>222',
        url,
        hasInit.current,
        isFirstTimeApp.current,
        deeplinkType,
        duration,
      );
      // alert(`listen>>>at:${isFirstTime} ___ hi:${hasInit.current} ___du:${duration}`)
      setTimeout(() => {
        handleDeepLinkCases(deeplinkType);
      }, duration);
    });
    return () => {
      // Clean up the event listeners
      // unsubscribeFirebase();
      console.log('linkingSubscription>>remove');
      linkingSubscription?.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRegister = async url => {
    if (getAccessToken()) {
      dispatch(showModalDeeplink());
      return;
    }
    try {
      // const firstname = url.searchParams.get('firstname');
      // const lastname = url.searchParams.get('lastname');
      // const icode = url.searchParams.get('icode');
      // dispatch(
      //   getUserValidationRequest({
      //     firstName: firstname,
      //     lastName: firstname,
      //     iCode: icode,
      //   }),
      // );
      navigate(SIGN_UP_WITH_NAME_SCREEN);
    }
    catch (e) {

    }
  };

  const handleLogin = async url => {
    if (getAccessToken()) {
      dispatch(showModalDeeplink());
      return;
    }
    try {
      console.log('handleLogin>>>', url.searchParams, url.search)
      const searchParams = LinkingHelper.getParamUrl(url.search)
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            {
              name: LOGIN_SCREEN,
              params: {
                ...searchParams
              },
            },
          ],
        }),
      );
    } catch (e) {}
  }

  const handleReportBundle = async url => {
    if (!getAccessToken()) {
      try {
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [
              {
                name: LOGIN_SCREEN,
              },
            ],
          }),
        );
      }
      catch (e) {
        return;
      }
    } else {
      try {
        console.log('handleReportBundle>>>', url.searchParams, url.search);
        const searchParams = LinkingHelper.getParamUrl(url.search);
        navigate(DIGIT, { cellCount: 6, type: Constant.menu.report_bundle_dispatch, itemId: searchParams?.bundleId });
      } catch (e) {}
    }

  }

  const handleDeepLinkCases = deepLinkType => {
    console.log('handleDeepLinkCases>>>>');
    dispatch(getListenDeepLink(false));
    switch (deepLinkType) {
      case DeeplinkType.active.key:
        handleActiveFromDeepLink(urlTemp.current);
        urlTemp.current = null;
        break;
      case DeeplinkType.resetPassword.key:
        handleForgotFromDeepLink(urlTemp.current);
        urlTemp.current = null;
        break;

      case DeeplinkType.notifications.key:
        handleNotificationFromDeepLink(urlTemp.current);
        urlTemp.current = null;
        break;

      case DeeplinkType.changeEmail.key:
        handleChangeEmail(urlTemp.current);
        urlTemp.current = null;
        break;
      case DeeplinkType.register.key:
        handleRegister(urlTemp.current)
        urlTemp.current = null;
        break;

      case DeeplinkType.login.key:
        handleLogin(urlTemp.current)
        urlTemp.current = null;
        break;

      case DeeplinkType.reportBundle.key:
        handleReportBundle(urlTemp.current)
        urlTemp.current = null;
        break;
      default:
        urlTemp.current = null;
        break;
    }
  };

  const getActiveUserApi = (token, panelistId) => {
    dispatch(
      getActiveUserRequest(
        {
          panelistId: panelistId,
          activationToken: token,
        },
        res => {
          if (res.status) {
            navigation.dispatch(
              CommonActions.reset({
                index: 0,
                routes: [
                  {
                    name: Screens.SUCCESSFUL_SCREEN,
                    params: {
                      data: res?.message,
                      header: {
                        title: '',
                        backClick: () => navigation.dispatch(StackActions.replace(LOGIN_SCREEN)),
                      },
                    },
                  },
                ],
              }),
            );
          } else throw res.message;
        },
        err => {
          console.log('getActiveUserApi>>>error>>>', err);
        },
      ),
    );
  };

  const handleActiveFromDeepLink = url => {
    if (getAccessToken()) {
      dispatch(showModalDeeplink());
      return;
    }
    const token = url.searchParams.get('token');
    const panelistId = url.searchParams.get('panelistid');
    // console.log(token, panelistId);
    getActiveUserApi(token, panelistId);
  };

  const handleNotificationFromDeepLink = url => {
    try {
      console.log('handleNotificationFromDeepLink>>>', url);
      const data = ApiHelper.paramsToObject(url?.searchParams);
      NotificationHelper.openNotification(data, 100);
    } catch (e) {
      console.log('ex>>>handleNotificationFromDeepLink>>>', e);
    }
  };

  const handleSuccessEmail = res => {
    store.dispatch(
      showGlobalSuccessModal({
        title: res.message?.description,
        description: res.message?.alert,
        icon: res.message?.icon,
        action: () => {
          //TODO gotoLogout.
          console.log('handleSuccessEmail>>>action>>>');
          store.dispatch(
            getLogoutRequest(
              {},
              res => {
                //TODO: remove token, user
                console.log('getLogoutRequest>>>xxx>>>');
                popToTop();
              },
              error => {
                popToTop();
              },
            ),
          );
        },
      }),
    );
  };

  const handleChangeEmail = url => {
    try {
      console.log('handleNotificationFromDeepLink>>>', url);
      const data = ApiHelper.paramsToObject(url?.searchParams);
      // NotificationHelper.openChangeEmail(data, 100);
      console.log('handleChangeEmail>>>', data);

      store.dispatch(
        changeEmailRequest(
          data,
          res => {
            //TODO: show error then go to logout
            handleSuccessEmail(res);
          },
          err => {
            // handleSuccessEmail({
            //   message:{
            //     alert: 'abc',
            //     description: 'zzzz'
            //   }
            // })
          },
        ),
      );
    } catch (e) {
      console.log('ex>>>handleNotificationFromDeepLink>>>', e);
    }
  };

  const handleForgotFromDeepLink = async url => {
    if (getAccessToken()) {
      dispatch(showModalDeeplink());
      return;
    }
    try {
      const token = url.searchParams.get('reset_token');
      const email = url.searchParams.get('email');
      dispatch(showLoading());
      const res = await validateResetToken(token);
      if (res.status) {
        console.log(890890);
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [
              {
                name: FORGOT_CHANGE_PASS,
                params: {
                  token,
                  email,
                },
              },
            ],
          }),
        );
      } else {
        throw res.message;
      }
    } catch (error) {
      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            {
              name: LOGIN_SCREEN,
            },
            {
              name: FORGOT_SEND_EMAIL,
              params: {
                error: error,
              },
            },
          ],
        }),
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  return [123];
};
