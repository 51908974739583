import { createSelector } from '@reduxjs/toolkit';

import {Constant, DashboardStatus} from "@constant";
import {RootState} from "@redux/reducers";

const allAppSelector = state => state.app;

export const menus = createSelector(allAppSelector, appState => appState.menus);
export const dashboard = createSelector(allAppSelector, appState => appState.dashboard);
export const dashboardLoadingSelector = createSelector(allAppSelector, appState => appState.dashboardLoading);
export const bonusInfo = createSelector(allAppSelector, appState => appState.bonusInfo);
export const bonusPointInfo = createSelector(allAppSelector, appState => appState.dashboard?.find(x => x.internalId === 'bonus_points')?.data);

export const surveys = createSelector(allAppSelector, appState => appState.surveys);
export const reportModal = createSelector(allAppSelector, appState => {
  console.log({ appState });
  return appState.reportModal;
});
export const isFirstTimeInApp = createSelector(allAppSelector, appState => appState.isFirstTimeInApp);
export const listenDeepLink = createSelector(allAppSelector, appState => appState.listenDeepLink);
export const isFirstTimeLogin = createSelector(allAppSelector, appState => appState.isFirsTimeLogin);
export const notificationPermission = createSelector(allAppSelector, appState => appState.notificationPermission);
export const notificationModal = createSelector(allAppSelector, appState => appState.notificationModal);
export const notificationModalContent = createSelector(allAppSelector, appState => appState.notificationModalContent);
export const deviceToken = createSelector(allAppSelector, appState => appState.deviceToken);
export const timelineSelector = createSelector(allAppSelector, appState => appState.timeline);
export const validateReportSelector = createSelector(allAppSelector, appState => appState.validateReport);
export const mapSettingSelector = createSelector(allAppSelector, appState => appState.postingLocation.mapSettings);
export const dispatchPointListSelector = createSelector(
  allAppSelector,
  appState => appState.postingLocation.dispatchPointList,
);
export const dispatchPointSelector = createSelector(allAppSelector, appState => appState.postingLocation.dispatchPoint);
export const importantNotificationSelector = createSelector(allAppSelector, appState => appState.importantNotification);
// export const checkStatusOfDashboardSelector = createSelector(allAppSelector, appState => appState.checkStatusOfDashBoard);
export const checkStatusOfDashboardSelector = createSelector(allAppSelector, appState => appState.dashboard?.find(x => x.internalId === DashboardStatus.check_status));

export const getPrizeDrawDetailSelector = createSelector(allAppSelector, appState => appState.getPrizeDrawDetail);

export const getPrizeDrawDetailCreator = createSelector(getPrizeDrawDetailSelector, state => state.payload);

export const readUnderScoreNotificationSelector = createSelector(
  allAppSelector,
  appState => appState.readUnderScoreNotification,
);
export const readUnderScoreNotificationCreator = createSelector(
  readUnderScoreNotificationSelector,
  state => state.payload,
);
