import _ from 'lodash';

export const ObjectUtil = {
  toList: object => {
    if (_.isArray(object)) {
      return object
    }
    if (_.isEmpty(object)) {
      return [];
    }
    const rs = [];
    for (const objectElement in object) {
      const item = { ...object[objectElement], key: objectElement };
      rs.push(item);
    }
    return rs;
  },
};
