import { addFavouriteLocationRequest } from '@redux/postingLocations/addFavouriteLocationSlice';
import { deleteFavouriteLocationSaga } from '@redux/postingLocations/deleteFavouriteLocationSaga';
import { deleteFavouriteLocationRequest } from '@redux/postingLocations/deleteFavouriteLocationSlice';
import { ForkEffect, takeLatest } from 'redux-saga/effects';
import { addFavouriteLocationSaga } from './addFavouriteLocationSaga';

function* postingLocationsSagas() {
  yield takeLatest(addFavouriteLocationRequest.type, addFavouriteLocationSaga);
  yield takeLatest(deleteFavouriteLocationRequest.type, deleteFavouriteLocationSaga);
}

export default postingLocationsSagas;
