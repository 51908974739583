import {Constant, REPORT_TYPE} from '@constant';
import { useRoute } from '@react-navigation/native';
import {
  getListDashboardItemRequest,
  getNotificationModal,
  getNotificationModalContent,
  submitRFIDNotification
} from '@redux/app/appSlice';
import { dashboard as dashboardSelector } from '@redux/app/selectors';
import { getFAQRequest } from '@redux/form/getFAQSlice';
import { getRFIDFormRequest } from '@redux/form/getRFIDFormSlice';
import { getReturnTransponderFormRequest } from '@redux/form/getReturnTransponderFormSlice';
import { getFAQCreator, getFAQSelector, getFAQError, getFAQErrorMsg, getFAQLoading } from '@redux/form/selectors';
import { store } from '@redux/store';
import { navigate } from '@routes/navigationUtils';
import { MENU_WEB_VIEW_SCREEN, Screens, WEB_VIEW_SCREEN_FULLSCREEN } from '@routes/route';
import { getPanelistId, mappingDashboard } from '@utils';
import { getContentTitle } from '@utils/transform/form';
import _ from 'lodash';
import {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {hideLoading, showLoading} from "@redux/global/globalSlice";
import {showListNotiActionModal, showListNotificationModal} from "@redux/common/commonSlice";
// import {BundleTomorrowNotificationTest} from "@utils/test/notification";

export const useLogic = props => {
  const paramsData = useRoute().params?.data;
  const dispatch = useDispatch();
  const dashboard = useSelector(dashboardSelector);
  const [refresh, setRefresh] = useState(false);

  console.log('useLogic>>>dashboard>>>', dashboard)
  console.log('useLogic>>>paramsData>>>', paramsData)

  const data = dashboard?.find(x => x.type === 'notification')?.data?.notifications ?? [];

  const onItemPress = (item, index) => {
    console.log('onItemPress>>>>item>>>', item)
    // getQuizApi(item?.id);
    // const uri = getContentTitle(item?.content);
    // const data = _.cloneDeep(BundleTomorrowNotificationTest)
    // data.s_id = Math.random() * 100
    // data.createdDate = new Date();
    // dispatch(getNotificationModalContent(data))
    // dispatch(getNotificationModal(true));
    // return

    if(item.popup){
      //TODO: show popup list here.
      console.log('popup>>>>', item)
      // dispatch(showListNotiActionModal())
      dispatch(
        showListNotiActionModal({
          data: item,
        }),
      );
      return
    }

    if (_.isEmpty(item.category)) {
      return;
    }

    if (item.category === 'under_peformance') {
      // navigate(Screens.NOTIFICATION_DETAIL)

      // const uri = getContentTitle(item?.content);
      // const html =
      //     '<html>\n' +
      //     '<body>\n' +
      //     '<p>We can see that your account is not up to date Please open this notification to see the outstanding actions we need you to process as soon as possible.</p><br> <p>You have 1items outstanding to be entered as received. If these have arrived but you have not entered them please do so as soon as possible.</p><br>   \n' +
      //     '<a href="https://mailagents.uk/wp-content/uploads/2023/02/PR_What_it_means_to_be_a_Mailagent_-_RMG_1080p_20230220.mp4" target="_blank">Link Video</a>\n' +
      //     '</body>\n' +
      //     '</html>';

      navigate(Screens.NOTIFICATION_DETAIL, { data: item, disableLoading: true });

      return
    }

    // dispatch(
    //   getNotificationModalContent({
    //     title: 'notification?.title' + Math.random() * 100,
    //     body: 'notification?.body',
    //     data: {
    //       root: {
    //         actions: [
    //           {
    //             id: 'fill_report',
    //             title: 'Fill report',
    //             data: {
    //               report_type: REPORT_TYPE.packages,
    //               url: '/spools/action_update?s_bundleid={s_bundleid}&s_senddate_status=1&s_senddate_answer={currentTime}',
    //             },
    //           },
    //           {
    //             id: 'not_send_today',
    //             title: 'I didn’t send today',
    //             data: {
    //               url: '/spools/action_update?s_bundleid={s_bundleid}&s_senddate_status=1&s_senddate_answer={currentTime}',
    //               message:
    //                 'You need to send your bundle [bundle id] on [new senddatetarget]", senddate_target should be rescheduled to the next week the same day.',
    //             },
    //           },
    //         ],
    //       },
    //     },
    //   }),
    // );
    // dispatch(getNotificationModal(true));

    // dispatch(
    //   submitRFIDNotification({
    //     ...item,
    //     index: index,
    //   }),
    // );
    //TODO: should check valid before navigate to RFID form
    console.log('onItemPress>>>paramsData>>>', paramsData)
    console.log('onItemPress>>>item>>>', item)

    let payload = {};
    if (!_.isArray(paramsData)) {
      payload = {
        ...paramsData,
        ...item,
        index: index,
        panelistId: getPanelistId(),
      };
    } else {
      payload = {
        ...item,
        index: index,
        panelistId: getPanelistId(),
      };
    }

    delete payload.message;
    delete payload.canDelete;
    console.log('payload>>>', payload)
    dispatch(showLoading())
    dispatch(
      getRFIDFormRequest(
        payload,
        res => {
          dispatch(hideLoading())
          navigate(Screens.RFID_FORM, {
            data: {
              ...item,
              index: index,
            },
          });
        },
        err => {
          dispatch(hideLoading())
        },
      ),
    );
    // navigate(Screens.RFID_FORM, {
    //   data: {
    //     ...item,
    //     index: index,
    //   },
    // });
  };

  const onRefresh = () => {
    setRefresh(false)
    dispatch(
      getListDashboardItemRequest({
        internalIds: [Constant.dashboard.notification],
      }),
    );
  };
  console.log('useLogic>>>', paramsData, data);

  useEffect(() => {}, []);
  return { data, onItemPress, onRefresh, refresh };
};
