import { Constant } from '@constant';
import { searchForAddressRequest } from '@redux/address/searchForAddressSlice';
import { searchForPostCodeRequest } from '@redux/address/searchForPostCodeSlice';
import {
  searchForPostCodeCreator,
  searchForPostCodeErrorMsg,
  searchForPostCodeLoading,
} from '@redux/address/selectors';
import { navigatePop } from '@routes/navigationUtils';
import { mappingAddress, mappingPostcode } from '@utils/transform/address';
import * as _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useAddressContainerModal = props => {
  const postcode = props?.postCode;
  const search = props?.searchText;
  const onSelected = props?.onSelect;
  const dispatch = props?.dispatch;
  const [loadingMore, setLoadingMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState(search);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [totalPage, setTotalPage] = useState(2);
  const [pageNumber, setPageNumber] = useState(Constant.START_PAGE);
  // const [loadedData, setLoadedData] = useState(false);
  // const {postcode:'', onSelected : null}: any = props?.route?.params;

  const [currentPayload, setCurrentPayload] = useState({
    postcode: postcode,
    PageNumber: Constant.START_PAGE,
    PageSize: Constant.SIZE_PAGE,
    // Status: 0,
    hideLoading: false,
  });
  const onTextChanged = text => {
    console.log('onTextChanged>>>', text);
    setSearchText(text);
    if (_.isEmpty(text)) {
      setLoading(false)
      setData([]);
      return;
    }
    debounceSearch(text);
  };
  const onSearch = text => {
    // getCustomerListApi(text)
    searchForAddressApi(
      {
        ...currentPayload,
        search: searchText,
      },
      true,
    );
  };

  const debounceSearch = useCallback(
    _.debounce(nextValue => {
      searchForAddressApi({ search: nextValue, hideLoading: true }, true);
    }, 700),
    [],
  );

  const searchForAddressApi = (params, isReset, isPullToRefresh = false, isLoadMore = false) => {
    /*
      payload_info
       */
    const request = {
      postcode: postcode,
      ...params,
      // SearchTerm: searchText,
      // hideLoading: isLoadMore,
      PageNumber: isReset ? Constant.START_PAGE : params.PageNumber,
    };
    if (isLoadMore) {
      setLoadingMore(true);
    }
    setLoading(true);

    dispatch(
      searchForAddressRequest(
        request,
        rs => {
          console.log('response>>>', rs, searchText, params);
          const data = mappingAddress(rs.data)
          setData(data);
          setLoading(false);
        },
        err => {
          setLoading(false);
        },
      ),
    );
  };

  const onRefresh = () => {
    setIsRefreshing(false);
    searchForAddressApi(
      {
        ...currentPayload,
        search: searchText,
      },
      true,
    );
  };

  const onItemPress = item => {
    if(props?.isFullScreen){
      navigatePop();
    }
    console.log('onItemPress1>>>', item);

    if (onSelected) {
      console.log('onItemPress>>>', item);
      const originItem = _.cloneDeep(item);
      delete originItem.title;
      onSelected(item?.title, originItem);
    }
  };

  const onItemFavouritePress = item => {

  }

  const handleLoadMore = () => {
    console.log('handleLoadMore>>>', totalPage, pageNumber, currentPayload);
    totalPage >= pageNumber + 1 &&
      searchForAddressApi(
        {
          ...currentPayload,
          PageNumber: pageNumber + 1,
          search: searchText,
          hideLoading: true,
        },
        false,
        false,
        true,
      );
  };

  useEffect(() => {
    // searchForPostCodeApi();
    onTextChanged(search);
  }, []);

  useEffect(() => {}, [dispatch]);
  return {
    onRefresh,
    onSearch,
    onTextChanged,
    handleLoadMore,
    data,
    loadingMore,
    loading,
    isRefreshing,
    searchText,
    onItemPress,
  };
};
