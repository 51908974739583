import {
  AppDateTimePicker,
  AppImagePicker,
  AppInput,
  AppInputPicker,
  AppMultipleSelection,
  AppPicker,
  AppRadio,
  AppSingleSelection,
  AppSwitch,
} from '@components';
import { AppPhoneInput } from '@components/AppPhoneInput/AppPhoneInput';
import { Constant } from '@constant';
import { FORM, FORM_ELEMENT_NAME, IMAGE_PICKER_TYPE } from '@constant/model/RegisterForm';
import { MisDeliveredView } from '@screens/main/CDS/form/components/MisDelivered.view';
import { AppAddress } from '@screens/main/SearchAddress/components/AppAddress.view';
import { AppPostcode } from '@screens/main/SearchPostcode/components/AppPostcode.view';
import { QuizDetailStyle } from '@screens/main/quiz/QuizDetail/QuizDetail.style';
import { AppNoteView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import { AppCalendarView } from '@screens/main/report/AddReport/components/AppCalendar.view';
import { BundleAddressView } from '@screens/main/report/bundle/form/components/BundleAddress.view';
import { BundlePostCodeView } from '@screens/main/report/bundle/form/components/BundlePostCode.view';
import { DispatchPointView } from '@screens/main/report/bundle/form/components/DispatchPoint.view';
import { EmptyingTimeView } from '@screens/main/report/bundle/form/components/EmptyingTime.view';
import { NearestAddressView } from '@screens/main/report/bundle/form/components/NearestAddress.view';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import { AppHtmlRadioView } from '@screens/register/RegisterForm/components/AppHtmlRadio.view';
import theme from '@theme';
import { DATE_FORMAT, formatDate } from '@utils/common/MomentUtils';
import { StringHelper } from '@utils/common/string';
import { ViewUtil } from '@utils/common/view';
import { checkRadioId, getErrorForm, getErrorFormWithoutTouch } from '@utils/form';
import { BundleTransform } from '@utils/transform/bundle';
import {
  checkEvalCondition,
  checkEvalExpression,
  EvalTransform,
  FormTransform,
  getContentTitle,
} from '@utils/transform/form';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, {useCallback} from 'react';
import { Keyboard, Platform } from 'react-native';
import {FileTransform} from "@utils/transform/file";
import {navigate} from "@routes/navigationUtils";
import {Screens} from "@routes/route";
import {QRTransform} from "@utils/transform/QR";
import {requestPermission} from "@utils/common/deviceUtil";
import {KeyboardHelper} from "@utils/common/keyboard";
import {CanEnterWithFloat, CanEnterWithInt} from "@constant/model/mock/form/canEnter";
import {AppScan} from "@components/AppScan";
import {ClearIcon} from "@assets";
import {VerifyMapFormItem} from "@components/VeryfyMapFormItem";
import {LocationType} from "@utils/hooks";

import {FormUtil} from "@utils/form/formUtil";

export const FormItemView = ({
  item,
  index,
  formik,
  isBoldTitle = false,
  style = [],
  showHint = true,
  showPlaceholder = false,
  PreIcon = null,
  checkCondition = false,
  formData = [],
  extraFormik = null,
  extraFormData = null,
  extraData = null,
  placeHolder = null,
  extraFormValues = {},
  onSelect = null,
  scrollViewRef = null,
  onCheckValid = null,
  folderUpload = null,
  onLayout = null,
  subFormRef = null,
  // maxDate = null,
  // minDate: = null,
}) => {
  const key = `${item.internalId}_${index}`;
  const values = {
    ..._.cloneDeep(formik.values),
    ...extraFormValues,
  };
  // console.log('FormItemView>>>', item?.type, extraFormValues, values);
  if (checkCondition) {
    // console.log('FormItemView>>>', item, values, item?.condition?.react);
    const isCondition = checkEvalCondition(item?.condition?.react, values);

    if (!isCondition) {
      //TODO: reset value of field hidden

      // if (!_.isEmpty(item?.condition?.react)) {
      //   // formik
      //   const defaultValue = FormTransform.getEmptyValue(item);
      //   const internalValue = formik.values[item.internalId];
      //   if (defaultValue !== internalValue) {
      //     formik?.setFieldValue(item.internalId, FormTransform.getEmptyValue(item));
      //   }
      // }
      return <></>;
    }
  }

  const getFormRequired = useCallback(() => {
      return FormTransform.getFormRequired(item, values)
  }, [item, values])

    const getFormTitle = useCallback(() => {
        return FormUtil.getTitleDynamic(item.title, values, formData)
    }, [item.title, values])

  // console.log('FormItemView>>>extraData>>>', extraData);

  switch (item.type) {
    case FORM.FORM_TYPE.hidden:
      return <></>;

    case FORM.FORM_TYPE.dropdown:
    case FORM.FORM_TYPE.singlechoice:
    case FORM.FORM_TYPE.stringlistsingle:
      // eslint-disable-next-line no-case-declarations
      const vl = formik.values[item.internalId];
      const isRequired = getFormRequired()
      const onSelectItem = (i) => {
          console.log('onSelect>>>', item.internalId, i, i?.internalId);
          // formik.values[item.internalId] = i;
          const newValues = {
              ...formik.values,
              ...extraFormValues,
          };
          newValues[item.internalId] = i;
          FormTransform.resetValueForHiddenField(formik, formData, checkCondition, newValues);
          formik.setFieldTouched(item.internalId, true);
          // formik.setFieldError(item.internalId, null)
          formik.setFieldValue(item.internalId, i);
      }
      const onApplyText = (text) => {
          console.log('onApplyText>>>', text)
          const value = KeyboardHelper.correctValueOfPicker(text, item.data)
          // const value = KeyboardHelper.correctValueOfPicker(text, CanEnterWithInt)
          // const value = KeyboardHelper.correctValueOfPicker(text, CanEnterWithFloat)

          const newValues = {
              ...formik.values,
              ...extraFormValues,
          };
          newValues[item.internalId] = value;
          FormTransform.resetValueForHiddenField(formik, formData, checkCondition, newValues);
          formik.setFieldTouched(item.internalId, true);
          formik.setFieldValue(item.internalId, value);
      }

      if (item.popup === false) {
        return (
          <AppSingleSelection
            key={key}
            style={[RegisterFormStyles.formItemView, ...style]}
            data={item.data}
            selected={vl || checkRadioId(vl) ? vl : ''}
            // title={item?.title}
            title={getFormTitle()}
            // isRequired={item.required}
            isRequired={isRequired}
            onSelect={onSelectItem}
            isBoldTitle
            error={getErrorForm(formik.errors, formik.touched, item.internalId)}
            hint={getContentTitle(item?.description)}
            onLayout={onLayout}
          />
        );
      }
      return (
        <AppPicker
          key={key}
          data={item.data}
          // data = {CanEnterWithInt}
          // data = {CanEnterWithFloat}
            // data={QRTransform.getTestQROptions(item.data)}
          style={[RegisterFormStyles.formItemView, ...style]}
          selected={formik.values[item.internalId]}
          // isRequired={item.required}
          isRequired={getFormRequired()}
          // title={item?.title}
          title={getFormTitle()}

          disabled={item?.editable === false}
          header={FormTransform.getPlaceHolder(getContentTitle(item?.placeHolder))}
          onSelect={onSelectItem}
          // scanId={item.scanId ?? 'scanId'}
          scanId={item.scanId}
          onQRPress ={async () => {
              await requestPermission(() => {
                  navigate(Screens.SCAN_CODE_SCREEN, {formData:{
                          ...formik.values,
                          ...extraFormValues,
                      },
                      info: {
                          // scanId: item.scanId ?? 'scanId',
                          ...item,
                          // data: QRTransform.getTestQROptions(item.data),
                      },
                      onSuccess: (result) => {
                          //TODO:
                          console.log('onSuccess>>>', result)
                          onSelectItem(result?.internalId)
                      }
                  })
              })

          }}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          isBoldTitle={isBoldTitle}
          hint={getContentTitle(item?.description)}
          onLayout={onLayout}
          canEnter={item?.canEnter}
          // canEnter={true}
          onApplyText={onApplyText}
        />
      );
      case FORM.FORM_TYPE.scan:
          return (
              <AppScan
                  key={key}
                  style={[RegisterFormStyles.formItemView, ...style]}
                  isRequired={item?.required}
                  multiline={[FORM.FORM_TYPE.textArea, FORM.FORM_TYPE.textarea].includes(item?.type)}
                  // label={showPlaceholder ? null : item?.title}
                  label={showPlaceholder ? null : getFormTitle()}

                  disabled={item?.inputManual === false}
                  onChangeText={text => {
                      // formik.setFieldValue(item.internalId, text);
                      FormTransform.setAppInputValue(formik, item, text);
                  }}
                  placeholder={
                      showPlaceholder
                          ? getContentTitle(item?.title) || getContentTitle(item?.name) || getContentTitle(item?.placeHolder)
                          : null
                  }
                  keyboardType={item?.keyboardType ? item?.keyboardType : ''}
                  value={formik.values[item.internalId] ?? ''}
                  handleBlur={() => {
                      formik.handleBlur(item.internalId);
                  }}
                  isPassword={!!item?.isSecured}
                  error={getErrorForm(formik.errors, formik.touched, item.internalId)}
                  isBoldTitle={isBoldTitle}
                  hint={showHint ? getContentTitle(item?.description) : null}
                  PostIcon={ClearIcon}
                  onPostIconPress = {() => {
                      FormTransform.setAppInputValue(formik, item, '');
                  }}
                  onLayout={onLayout}
                  scanId={ item?.internalId }
                  showInput ={item?.is_show_scan_code}
                  // showInput ={true}
                  onQRPress ={async () => {
                      await requestPermission(() => {
                          navigate(Screens.INPUT_SCAN_CODE_SCREEN, {formData:{
                                  ...formik.values,
                                  ...extraFormValues,
                              },
                              info: {
                                  // scanId: item.scanId ?? 'scanId',
                                  ...item,
                                  // data: QRTransform.getTestQROptions(item.data),
                              },
                              onSuccess: (result) => {
                                  //TODO:
                                  console.log('onSuccess>>>', result)
                                  FormTransform.setAppInputValue(formik, item, result);
                              }
                          })
                      })

                  }}
              />
          );
    case FORM.FORM_TYPE.dispatch_point:
      console.log('FORM.FORM_TYPE.dispatch_point>>>', extraData);
      // if (_.isEmpty(extraData.subForm)) {
      //   return <></>;
      // }
      return (
        <DispatchPointView
          key={key}
          data={item.data}
          style={[RegisterFormStyles.formItemView, ...style, { marginBottom: _.isNil(formik?.values[FORM_ELEMENT_NAME.posting_location_id]) ? theme.spacing.md : theme.spacing.sm }]}
          selected={formik.values[item.internalId]}
          // isRequired={item.required}
          isRequired={getFormRequired()}
          // title={item?.title}
          title={getFormTitle()}

          disabled={item?.editable === false}
          // header={item?.title}
          header={getFormTitle()}

          onSelect={i => {
            console.log('onSelect>>>DispatchPointView>>>', formik, item.internalId, i);
            // const isHide = CheckE
            //   Eval
              const values = {
                  ...formik.values,
              }
              values[item.internalId] = i
              const isHide = checkEvalCondition(item?.hideSubForm?.react, values)
            // if (i === FORM.FORM_ELEMENT_NAME.collection) {
              if(isHide){
              // FormTransform.setFormikElementValue(FORM.FORM_ELEMENT_NAME.nearest_address, formik, null);
              FormTransform.setFormikElementValue(FORM_ELEMENT_NAME.posting_location_id, formik, null);
            }
            formik.setFieldValue(item.internalId, i);
          }}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          isBoldTitle={isBoldTitle}
          hint={getContentTitle(item?.description)}
          formik={formik}
          formData={formData}
          location={extraData?.location}
          subForm={extraData?.subForm}
          verifyOnMap={extraData?.verifyOnMap}
          mapSettings={extraData?.mapSettings}
          locationType={extraData?.locationType}
          defaultValue={extraData?.defaultValue}
          summary={extraData?.summary}
          placeHolder={getContentTitle(item?.placeHolder)}
          onCheckValid={onCheckValid}
          formikRef={subFormRef}
          scrollViewRef={scrollViewRef}
        />
      );
    case FORM.FORM_TYPE.nearest_address:
      console.log('extraData>>>', extraData);
      return (
        <NearestAddressView
          key={key}
          data={item.data}
          style={[RegisterFormStyles.formItemView, ...style]}
          selected={formik.values[item.internalId]}
          // isRequired={item.required}
          isRequired={getFormRequired()}
          // title={item?.title}
          title={getFormTitle()}
          titlePopup={getContentTitle(item?.titlePopup)}
          descriptionPopup={getContentTitle(item?.descriptionPopup)}
          disabled={item?.editable === false}
          header={item?.title}
          onSelect={(i, extra) => {
            console.log('NearestAddressView>>>onSelect>>>', i, extra);
            formik.setFieldValue(item.internalId, i);

            const bundlePostCodeId = FormTransform.getInternalId(FORM.FORM_TYPE.bundle_postcode, formData);
            FormTransform.setFormikElementValue(bundlePostCodeId, formik, '');

            const bundleAddressId = FormTransform.getInternalId(FORM.FORM_TYPE.bundle_address, formData);
            FormTransform.setFormikElementValue(bundleAddressId, formik, '');

            const bundleEmptyingId = FormTransform.getInternalId(FORM.FORM_TYPE.emptying_time, formData);
            FormTransform.setFormikElementValue(
              bundleEmptyingId,
              formik,
              BundleTransform.mappingEmptying(extra?.collection_times),
            );

            FormTransform.setFormikElementValue('type_extra', formik, extra?.type_extra);
            FormTransform.setFormikElementValue(FORM.FORM_ELEMENT_NAME.address_selected_extra, formik, extra);

            FormTransform.setFormikElementValue(FORM_ELEMENT_NAME.posting_location_id, extraFormik, extra?.id);
          }}
          onClickHref={i => {
            formik.setFieldValue(item.internalId, '');
            extraFormik.setFieldValue(item.internalId, '');

            //NOTE: enable_nearest_address for visible/enable and validate: form nearest_address
            // enable_nearest_address = false -> condition = false -> hide form item
            formik.setFieldValue(`enable_${FORM_ELEMENT_NAME.nearest_address}`, false);

            formik.setFieldValue(FORM.EXTRA_FORM_ELEMENT_NAME.list_post_position, []);
            formik.setFieldValue(FORM.EXTRA_FORM_ELEMENT_NAME.reset_bundle_address, false);

            FormTransform.setFormikElementValue(FORM.FORM_ELEMENT_NAME.address_selected_extra, formik, null);


              const bundlePostCodeId = FormTransform.getInternalId(FORM.FORM_TYPE.bundle_postcode, formData);
            FormTransform.setFormikElementValue(bundlePostCodeId, formik, '');

            const bundleAddressId = FormTransform.getInternalId(FORM.FORM_TYPE.bundle_address, formData);
            FormTransform.setFormikElementValue(bundleAddressId, formik, '');

            const bundleEmptyingId = FormTransform.getInternalId(FORM.FORM_TYPE.emptying_time, formData);
            FormTransform.setFormikElementValue(bundleEmptyingId, formik, []);

            //TODO: should check reset value of parent form
            const newValues = {
              ...extraFormik.values,
              // ...extraFormValues,
            };
            newValues[FORM_ELEMENT_NAME.posting_location_id] = null;
            console.log('NearestAddressView>>>onClickHref>>>', newValues, extraFormData);
            FormTransform.resetValueForHiddenField(extraFormik, extraFormData, checkCondition, newValues);
            FormTransform.setFormikElementValue(FORM_ELEMENT_NAME.posting_location_id, extraFormik, null);
          }}
          onBackDrop={() => {
            // formik.setFieldValue(item.internalId, undefined);
              console.log("onBackDrop>>>", formik, extraFormik)
            formik.setFieldValue(item.internalId, null);
            // extraFormik.setFieldValue(item.internalId, null);
            //   formik.setFieldValue(`visible_${FORM_ELEMENT_NAME.nearest_address}`, true);

            formik.setFieldValue(FORM.EXTRA_FORM_ELEMENT_NAME.list_post_position, []);
            formik.setFieldValue(FORM.EXTRA_FORM_ELEMENT_NAME.reset_bundle_address, false);

            FormTransform.setFormikElementValue(FORM.FORM_ELEMENT_NAME.address_selected_extra, formik, null);

              // extraFormik.setFieldValue(item.internalId, undefined);
            const bundlePostCodeId = FormTransform.getInternalId(FORM.FORM_TYPE.bundle_postcode, formData);
            FormTransform.setFormikElementValue(bundlePostCodeId, formik, '');

            const bundleAddressId = FormTransform.getInternalId(FORM.FORM_TYPE.bundle_address, formData);
            FormTransform.setFormikElementValue(bundleAddressId, formik, '');

            const bundleEmptyingId = FormTransform.getInternalId(FORM.FORM_TYPE.emptying_time, formData);
            FormTransform.setFormikElementValue(bundleEmptyingId, formik, []);

            //TODO: should check reset value of parent form
            const newValues = {
              ...extraFormik.values,
              // ...extraFormValues,
            };
            newValues[FORM_ELEMENT_NAME.posting_location_id] = null;
            console.log('NearestAddressView>>>onBackDrop>>>', newValues, extraFormData);
            console.log('NearestAddressView>>>item>>>', item);

            FormTransform.resetValueForHiddenField(extraFormik, extraFormData, checkCondition, newValues);
            FormTransform.setFormikElementValue(FORM_ELEMENT_NAME.posting_location_id, extraFormik, null);
            // setTimeout(() => {
            //   let err = item?.errors?.validation;
            //   if (_.isEmpty(null) && item?.required) {
            //     err = item?.errors?.required;
            //   }
            //   extraFormik.setFieldError(item.internalId, err);
            // }, 100);
          }}
          onVisible={i => {}}
          visible={formik.values[`visible_${FORM_ELEMENT_NAME.nearest_address}`]}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          isBoldTitle={isBoldTitle}
          hint={getContentTitle(item?.description)}
          formik={formik}
          dispatchType={extraFormik?.values[FORM_ELEMENT_NAME.dispatch_point]}
          posting_datetime={extraFormik?.values[FORM.FORM_ELEMENT_NAME.posting_datetime]}
          location={extraData?.location}
          summary={extraData?.summary}
          onLayout={onLayout}
        />
      );
    case FORM.FORM_TYPE.stringlistmultiple:
    case FORM.FORM_TYPE.MultipleSelection:
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return (
        <AppMultipleSelection
          key={key}
          style={[RegisterFormStyles.formItemView, ...style]}
          data={item.data}
          selected={formik.values[item.internalId] ? formik.values[item.internalId] : []}
          // title={item?.title}
          title={getFormTitle()}

          disabled={item?.editable === false}
          onSelect={i => {
            console.log('AppMultipleSelection>>>onSelect>>>', item, i, item.internalId, i?.internalId);
            formik.setFieldTouched(item.internalId, true);

            formik.setFieldValue(item.internalId, i);
            // formik.setFieldError(item.internalId, null);
          }}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          isBoldTitle={isBoldTitle}
          hint={getContentTitle(item?.description)}
          // isRequired={item.required}
          isRequired={getFormRequired()}
          onLayout={onLayout}
        />
      );
    case FORM.FORM_TYPE.boolean:
    case FORM.FORM_TYPE.SingleSelection:
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      const idValue = formik.values[item.internalId];
      return (
        <AppSingleSelection
          key={key}
          style={[RegisterFormStyles.formItemView, ...style]}
          data={item.data}
          selected={idValue || checkRadioId(idValue) ? idValue : ''}
          // title={item?.title}
          title={getFormTitle()}

          disabled={item?.editable === false}
          onSelect={i => {
            console.log('AppSingleSelection>>>onSelect>>>', item, i, item.internalId, i?.internalId);

            // formik.values[item.internalId] = i;
            const newValues = {
              ...formik.values,
              ...extraFormValues,
            };
            newValues[item.internalId] = i;
            FormTransform.resetValueForHiddenField(formik, formData, checkCondition, newValues);
            formik.setFieldTouched(item.internalId, true);

            formik.setFieldValue(item.internalId, i);
            // formik.setFieldError(item.internalId, null);
          }}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          isBoldTitle={isBoldTitle}
          hint={getContentTitle(item?.description)}
          // isRequired={item.required}
          isRequired={getFormRequired()}
          onLayout={onLayout}
        />
      );

    case FORM.FORM_TYPE.postcode:
      return (
        <AppPostcode
          onSelect={text => {
            formik.setFieldValue(item.internalId, text);
            const lastValue = formik.values[item.internalId];
            if (lastValue !== text) {
              formik.setFieldValue(FORM_ELEMENT_NAME.address, '');
              formik.setFieldValue(FORM_ELEMENT_NAME.tmp_address_extra, null);
            }
          }}
          // title={item?.title}
          title={getFormTitle()}

          disabled={item?.editable === false}
          style={[RegisterFormStyles.formItemView, ...style]}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          // selected={formik.values[item.internalId] ? formik.values[item.internalId] : ''}
          value={formik.values[item.internalId]}
          isBoldTitle={isBoldTitle}
          // isRequired={item.required}
          isRequired={getFormRequired()}
          hint={getContentTitle(item?.description)}
          placeHolder={getContentTitle(item?.placeHolder)}
          onLayout={onLayout}
        />
      );
    case FORM.FORM_TYPE.address:
      return (
        <AppAddress
          onSelect={(text, extraValue) => {
            console.log('onSelect>>>AppAddress>>>', text, extraValue);
            formik.setFieldValue(item.internalId, text);
            formik.setFieldValue(FORM_ELEMENT_NAME.tmp_address_extra, extraValue);
          }}
          // title={item?.title}
          title={getFormTitle()}

          disabled={item?.editable === false}
          style={[RegisterFormStyles.formItemView, ...style]}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          postCode={formik.values.postcode}
          // selected={formik.values[item.internalId] ? formik.values[item.internalId] : ''}
          value={formik.values[item.internalId]}
          isBoldTitle={isBoldTitle}
          // isRequired={item.required}
          isRequired={getFormRequired()}
          placeHolder={getContentTitle(item?.placeHolder)}
          onLayout={onLayout}
        />
      );
    case FORM.FORM_TYPE.bundle_postcode:
      console.log(
        'bundle_postCode>>>',
        getErrorForm(formik.errors, formik.touched, item.internalId),
        formik,
        formik.error,
      );
      return (
        <BundlePostCodeView
          key={key}
          style={[RegisterFormStyles.formItemView, ...style]}
          isRequired={item?.required}
          multiline={item?.type === FORM.FORM_TYPE.textArea}
          // label={showPlaceholder ? null : item?.title}
          label={showPlaceholder ? null : getFormTitle()}

          disabled={item?.editable === false}
          onChangeText={text => {
            console.log('onChangeText>>>BundlePostCodeView>>>', extraFormik, text);
            // formik.setFieldTouched(item.internalId, true);
            // formik.setFieldValue(item.internalId, text);
            // formik.setFieldError(item.internalId, '');
            // formik.validateField(item.internalId)

            const bundleAddressId = FormTransform.getInternalId(FORM.FORM_TYPE.bundle_address, formData);
            FormTransform.setFormikElementValue(bundleAddressId, formik, '');
            const bundleEmptyingId = FormTransform.getInternalId(FORM.FORM_TYPE.emptying_time, formData);
            FormTransform.setFormikElementValue(bundleEmptyingId, formik, []);
            FormTransform.setFormikElementValue(FORM_ELEMENT_NAME.posting_location_id, extraFormik, null);
            FormTransform.setFormikElementValue(bundleAddressId, extraFormik, '');
            formik.setFieldValue(FORM.EXTRA_FORM_ELEMENT_NAME.reset_bundle_address, false);
            //NOTE: reset after change postcode
            formik.setFieldValue(FORM.EXTRA_FORM_ELEMENT_NAME.list_post_position, []);

            formik.setFieldTouched(item.internalId, true);
            formik.setFieldValue(item.internalId, text);
          }}
          onLoadedData={(rs, text, isSuccess) => {
            console.log('onLoadedData>>>', rs, text, isSuccess, item);

            if (!_.isEmpty(rs)) {
              console.log('Keyboard.dismiss>>>>', scrollViewRef);
              Keyboard.dismiss();
              //TODO: hide keyboard then scroll to bottom.
              // scrollViewRef?.current?.
              ViewUtil.scrollToEnd(scrollViewRef?.current, 200, true);
            }
            //TODO: should show error of postcode if empty

            formik.setFieldValue(FORM.EXTRA_FORM_ELEMENT_NAME.list_post_position, _.cloneDeep(rs));
            if (!isSuccess || _.isEmpty(rs)) {
              //TEMP_FIX: must settimeout error
              setTimeout(() => {
                  let err = item?.errors?.validation;
                  if(_.isEmpty(text) && item?.required){
                      err  = item?.errors?.required
                  }
                  formik.setFieldError(item.internalId, err);

              }, 100);
            }
          }}
          placeholder={
            showPlaceholder
              ? getContentTitle(item?.title) || getContentTitle(item?.name) || getContentTitle(item?.placeHolder)
              : null
          }
          keyboardType={item?.keyboardType ? item?.keyboardType : ''}
          value={formik.values[item.internalId] ?? ''}
          handleBlur={() => {
            formik.handleBlur(item.internalId);
          }}
          isPassword={!!item?.isSecured}
          // error={getErrorFormWithoutTouch(formik.errors, item.internalId)}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          isBoldTitle={isBoldTitle}
          hint={showHint ? getContentTitle(item?.description) : null}
          PreIcon={PreIcon}
          location={extraData?.location}
          metaItem={item}
          dispatchType={extraFormik?.values[FORM_ELEMENT_NAME.dispatch_point]}
          posting_datetime={extraFormik?.values[FORM.FORM_ELEMENT_NAME.posting_datetime]}
          summary={extraData?.summary}
          onLayout={onLayout}
        />
      );
    case FORM.FORM_TYPE.bundle_address:
      if (_.isEmpty(formik.values[FORM.EXTRA_FORM_ELEMENT_NAME.list_post_position])) {
        return <></>;
      }
      return (
        <BundleAddressView
          onSelect={(text, extraValue) => {
            console.log('BundleAddressView>>>onSelect>>>', text, extraValue, formData);
            const bundleEmptyingId = FormTransform.getInternalId(FORM.FORM_TYPE.emptying_time, formData);
            FormTransform.setFormikElementValue(
              bundleEmptyingId,
              formik,
              BundleTransform.mappingEmptying(extraValue?.collection_times),
            );
            FormTransform.setFormikElementValue('type_extra', formik, extraValue?.type_extra);
            FormTransform.setFormikElementValue(FORM.FORM_ELEMENT_NAME.address_selected_extra, formik, extraValue);


              //NOTE: don't need set correct postal_code after select address
            // const postal_code = extraValue?.postal_code
            // if(!_.isEmpty(postal_code)){
            //     const postalCodeId = FormTransform.getInternalId(FORM.FORM_TYPE.bundle_postcode, formData);
            //     FormTransform.setFormikElementValue(
            //         postalCodeId,
            //         formik,
            //         postal_code,
            //     );
            //     FormTransform.setFormikElementValue(postalCodeId, extraFormik, postal_code);
            //     console.log('postalCodeId>>>', postalCodeId, postal_code)
            // }

            FormTransform.setFormikElementValue(FORM_ELEMENT_NAME.posting_location_id, extraFormik, extraValue?.id);
            FormTransform.setFormikElementValue(item.internalId, extraFormik, text);
            // FormTransform.setFormikElementValue(FORM.FORM_TYPE.nearest_address, extraFormik, ' ');
            // FormTransform.setFormikElementValue(FORM.FORM_TYPE.nearest_address, formik, ' ');

            formik.setFieldValue(FORM.EXTRA_FORM_ELEMENT_NAME.reset_bundle_address, false);

            //TODO: should check reset value of parent form
            const newValues = {
              ...extraFormik.values,
              // ...extraFormValues,
            };
            // newValues[FORM_ELEMENT_NAME.posting_location_id] = null;

            FormTransform.resetValueForHiddenField(extraFormik, extraFormData, checkCondition, newValues);

            formik.setFieldValue(item.internalId, text);
          }}
          onClickHref={href => {
            formik.setFieldValue(item.internalId, '');
            formik.setFieldValue(FORM.EXTRA_FORM_ELEMENT_NAME.list_post_position, []);
            formik.setFieldValue(FORM.EXTRA_FORM_ELEMENT_NAME.reset_bundle_address, true);

            const bundleEmptyingId = FormTransform.getInternalId(FORM.FORM_TYPE.emptying_time, formData);
            FormTransform.setFormikElementValue(bundleEmptyingId, formik, []);

            FormTransform.setFormikElementValue(item.internalId, extraFormik, '');
            //FIXME: temp fix: can't raise '' for posting_location_id
            // FormTransform.setFormikElementValue(FORM_ELEMENT_NAME.posting_location_id, extraFormik, 'xxx');
            FormTransform.setFormikElementValue(FORM_ELEMENT_NAME.posting_location_id, extraFormik, '');

            //   setTimeout(() => {
            //   FormTransform.setFormikElementValue(FORM_ELEMENT_NAME.posting_location_id, extraFormik, '');
            // }, 100);
          }}
          // title={item?.title}
          title={getFormTitle()}

          disabled={item?.editable === false}
          style={[RegisterFormStyles.formItemView, ...style]}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          postCode={formik.values[FormTransform.getInternalId(FORM.FORM_TYPE.bundle_postcode, formData)]}
          dispatchType={extraFormik?.values[FORM_ELEMENT_NAME.dispatch_point]}
          // selected={formik.values[item.internalId] ? formik.values[item.internalId] : ''}
          value={formik.values[item.internalId]}
          isBoldTitle={isBoldTitle}
          // isRequired={item.required}
          isRequired={getFormRequired()}
          descriptionPopup={getContentTitle(item?.descriptionPopup)}
          location={extraData?.location}
          placeHolder={getContentTitle(item?.placeHolder)}
          data={formik.values[FORM.EXTRA_FORM_ELEMENT_NAME.list_post_position]}
          summary={extraData?.summary}
          onLayout={onLayout}
        />
      );
    case FORM.FORM_TYPE.emptying_time:
      return (
        <EmptyingTimeView
          // onSelect={(text, extraValue) => {
          //   console.log('onSelect>>>BundleAddressView>>>', text, extraValue);
          //   formik.setFieldValue(item.internalId, text);
          //   formik.setFieldValue(FORM_ELEMENT_NAME.tmp_address_extra, extraValue);
          // }}
          // title={item?.title}
          title={getFormTitle()}

            disabled={item?.editable === false}
          style={[RegisterFormStyles.formItemView, ...style]}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          value={formik.values[item.internalId]}
          type_extra={formik.values['type_extra']}
          isBoldTitle={isBoldTitle}
          // isRequired={item.required}
          isRequired={getFormRequired()}

          descriptionPopup={getContentTitle(item?.descriptionPopup)}
          // selectedDate={extraFormik.values[]}
          selectedDate={extraFormik.values[FORM.FORM_ELEMENT_NAME.posting_datetime]}
          onLayout={onLayout}
        />
      );
    case FORM.FORM_TYPE.input:
    // case FORM.FORM_TYPE.text:
    case FORM.FORM_TYPE.string:
    case FORM.FORM_TYPE.textArea:
    case FORM.FORM_TYPE.textarea:
    case FORM.FORM_TYPE.text:
      // case FORM.FORM_TYPE.bundle_postcode:
      if (item.internalId === FORM.FORM_ELEMENT_NAME.phonenumber) {
        return (
          <AppPhoneInput
            key={key}
            style={[RegisterFormStyles.formItemView, ...style]}
            isRequired={item?.required}
            // multiline={item?.type === FORM.FORM_TYPE.textArea}
            // label={showPlaceholder ? null : item?.title}
            label={showPlaceholder ? null : getFormTitle()}
            disabled={item?.editable === false}
            // onChangeText={text => {
            //   console.log('onChangeText>>>phonenumber>>', text);
            //   formik.setFieldValue(item.internalId, text);
            // }}
            phoneCode={Constant.PHONE_CODE}
            onChangeText={text => {
              console.log('onChangeText>>>phonenumber>>', text);
              formik.setFieldValue(item.internalId, text);
            }}
            placeholder={showPlaceholder ? item?.title : null}
            // placeHolder={showPlaceholder ? item?.title : getContentTitle(item?.placeHolder)}
            keyboardType={item?.keyboardType ? item?.keyboardType : ''}
            value={formik.values[item.internalId]?.replace(Constant.PHONE_CODE, '') ?? ''}
            handleBlur={() => {
              formik.handleBlur(item.internalId);
            }}
            isPassword={!!item?.isSecured}
            error={getErrorForm(formik.errors, formik.touched, item.internalId)}
            isBoldTitle={isBoldTitle}
            hint={showHint ? getContentTitle(item?.description) : null}
            onLayout={onLayout}
          />
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      console.log('renderString>>>', item);
      if (item.internalId === FORM.FORM_ELEMENT_NAME.email) {
        return (
          <AppInput
            key={key}
            style={[RegisterFormStyles.formItemView, ...style]}
            isRequired={item?.required}
            multiline={[FORM.FORM_TYPE.textArea, FORM.FORM_TYPE.textarea].includes(item?.type)}
            // label={showPlaceholder ? null : item?.title}
            label={showPlaceholder ? null : getFormTitle()}

            disabled={item?.editable === false}
            onChangeText={text => {
              // formik.setFieldValue(item.internalId, text);
              FormTransform.setAppInputValue(formik, item, text);
            }}
            placeholder={
              showPlaceholder
                ? getContentTitle(item?.title) || getContentTitle(item?.name) || getContentTitle(item?.placeHolder)
                : null
            }
            keyboardType={item?.keyboardType ? item?.keyboardType : 'email-address'}
            value={formik.values[item.internalId] ?? ''}
            handleBlur={() => {
              formik.handleBlur(item.internalId);
            }}
            isPassword={!!item?.isSecured}
            error={getErrorForm(formik.errors, formik.touched, item.internalId)}
            isBoldTitle={isBoldTitle}
            hint={showHint ? getContentTitle(item?.description) : null}
            PreIcon={PreIcon}
            onLayout={onLayout}
          />
        );
      }
      return (
        <AppInput
          key={key}
          style={[RegisterFormStyles.formItemView, ...style]}
          isRequired={item?.required}
          multiline={[FORM.FORM_TYPE.textArea, FORM.FORM_TYPE.textarea].includes(item?.type)}
          // label={showPlaceholder ? null : item?.title}
          label={showPlaceholder ? null : getFormTitle()}

          disabled={item?.editable === false}
          onChangeText={text => {
            // formik.setFieldValue(item.internalId, text);
            FormTransform.setAppInputValue(formik, item, text);
          }}
          placeholder={
            showPlaceholder
              ? getContentTitle(item?.title) || getContentTitle(item?.name) || getContentTitle(item?.placeHolder)
              : null
          }
          keyboardType={item?.keyboardType ? item?.keyboardType : ''}
          value={formik.values[item.internalId] ?? ''}
          handleBlur={() => {
            formik.handleBlur(item.internalId);
          }}
          isPassword={!!item?.isSecured}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          isBoldTitle={isBoldTitle}
          hint={showHint ? getContentTitle(item?.description) : null}
          PreIcon={PreIcon}
          onLayout={onLayout}
        />
      );
    case FORM.FORM_TYPE.integer:
      return (
        <AppInput
          key={key}
          style={[RegisterFormStyles.formItemView, ...style]}
          isRequired={item?.required}
          // label={showPlaceholder ? null : item?.title}
          label={showPlaceholder ? null : getFormTitle()}

          disabled={item?.editable === false}
          onChangeText={text => {
            // formik.setFieldValue(item.internalId, text);
            const newText = StringHelper.getFloatNumber(text);
            FormTransform.setAppInputValue(formik, item, newText);
          }}
          placeholder={
            showPlaceholder ? getContentTitle(item?.placeHolder) || item?.title || getContentTitle(item?.name) : null
          }
          keyboardType={Platform.OS === 'ios' ? 'numbers-and-punctuation' : 'decimal-pad'}
          value={formik.values[item.internalId] ?? ''}
          handleBlur={() => {
            formik.handleBlur(item.internalId);
          }}
          isPassword={!!item?.isSecured}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          isBoldTitle={isBoldTitle}
          hint={showHint ? getContentTitle(item?.description) : null}
          PreIcon={PreIcon}
          onLayout={onLayout}
        />
      );

    case FORM.FORM_TYPE.password:
      return (
        <AppInput
          key={key}
          style={[RegisterFormStyles.formItemView, ...style]}
          // isRequired={item.required}
          isRequired={getFormRequired()}
          // label={item?.title}
          label={getFormTitle()}

          disabled={item?.editable === false}
          isPassword={true}
          onChangeText={text => {
            formik.setFieldValue(item.internalId, text);
          }}
          value={formik.values[item.internalId]}
          handleBlur={() => {
            formik.handleBlur(item.internalId);
          }}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          isBoldTitle={isBoldTitle}
          hint={showHint ? getContentTitle(item?.description) : null}
          onLayout={onLayout}
        />
      );
    case FORM.FORM_TYPE.tel:
      return (
        <AppInput
          key={key}
          style={[RegisterFormStyles.formItemView, ...style]}
          // isRequired={item.required}
          isRequired={getFormRequired()}
          // label={item?.title}
          label={getFormTitle()}

          disabled={item?.editable === false}
          keyboardType={'phone-pad'}
          onChangeText={text => {
            formik.setFieldValue(item.internalId, text);
          }}
          value={formik.values[item.internalId]}
          handleBlur={() => {
            formik.handleBlur(item.internalId);
          }}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          isBoldTitle={isBoldTitle}
          hint={getContentTitle(item?.description)}
          placeholder={
            showPlaceholder ? getContentTitle(item?.placeHolder) || item?.title || getContentTitle(item?.name) : null
          }
          onLayout={onLayout}
        />
      );
    case FORM.FORM_TYPE.email:
      return (
        <AppInput
          key={key}
          style={[RegisterFormStyles.formItemView, ...style]}
          // isRequired={item.required}
          isRequired={getFormRequired()}
          // label={item?.title}
          label={getFormTitle()}

          disabled={item?.editable === false}
          keyboardType={'email-address'}
          onChangeText={text => {
            // formik.setFieldValue(item.internalId, text);
            FormTransform.setAppInputValue(formik, item, text);
          }}
          value={formik.values[item.internalId]}
          handleBlur={() => {
            formik.handleBlur(item.internalId);
          }}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          isBoldTitle={isBoldTitle}
          hint={getContentTitle(item?.description)}
          placeholder={
            showPlaceholder ? getContentTitle(item?.placeHolder) || item?.title || getContentTitle(item?.name) : null
          }
          onLayout={onLayout}
        />
      );
    case FORM.FORM_TYPE.birtday:
    case FORM.FORM_TYPE.birthday:
      return (
        <AppDateTimePicker
          key={key}
          format={DATE_FORMAT.UIFullDate}
          fromFormat={DATE_FORMAT.StandardFullDate}
          style={[RegisterFormStyles.formItemView, ...style]}
          // isRequired={item.required}
          isRequired={getFormRequired()}
          // title={item?.title}
          title={getFormTitle()}

          disabled={item?.editable === false}
          maxDate={moment().date()}
          minDate={new Date(1900, 1, 1)}
          onPickDate={text => {
            console.log('AppDateTimePicker>>>', text, typeof text);
            // formik.setFieldValue(item.id, text);
            formik.setFieldValue(item.internalId, formatDate(text, DATE_FORMAT.StandardDate, DATE_FORMAT.UIDate));
          }}
          value={formik.values[item.internalId]}
          // handleBlur={() => {
          //   formik.handleBlur(item.id);
          // }}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          isBoldTitle={isBoldTitle}
          hint={getContentTitle(item?.description)}
          placeHolder={getContentTitle(item?.placeHolder)}
          onLayout={onLayout}
        />
      );
    case FORM.FORM_TYPE.date:
      return (
        <AppDateTimePicker
          key={key}
          format={DATE_FORMAT.UIDate}
          fromFormat={DATE_FORMAT.StandardDate}
          style={[RegisterFormStyles.formItemView, ...style]}
          // isRequired={item.required}
          isRequired={getFormRequired()}
          // title={item?.title}
          title={getFormTitle()}

          disabled={item?.editable === false}
          maxDate={EvalTransform.getMaxDate(item?.maxDate?.javascript, values)}
          minDate={EvalTransform.getMinDate(item?.minDate?.javascript, values)}
          onPickDate={text => {
            console.log('AppDateTimePicker>>>onPickDate>>', text, typeof text);
            const newValue = formatDate(text, DATE_FORMAT.StandardDate, DATE_FORMAT.UIDate);
            const newValues = {
              ...formik.values,
              ...extraFormValues,
            };
            newValues[item.internalId] = newValue;
            FormTransform.resetValueForHiddenField(formik, formData, checkCondition, newValues);
              // formik.setFieldTouched(item.internalId, true);
              formik.setFieldValue(item.internalId, newValue);
              // FIXME: 29/05/2024 sometime still keep error message after setValue for datepicker
              setTimeout(() => {
                  console.log('formik.setFieldError>>>', item.internalId)
                  formik.setFieldError(item.internalId, null)
              }, 300)

            //TODO: checkEvalExpression.
          }}
          value={formik.values[item.internalId]}
          // handleBlur={() => {
          //   formik.handleBlur(item.id);
          // }}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          isBoldTitle={isBoldTitle}
          hint={getContentTitle(item?.description)}
          placeHolder={getContentTitle(item?.placeHolder)}
          onLayout={onLayout}
        />
      );
    case FORM.FORM_TYPE.datetime:
      return (
        <AppDateTimePicker
          mode={'datetime'}
          key={key}
          format={DATE_FORMAT.UIFullDate}
          fromFormat={DATE_FORMAT.StandardFullDate}
          style={[RegisterFormStyles.formItemView, ...style]}
          // isRequired={item.required}
          isRequired={getFormRequired()}
          // title={item?.title}
          title={getFormTitle()}

          disabled={item?.editable === false}
          onPickDate={text => {
            console.log('AppDateTimePicker>>>', text, typeof text);
            // const newValue = formatDate(text, DATE_FORMAT.StandardFullDate);
            const newValue = formatDate(text, DATE_FORMAT.StandardFullDate, DATE_FORMAT.UIFullDate);
            // formik.values[item.internalId] = newValue;
            const newValues = {
              ...formik.values,
              ...extraFormValues,
            };
            newValues[item.internalId] = newValue;
            FormTransform.resetValueForHiddenField(formik, formData, checkCondition, newValues);
            formik.setFieldValue(item.internalId, newValue);
              setTimeout(() => {
                  console.log('formik.setFieldError>>>', item.internalId)
                  formik.setFieldError(item.internalId, null)
              }, 300)

            //TODO: checkEvalExpression.
          }}
          value={formik.values[item.internalId]}
          // handleBlur={() => {
          //   formik.handleBlur(item.id);
          // }}
          maxDate={EvalTransform.getMaxDate(item?.maxDate?.javascript, values)}
          minDate={EvalTransform.getMinDate(item?.minDate?.javascript, values)}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          isBoldTitle={isBoldTitle}
          hint={getContentTitle(item?.description)}
          placeHolder={getContentTitle(item?.placeHolder)}
          onLayout={onLayout}
        />
      );
    case FORM.FORM_TYPE.time:
      return (
        <AppDateTimePicker
          mode="time"
          minuteInterval={item?.step ?? 1}
          format={DATE_FORMAT.Time}
          fromFormat={DATE_FORMAT.StandardTime}
          key={key}
          style={[RegisterFormStyles.formItemView, ...style]}
          // isRequired={item.required}
          isRequired={getFormRequired()}
          // title={item?.title}
          title={getFormTitle()}

          disabled={item?.editable === false}
          onPickDate={text => {
            console.log('AppDateTimePicker>>>', text, typeof text);
            const newValue = formatDate(text, DATE_FORMAT.StandardTime, DATE_FORMAT.Time);
            // formik.values[item.internalId] = newValue;
            const newValues = {
              ...formik.values,
              ...extraFormValues,
            };
            newValues[item.internalId] = newValue;
            FormTransform.resetValueForHiddenField(formik, formData, checkCondition, newValues);
            formik.setFieldValue(item.internalId, newValue);

            //TODO: checkEvalExpression.
          }}
          value={formik.values[item.internalId]}
          // handleBlur={() => {
          //   formik.handleBlur(item.id);
          // }}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          isBoldTitle={isBoldTitle}
          hint={getContentTitle(item?.description)}
          placeHolder={getContentTitle(item?.placeHolder)}
          onLayout={onLayout}
        />
      );
    case FORM.FORM_TYPE.checkbox:
      return (
        <AppHtmlRadioView
          key={key}
          style={[RegisterFormStyles.formItemView, ...style]}
          data={item}
          selected={formik.values[item.internalId] ? formik.values[item.internalId] : false}
          // title={item?.title}
          title={getFormTitle()}

          disabled={item?.editable === false}
          onSelect={i => {
            console.log('AppHtmlRadioView>>>onSelect>>>', item, i, item.internalId, i?.internalId);

            // formik.values[item.internalId] = i;
            const newValues = {
              ...formik.values,
              ...extraFormValues,
            };
            newValues[item.internalId] = i;
            FormTransform.resetValueForHiddenField(formik, formData, checkCondition, newValues);
            formik.setFieldTouched(item.internalId, true);

            formik.setFieldValue(item.internalId, i);
            // formik.setFieldError(item.internalId, null);
          }}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          isBoldTitle={isBoldTitle}
          hint={getContentTitle(item?.description)}
          // isRequired={item.required}
          isRequired={getFormRequired()}
          onLayout={onLayout}
        />
      );
    case FORM.FORM_TYPE.calendar:
      return (
        <AppCalendarView
          key={key}
          format={DATE_FORMAT.UIDate}
          fromFormat={DATE_FORMAT.StandardDate}
          style={[RegisterFormStyles.formItemView, ...style]}
          // isRequired={item.required}
          isRequired={getFormRequired()}
          // title={item?.title}
          title={getFormTitle()}

          disabled={item?.editable === false}
          onPickDate={text => {
            console.log('AppCalendarView>>>', text, typeof text, formatDate(text));
            // formik.setFieldValue(item.internalId, formatDate(text));
            const newValue = formatDate(text, DATE_FORMAT.StandardDate, DATE_FORMAT.UIDate);
            // formik.values[item.internalId] = newValue;
            const newValues = {
              ...formik.values,
              ...extraFormValues,
            };
            newValues[item.internalId] = newValue;
            FormTransform.resetValueForHiddenField(formik, formData, checkCondition, newValues);
            formik.setFieldValue(item.internalId, newValue);
          }}
          // minDate={item?.minDate}
          minDate={EvalTransform.getMinDate(item?.minDate?.javascript, values)}
          maxDate={EvalTransform.getMinDate(item?.maxDate?.javascript, values)}
          value={formik.values[item.internalId]}
          // handleBlur={() => {
          //   formik.handleBlur(item.id);
          // }}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          isBoldTitle={isBoldTitle}
          onLayout={onLayout}
        />
      );
    case FORM.FORM_TYPE.inputPicker:
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return (
        <AppInputPicker
          key={key}
          style={[RegisterFormStyles.formItemView, ...style]}
          data={item.data}
          value={formik.values[item.internalId]}
          isRequired={item.required}
          // title={item?.title}
          title={getFormTitle()}

          disabled={item?.editable === false}
          onChangeText={i => {
            console.log('AppInputPicker>>>onSelect>>>', item, i, item.internalId, i?.internalId);

            formik.values[item.internalId] = i;
            const newValues = {
              ...formik.values,
              ...extraFormValues,
            };
            newValues[item.internalId] = i;
            FormTransform.resetValueForHiddenField(formik, formData, checkCondition, newValues);
            formik.setFieldTouched(item.internalId, true);
            formik.setFieldValue(item.internalId, i);
            // formik.setFieldError(item.internalId, null);
          }}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          isBoldTitle={isBoldTitle}
          placeHolder={getContentTitle(item?.placeHolder)}
          onLayout={onLayout}
        />
      );
    case FORM.FORM_TYPE.imagePicker:
    case FORM.FORM_TYPE.camera:
      // eslint-disable-next-line @typescript-eslint/no-empty-function
        const uploadPath = FileTransform.fixUploadPath(item?.uploadPath)
      return (
        <AppImagePicker
          type={item.type === FORM.FORM_TYPE.camera ? IMAGE_PICKER_TYPE.camera : IMAGE_PICKER_TYPE.gallery}
          key={key}
          style={[RegisterFormStyles.formItemView, ...style]}
          // data={item.data}
          // value={formik.values[item.internalId]}
          // isRequired={item.required}
          isRequired={getFormRequired()}
          // title={item?.title}
          title={getFormTitle()}

          disabled={item?.editable === false}
          selected={formik.values[item.internalId] ? formik.values[item.internalId] : []}
          onSelect={i => {
            const newValue = i;
            formik.setFieldValue(item.internalId, newValue);
            // formik.values[item.internalId] = i;
            const newValues = {
              ...formik.values,
              ...extraFormValues,
            };
            newValues[item.internalId] = newValue;
            console.log('AppImagePicker>>>onSelect>>>', newValue, newValues)
            FormTransform.resetValueForHiddenField(formik, formData, checkCondition, newValues);
          }}
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          isBoldTitle={isBoldTitle}
          // folderUpload={folderUpload}
          folderUpload={!_.isEmpty(uploadPath) ? uploadPath : folderUpload}
          uploadFileName={FileTransform.fixUploadName(item?.uploadFileName, {
              ...extraFormValues,
              ...formik.values,
              internalId: item.internalId
          })}
          s3config={item?.s3config}
          maxFile={item.maxFile}
          onLayout={onLayout}
        />
      );
    case FORM.FORM_TYPE.note:
      const styleCSS = item?.styleCSS ? item?.styleCSS : {};
      return (
        <AppNoteView
          key={key}
          style={{
            ...RegisterFormStyles.formItemView,
            ...styleCSS,
          }}
          // title={item?.title}
          title={getFormTitle()}

          data={values}
          structure={formData}
        />
      );
    case FORM.FORM_TYPE.misdelivered_item_form:
      const error1 = getErrorForm(formik.errors, formik.touched, item.internalId);
      console.log(
        'FORM.FORM_TYPE.misdelivered_item_form>>>>',
        item,
        error1,
        formik.touched,
        formik.errors,
        item?.internalId,
      );
      console.log('FORM.FORM_TYPE.misdelivered_item_form>>>>touch', formik.touched);
      console.log('FORM.FORM_TYPE.misdelivered_item_form>>>>error1', error1);

      return (
        <MisDeliveredView
          key={key}
          style={RegisterFormStyles.formItemView}
          data={item}
          selected={formik.values[item.internalId] ?? []}
          // title={item?.title}
          title={getFormTitle()}

          hint={item?.description}
          isBoldTitle={isBoldTitle}
          extraFormValues={extraFormValues}
          formik={formik}
          formData={formData}
          scrollViewRef={scrollViewRef}
          onSelect={rs => {
            console.log('MisDeliveredView>>>onSubmitSuccess>>>', rs);
            //TODO: add new item
            // const values = formik.values[item.internalId];
            // const newValues = [...values, rs];
            formik.setFieldTouched(item.internalId, true);
            formik.setFieldValue(item.internalId, rs);
            if (onSelect) {
              onSelect(item, rs);
            }
            // formik.values[item.internalId] = newValues;
          }}
          onCheckValid={onCheckValid}
          error={error1}
          // isRequired={item.required}
          isRequired={getFormRequired()}
          onLayout={onLayout}
        />
      );
    case FORM.FORM_TYPE.toggleSwitch:
      return (
        <AppSwitch
          key={key}
          style={RegisterFormStyles.formItemView}
          // label={item?.title}
          label={getFormTitle()}

          data={values}
          // structure={formData}
          isBoldTitle
          error={getErrorForm(formik.errors, formik.touched, item.internalId)}
          // isRequired={item.required}
          isRequired={getFormRequired()}
          onLayout={onLayout}
          value={formik.values[item.internalId]}
          onValueChange={x => {
            formik.setFieldValue(item.internalId, x);
          }}
        />
      );
    case FORM.FORM_TYPE.verify_map:
      return (
        <VerifyMapFormItem
          key={key}
          style={RegisterFormStyles.formItemView}
          onLayout={onLayout}
          location={extraData?.location}
          mapSettings={extraData?.mapSettings}
          extraData={extraData}
          metaItem={item}
          onSelect={() => {
            if (extraData?.locationType === LocationType.home) {
              return;
            }
            navigate(Screens.VERIFY_MAP_SCREEN, {
              data: {
                location: extraData?.location,
                mapSettings: extraData?.mapSettings,
                address_selected_extra: formik.values[FORM.FORM_ELEMENT_NAME.address_selected_extra],
              },
            });
          }}
          verifyOnMap={extraData?.verifyOnMap}
          locationType={extraData?.locationType}
          address_selected_extra={formik.values[FORM.FORM_ELEMENT_NAME.address_selected_extra]}
        />
      );
  }
  return <></>;
};
