import { ArrowRightIcon } from '@assets';
import { AppButton, AppKAView, AppSafeAreaView, AppText, AppEmptyView } from '@components';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import theme, { globalStyle } from '@theme';
import { getContentTitle } from '@utils/transform/form';
import _ from 'lodash';
// import { getLogoutRequest } from '@redux/auth/authSlice';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {FlatList, StatusBar, TouchableOpacity, useWindowDimensions, View} from 'react-native';
import { useDispatch } from 'react-redux';
import { useLogic } from './Notifications.hook';
import { NotificationItemStyle } from './Notifications.style';
import { styles } from './Notifications.style';
import {AppHtmlView} from "@screens/main/quiz/QuizFailed/components/AppNote.view";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {Font, FontSize} from "@theme/size";
import {HtmlUtil} from "@utils/common/html";

const Notifications = props => {
  const dispatch = useDispatch();
    const fontScale = useWindowDimensions().fontScale;

    const { data, onItemPress, onRefresh, refresh } = useLogic(props);
  const { t } = useTranslation();
  const actions = ['click1', 'click2'];
  const renderActions = () => {
    return (
      <>
        <View>
          {actions?.map(x => {
            return <AppButton text={x} />;
          })}
        </View>
      </>
    );
  };

  const fixMessageUI = (message) => {
      if(HtmlUtil.isHtml(message)){
          return '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+message
      }
      return "    "+message
  }
  const renderItem = ({ item, index }) => {
      console.log('renderItem>>>', item)

      return (
      <View style={[globalStyle.directionRow, NotificationItemStyle.viewContainer]}>
        <TouchableOpacity
          onPress={() => {
            onItemPress(item, index);
          }}
          activeOpacity={_.isEmpty(item.category) ? 1 : 0.7}
          style={[NotificationItemStyle.viewSelect]}>
            <View style={{position: 'absolute', marginTop: 3 * fontScale}}>
                <FontAwesome5 name={item?.icon?.class} color={item?.icon?.color} size={14*fontScale} solid/>
            </View>

            <AppHtmlView style={{...globalStyle.fullWidth,fontSize: FontSize.Font15}} text={fixMessageUI(item.message)}/>
            {!!item?.date && (
                <AppText style={[globalStyle.fullWidth, NotificationItemStyle.itemText]}>{item.date}</AppText>
            )}

          {/* {item?.passed ? <></> : <ArrowRightIcon />} */}
        </TouchableOpacity>
        {/*{renderActions()}*/}
      </View>
    );
  };
  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={styles.areaStyle}>
        <GenericHeader onBackClicked={goBack} title={t('NOTIFICATIONS')} style={[{ backgroundColor: '#EDEDED' }]} />
        <AppKAView>
          <View style={[NotificationItemStyle.containerView]}>
            <FlatList
              keyExtractor={(item, index) => String(index)}
              contentContainerStyle={{ marginTop: theme.spacing.lg, flexGrow: 1 }}
              data={data}
              renderItem={renderItem}
              scrollIndicatorInsets={{ right: 1 }}
              ListEmptyComponent={AppEmptyView}
              onRefresh={() => onRefresh()}
              refreshing={refresh}

              showsVerticalScrollIndicator={false}
            />
          </View>
        </AppKAView>
        {/* <AuthFooter /> */}
      </AppSafeAreaView>
    </>
  );
};

export { Notifications };
