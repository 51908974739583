import { useRoute } from '@react-navigation/native';
import { getD2DItemsRequest } from '@redux/survey/getD2DItemsSlice';
import { saveFeedbackD2DForDailyRequest } from '@redux/survey/saveFeedbackD2DForDailySlice';
import { saveFeedbackD2DRequest } from '@redux/survey/saveFeedbackD2DSlice';
import { getD2DItemsCreator } from '@redux/survey/selectors';
import {goBack, navigate, popToTop} from '@routes/navigationUtils';
import { Screens } from '@routes/route';
import { D2DTransform } from '@utils/transform/d2d';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {SuccessMessageMock} from "@constant/model/mock/successMessage";
import {globalLoading} from "@redux/global/selectors";
import {hideLoading} from "@redux/global/globalSlice";

export const useDailyItem = navigation => {
  const dispatch = useDispatch();
  const selectorData = useSelector(getD2DItemsCreator)?.data;
  const getD2DItemsData = selectorData?.items;
  const description = selectorData?.description;
  const loading = useSelector(globalLoading);

  const paramsData = useRoute().params?.data;
  const headerTitle = paramsData.name;
  const [data, setData] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [messageInfo, setMessageInfo] = useState({});

  const [refreshing, setRefreshing] = useState(false);
  const onChangeText = (text, item) => {
    console.log('onChangeText>>>', text, item);
    const exist = data.find(x => x.date === item.date);
    if (exist) {
      exist.amount = text;
    }
    setData(_.cloneDeep(data));
  };

  const onRefresh = () => {
    setRefreshing(false);

    getD2DItemsApi();
  };

  const onSave = () => {
    saveFeedbackD2DForDailyApi();
  };

  const saveFeedbackD2DForDailyApi = async () => {
    const filterData = data?.filter(item => item.clickable && !_.isEmpty(item.amount));
    const payload = filterData?.map(x => {
      return {
        target_week: x.week,
        target_date: x.date,
        amount: x.amount,
      };
    });
    if (_.isEmpty(payload)) {
      return;
    }
    console.log('getD2DSubmitRequest>>>', payload);
    dispatch(
      saveFeedbackD2DForDailyRequest(
        payload,
        rs => {
          // handleSuccess(rs);
          // popToTop();
          // setIsSuccess(true);
          // setMessageInfo(rs?.message)
          navigate(Screens.SUCCESSFUL_SCREEN, {
            data: rs?.message,
            header: {
              title: headerTitle,
              backClick: () => {
                popToTop();
              },
            },
          });
        },
        err => {},
      ),
    );
  };

  const getD2DItemsApi = () => {
    const payload = {
      ...paramsData,
    };
    dispatch(getD2DItemsRequest(payload));
  };

  useEffect(() => {
    getD2DItemsApi();
  }, []);

  useEffect(() => {
    // setTimeout(() => {
    //   dispatch(hideLoading())
    // }, 3000)
  }, [])

  useEffect(() => {
    if (_.isEmpty(getD2DItemsData)) {
      setData([]);
    } else {
      setData(_.cloneDeep(getD2DItemsData));
    }
    console.log('useEffect>>>', getD2DItemsData);
  }, [getD2DItemsData]);
  console.log('getD2DItemsData>>>', getD2DItemsData);
  return { data, onChangeText, headerTitle, onSave, onRefresh, refreshing, description, isSuccess, messageInfo, loading };
};
