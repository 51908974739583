import { createAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import {getLogoutSuccess} from "@redux/auth/authSlice";

const initialState = {
  isFirstTimeInApp: true,
  menus: null,
  dashboard: null,
  dashboardDetail: null,
  dashboardLoading: false,
  reportModal: [],
  validateReport: null,
  listenDeepLink: false,
  isFirsTimeLogin: false,
  location: null,
  surveys: null,
  notificationPermission: false,
  notificationModal: false,
  notificationModalContent: {
    title: '',
    body: '',
  },
  deviceToken: '',
  bonusInfo: null,
  postingLocation: {
    mapSettings: null,
    dispatchPointList: [],
    dispatchPoint: null,
  },
  timeline: [],
  importantNotification:{},
  netInfo: null,
  checkStatusOfDashBoard:{},
  getPrizeDrawDetail: {
    loading: false,
    payload: null,
    error: null,
  },
  readUnderScoreNotification: {
    loading: false,
    payload: null,
    error: null,
  },
};

export const checkImportantNotificationRequest = createAction(
  'checkImportantNotificationRequest/checkImportantNotificationRequest',
  (payload, onSuccess, onFailed) => {
    console.log('prepareAction>>>', payload, onSuccess, onFailed);
    return {
      payload: payload,
      meta: onSuccess,
      error: onFailed,
    };
  },
);


export const checkStatusOfDashboardRequest = createAction(
    'checkStatusOfDashboardRequest/checkStatusOfDashboardRequest',
    (payload, onSuccess, onFailed) => {
      console.log('prepareAction>>>', payload, onSuccess, onFailed);
      return {
        payload: payload,
        meta: onSuccess,
        error: onFailed,
      };
    },
);


export const getValidateReportRequest = createAction(
    'getValidateReport/getValidateReportRequest',
    (payload, onSuccess, onFailed) => {
      console.log('prepareAction>>>', payload, onSuccess, onFailed);
      return {
        payload: payload,
        meta: onSuccess,
        error: onFailed,
      };
    },
);

export const getDetailDispatchPoint = createAction('app/getDetailDispatchPoint', (payload, onSuccess, onFailed) => {
  console.log('prepareAction>>>', payload, onSuccess);
  return {
    payload: payload,
    meta: onSuccess,
    error: onFailed,
  };
});

export const getMapSettingRequest = createAction(
    'getMapSetting/getMapSettingRequest',
    (payload, onSuccess, onFailed) => {
      console.log('prepareAction>>>', payload, onSuccess, onFailed);
      return {
        payload: payload,
        meta: onSuccess,
        error: onFailed,
      };
    },
);

export const getPrizeDrawDetailRequest = createAction(
    'getPrizeDrawDetail/getPrizeDrawDetailRequest',
    (payload, onSuccess, onFailed) => {
      console.log('prepareAction>>>', payload, onSuccess, onFailed);
      return {
        payload: payload,
        meta: onSuccess,
        error: onFailed,
      };
    },
);

export const readUnderScoreNotificationRequest = createAction(
    'readUnderScoreNotification/readUnderScoreNotificationRequest',
    (payload, onSuccess, onFailed) => {
      console.log('prepareAction>>>', payload, onSuccess, onFailed);
      return {
        payload: payload,
        meta: onSuccess,
        error: onFailed,
      };
    },
);

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    getMenuSuccess(state, action) {
      state.menus = action.payload;
    },
    getMenu() {
      console.log('call getMenu saga');
    },
    getMenuFailed() {
      console.log('call getMenu fail');
    },

    getTimelineSuccess(state, action) {
      state.timeline = action.payload;
    },
    getTimelineRequest() {
      console.log('call getTimeline saga');
    },
    getTimelineFailed() {
      console.log('call getTimeline fail');
    },
    resetTimeline(state, action) {
      state.timeline = [];
    },

    getBonusPoint(state, action) {
      state.bonusInfo = action.payload;
    },
    getDashboard(state, action) {
      console.log('call getDashboard saga');
      state.dashboardLoading = true
    },
    hideDashboardLoading(state, action) {
      console.log('hideDashboardLoading>>>');
      state.dashboardLoading = false
    },

    getDashboardSuccess(state, action) {
      //NOTE: sẽ ko call loading ở success này, hàm này chỉ cập nhật data
      state.dashboard = action.payload?.filter(x => !!x) ?? [];
    },
    getDashboardSuccessWithLoading(state, action) {
      //NOTE: sẽ loading ở success này để kết thúc việc call dât
      state.dashboard = action.payload?.filter(x => !!x) ?? [];
      state.dashboardLoading = false
    },
    getDashboardFailed(state, action) {
      console.log('call getDashboard fail');
      state.dashboardLoading = false
    },

    getDashboardDetail(state, action) {
      console.log('call getDashboardDetail saga');
    },

    getDashboardDetailSuccess(state, action) {
      state.dashboardDetail = action.payload;
    },
    getDashboardDetailFailed() {
      console.log('call getDashboardDetail fail');
    },

    getReportModalSuccess(state, action) {
      state.reportModal = action.payload;
    },
    getReportModalRequest() {
      console.log('call getReportModal saga');
    },
    getReportModalFailed() {
      console.log('call getReportModal fail');
    },

    getValidateReportSuccess(state, action) {
      state.validateReport = action.payload;
    },
    // getValidateReportRequest() {
    //   console.log('call getValidateReport saga');
    // },
    getValidateReportFailed(state, action) {
      state.validateReport = null;
      console.log('call getValidateReport fail');
    },

    getIsFirstTimeInApp(state) {
      state.isFirstTimeInApp = false;
    },

    getListenDeepLink(state, action) {
      state.listenDeepLink = action.payload;
    },

    getLocation(state, action) {
      state.location = action.payload;
    },
    updateFirstTimeLogin(state, action) {
      console.log('updateFirstTimeLogin>>>acion>>>', action);
      state.isFirsTimeLogin = action.payload.isFirsTimeLogin;
    },

    getSurveySuccess(state, action) {
      state.surveys = action.payload;
    },
    getSurveyRequest() {
      console.log('call getSurveyRequest saga');
    },
    getSurveyFailed() {
      console.log('call getSurveyRequest fail');
    },

    getNotificationPermission(state, action) {
      state.notificationPermission = action.payload;
    },

    getNotificationModal(state, action) {
      state.notificationModal = action.payload;
    },

    getNotificationModalContent(state, action) {
      state.notificationModalContent = action.payload;
    },

    getDeviceToken(state, action) {
      state.deviceToken = action.payload;
    },
    submitRFIDNotification(state, action) {
      // state.dashboard.notification
      const dashboard = _.cloneDeep(state.dashboard);
      const notification = dashboard?.find(x => x.type === 'notification');
      console.log('submitRFIDNotification>>>', notification);
      if (notification) {
        // const indexNotification = dashboard.findIndexOf(notification);
        const index = action.payload.index;
        notification?.data?.splice(index, index);
        state.dashboard = dashboard;
      }
      console.log('submitRFIDNotification>>>', action);
    },

    getMapSettingSuccess(state, action) {
      state.postingLocation.mapSettings = action.payload;
    },
    // getMapSettingRequest() {
    //   console.log('call getMapSettingRequest saga');
    // },
    getMapSettingFailed() {
      console.log('call getMapSettingRequest fail');
    },

    getListDispatchPointSuccess(state, action) {
      state.postingLocation.dispatchPointList = action.payload;
    },
    getListDispatchPoint() {
      console.log('call getListDispatchPoint saga');
    },
    getListDispatchPointReset(state) {
      console.log('call getListDispatchPoint reset');
      state.postingLocation.dispatchPointList = [];
    },
    getListDispatchPointFailed() {
      console.log('call getListDispatchPoint fail');
    },

    getDetailDispatchPointSuccess(state, action) {
      state.postingLocation.dispatchPoint = action.payload;
    },
    // getDetailDispatchPointRequest() {
    //   console.log('call getDetailDispatchPointRequest saga');
    // },
    getDetailDispatchPointFailed() {
      console.log('call getDetailDispatchPointRequest fail');
    },
    checkImportantNotificationSuccess(state, action) {
      // state.loading = false;
      state.importantNotification = action.payload;
      // state.error = null;
    },
    // getImportantNotificationRequest() {
    //   console.log('call getImportantNotificationFailed request');
    // },
    checkImportantNotificationFailed() {
      console.log('call getImportantNotificationFailed fail');

    },
    updateNetInfo(state, action) {
      state.netInfo = action.payload;
    },
    checkStatusOfDashboardFailed(){

    },
    checkStatusOfDashboardSuccess(state, action) {
      state.checkStatusOfDashBoard = action.payload
    },
    getListDashboardItemRequest(state, action){

    },
    getPrizeDrawDetailSuccess(state, action) {
      state.getPrizeDrawDetail.loading = false;
      state.getPrizeDrawDetail.payload = action.payload;
      state.getPrizeDrawDetail.error = null;
    },
    getPrizeDrawDetailFailed(state, action) {
      state.getPrizeDrawDetail.loading = false;
      state.getPrizeDrawDetail.error = action.payload;
    },
    readUnderScoreNotificationSuccess(state, action) {
      state.readUnderScoreNotification.loading = false;
      state.readUnderScoreNotification.payload = action.payload;
      state.readUnderScoreNotification.error = null;
    },
    readUnderScoreNotificationFailed(state, action) {
      state.readUnderScoreNotification.loading = false;
      state.readUnderScoreNotification.error = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getLogoutSuccess, (state, action) => {
      // action is inferred correctly here if using TS
      console.log('getLogoutSuccess>>addCase>>>', state)
      return {
        ...initialState,
        isFirstTimeInApp: state.isFirstTimeInApp,
        netInfo: state.netInfo,
      }
    });
  },
});

export const {
  getMenuSuccess,
  getMenu,
  getMenuFailed,
  getDashboard: getDashboardRequest,
  getDashboardSuccess,
  getDashboardSuccessWithLoading,
  getDashboardFailed,
  getDashboardDetailRequest,
  getDashboardDetailSuccess,
  getDashboardDetailFailed,
  getIsFirstTimeInApp,
  getListenDeepLink,
  getReportModalSuccess,
  getReportModalRequest,
  getReportModalFailed,
  updateFirstTimeLogin,
  getValidateReportSuccess,
  // getValidateReportRequest,
  getValidateReportFailed,
  getLocation,
  getSurveySuccess,
  getSurveyRequest,
  getSurveyFailed,
  getNotificationPermission,
  getNotificationModal,
  getNotificationModalContent,
  getBonusPoint,
  getDeviceToken,
  submitRFIDNotification,
  getTimelineSuccess,
  getTimelineRequest,
  getTimelineFailed,
  getMapSettingSuccess,
  // getMapSettingRequest,
  getMapSettingFailed,
  getListDispatchPointSuccess,
  getListDispatchPointReset,
  getListDispatchPoint: getListDispatchPointRequest,
  getListDispatchPointFailed,
  getDetailDispatchPointSuccess,
  // getDetailDispatchPointRequest,
  getDetailDispatchPointFailed,
  resetTimeline,
    // getImportantNotificationRequest,
    checkImportantNotificationSuccess,
    checkImportantNotificationFailed,
  updateNetInfo,
  hideDashboardLoading,
  checkStatusOfDashboardFailed,
  checkStatusOfDashboardSuccess,
  getListDashboardItemRequest,
  getPrizeDrawDetailFailed,
  getPrizeDrawDetailSuccess,
    readUnderScoreNotificationFailed,
    readUnderScoreNotificationSuccess,
} = appSlice.actions;

export default appSlice.reducer;
