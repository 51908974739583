import { ArrowBorderIcon, ChevronDownIcon } from '@assets';
import { useCollapseView } from '@screens/main/prizeDraw/collapseView/hook';
import theme, { globalStyle } from '@theme';
import { Font, FontSize } from '@theme/size';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import Collapsible from 'react-native-collapsible';
import { AppText } from '../../../../components/AppText/AppText';
import { styles } from './styles';

export const CollapseView = props => {
  const { isLast, children, title } = props;
  console.log('PrizeContent>>>children>>>', children);
  const { handleCollapsed, isExpand } = useCollapseView();
  return (
    <View style={[styles.container, isLast && { marginBottom: 60 }]}>
      <TouchableOpacity
        activeOpacity={0.7}
        onPress={handleCollapsed}
        style={[
          styles.accordion,
          !isExpand ? {} : styles.isExpanded,
          {
            // height: 42 * headerLines
            // minHeight: 42*2
          },
        ]}>
        <View
          style={[
            globalStyle.directionColumm,
            globalStyle.flex1,
            { paddingVertical: theme.spacing.md, paddingLeft: theme.spacing.md },
          ]}>
          <AppText style={{ ...Font.Bold_700, fontSize: FontSize.Font15 }}>{title}</AppText>
        </View>

        <ChevronDownIcon style={{ transform: isExpand ? [{ rotate: '180deg' }] : [] }} />
      </TouchableOpacity>
      <View
        style={[
          {
            top: -8,
            left: 30,
            zIndex: 100,
            transform: [{ rotate: '180deg' }],
            paddingRight: !isExpand ? 16 : 0,
            // alignItems: 'flex-start',
            alignSelf: 'flex-start',
          },
        ]}>
        {isExpand && (
          <ArrowBorderIcon height={14} width={16} strokeWidth={1} fillColor="#E3E3E3" strokeColor="#E3E3E3" />
        )}
      </View>
      <Collapsible style={{ width: '100%' }} collapsed={!isExpand}>
        {children}
      </Collapsible>
    </View>
  );
};
