import { Constant, TICKET_STATUS } from '@constant';
import { hideListNotificationModal, hideNewsModal, hideNoInternetModal } from '@redux/common/commonSlice';
import {
  listNotificationModalSelector,
  listNotificationModalVisible,
  newsModalSelector,
  newsModalVisible,
  noInternetModalVisible,
} from '@redux/common/selectors';
import { navigate } from '@routes/navigationUtils';
import { Screens, SURVEY_LIST_SCREEN } from '@routes/route';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

export const useListNotificationModal = props => {
  const dispatch = useDispatch();
  const modalVisible = useSelector(listNotificationModalVisible);
  const modalData = useSelector(listNotificationModalSelector);
  console.log('showNewsModal>>>modalVisible>>>', modalVisible);
  console.log('showNewsModal>>>modalData>>>', modalData);

  // const data = useSelector()
  const handlePressButton = () => {
    modalVisible && dispatch(hideListNotificationModal());
    //TODO: open setting
  };
  const handleHideModal = () => {
    modalVisible && dispatch(hideListNotificationModal());
  };

  const onItemPress = item => {
    console.log('onItemPress>>>', item);
    switch (item.internalId) {
      case Constant.dashboard.notification:
        navigate(Screens.NOTIFICATIONS, {
          data: [],
        });
        break;
      case Constant.dashboard.quizzes:
        navigate(Screens.LIST_QUIZ_SCREEN);
        break;
      case Constant.dashboard.timeline_package:
        navigate(Screens.PACKAGE_TIMELINE, { type: item.internalId });
        break;
      case Constant.dashboard.timeline_bundle:
        navigate(Screens.BUNDLE_TIMELINE, { type: item.internalId });
        break;
      case Constant.dashboard.survey_cds:
        navigate(SURVEY_LIST_SCREEN, { isFromDashboard: true });
        break;
      case Constant.dashboard.survey_d2d:
        navigate(Screens.D2D_OVERVIEW);
        break;

      case Constant.menu.list_open_ticket:
        navigate(Screens.TICKET_SCREEN, {
          tabScreen: Screens.LIST_TICKET_SCREEN,
          params: { status: TICKET_STATUS.open },
        });
        break;
      case Constant.dashboard.recent_tasks:
      case Constant.dashboard.bonus_points:
        navigate(Screens.REWARD, {
          tabScreen: Screens.HISTORY_POINTS,
          // params: { status: TICKET_STATUS.open },
        });
        break;

      case Constant.dashboard.invitations:
        navigate(Screens.CDM_FORM_SCREEN, {
          data: item?.data,
          step: 0,
          // params: { status: TICKET_STATUS.open },
        });
        break;
    }
    handleHideModal();
  };
  return { handlePressButton, handleHideModal, modalData, onItemPress };
};
