export const VoucherMockData = {
  bonusInfo: {
    note: 'With 2000 points, you will redeem a voucher',
    total: 21058,
    content: 'Find out more at <a href="https://www.cadooz.com">www.cadooz.com</a>',
  },
  data: [
    {
      // image: 'https://spectos-correos-prod.s3.eu-central-1.amazonaws.com/app/voucher/adidas.svg'
      image: 'https://raw.githubusercontent.com/kyhosy/freeimage/main/adidas.svg?1234566778899'
    }
  ],
};
