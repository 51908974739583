import { Config, ConfigMode } from '@constant/config';
import env from '@env';
import { getLogoutSuccess } from '@redux/auth/authSlice';
import { store } from '@redux/store';
import axios from 'axios';
import _ from 'lodash';
import { showMessage, hideMessage } from 'react-native-flash-message';
import { useNetInfoInstance } from "@react-native-community/netinfo";

export const getParserErrorMessage = message => {
  const e = message?.description;
  if (!_.isEmpty(e)) {
    return e;
  }
  const ex = message?.error;
  if (typeof ex === 'string') {
    return ex;
  }

  if (typeof ex === 'object') {
    const description = ex?.description;
    if (!_.isEmpty(description)) {
      return description;
    } else {
      //TODO: get first error
      for (const msgKey in ex) {
        const x = ex[msgKey];
        if(!_.isEmpty(x)){
          return x?.toString();
        }
      }
    }
  }
  return message?.toString()
}

export const getNormalErrorMessage = err => {
  const message = err?.message;
  if (typeof message === 'object') {
    const mx = getParserErrorMessage(message);
    console.log('getParserErrorMessage>>>', mx)
    return mx
  }

  if (_.isEmpty(message)) {
    if(!!err?.data && typeof err.data === "string")
      return err?.data
    return JSON.stringify(err);
  }
  return err?.message;
}

export const getAxiosErrorMessage = err => {
  if (err.response?.status === 403) {
    // dispatch(getLogoutSuccess({}))
    store.dispatch(getLogoutSuccess({}));
    // @ts-ignore
    return err?.response?.data?.message ?? err?.message;
  }

  return err?.response?.data?.message ?? getNormalErrorMessage(err);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getErrorMessage = (err: any) => {
  console.log('getErrorMessage<<<', err, typeof err);
  if (_.isEmpty(err)) {
    return '';
  }
  if (typeof err === 'string') {
    return err;
  }

  if (axios.isAxiosError(err)) {
    //TODO: refactor support send error to rtpm web later
    // window.parent.postMessage(JSON.stringify(err), 'https://rtpm-dev.spectos.com/FDB-26480/current/en/6343d563-07f8-401b-a2a3-065d0a01011a/spool/customers#/list');
    return getAxiosErrorMessage(err)
  }

  return getNormalErrorMessage(err)
};

export const showErrorMsg = (msg: string, description = '', type = 'danger') => {
  console.log('showErrorMsg>>>', msg, description);
  if (_.isEmpty(msg) && _.isEmpty(description)) {
    return;
  }
  const option = {
    message: msg,
    description: description,
    type: type,
    duration: 5000,
    onPress: () => {
      // onChildPress(data)
    },
  };
  // @ts-ignore
  showMessage(option);
};

export const getFlatMsgFromObject = msg => {
  if (typeof msg === 'object') {
    if (_.isEmpty(msg)) {
      return '';
    }
    let rs = '';
    let i = 0;
    for (const msgKey in msg) {
      rs = rs + `${i === 0 ? '' : '\n'}${msgKey}: ${msg[msgKey]?.toString()}`;
      i = i + 1;
    }

    return rs;
  }

  return msg?.toString();
};

export const ApiHelper = {
  getApiKey: () => {
    const envMode = env.ENV;
    if (envMode === 'production') {
      return ConfigMode[Config.Api.mode]['spectos-apikey'];
    }
    return env['spectos-apikey'];
  },
  getApiUrl: () => {
    // const ttmName = Config.Api.ttmservice.normal;
    const envMode = env.ENV;
    const ttmName = ConfigMode.normal.ttmservice;
    const mode = Config.Api.mode;
    // const newTtmName = Config.Api.ttmservice[mode] ?? ttmName;
    if (envMode === 'production') {
      const newTtmName = ConfigMode[mode]?.ttmservice ?? ttmName;

      const newUrl = env.API_URL.replace(ttmName, newTtmName);
      return newUrl;
    }
    return env.API_URL;
  },
  paramsToObject: entries => {
    const result = {};
    for (const [key, value] of entries) {
      // each 'entry' is a [key, value] tupple
      result[key] = value;
    }
    return result;
  },

  isConnected: () => {
    // const { netInfo: { type, isConnected }, refresh } = useNetInfoInstance();
    const netInfo = store.getState()?.app?.netInfo;
    if (_.isEmpty(netInfo)) {
      return true
    }
    return netInfo?.isConnected

  }


};
