// export const PostcodeModal:
import { AddressContainerView } from '@screens/main/SearchAddress/components/AddressContainer.view';
import { useAddressContainerModal } from '@screens/main/SearchAddress/components/AddressContainerModal.hook';
import { useSearchPostcode } from '@screens/main/SearchPostcode/SearchPostcode.hook';
import { AppPostcode } from '@screens/main/SearchPostcode/components/AppPostcode.view';
import { usePostcodeContainer } from '@screens/main/SearchPostcode/components/usePostcodeContainer';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

export const AddressContainerModal = props => {
  const { t } = useTranslation();
  console.log('AddressContainerModal>>>', props);
  const {
    onSearch,
    data,
    onRefresh,
    isRefreshing,
    loadingMore,
    loading,
    onLoadMore,
    onTextChanged,
    searchText,
    onItemPress,
    onItemFavouritePress,
  } = useAddressContainerModal(props);
  return (
    <AddressContainerView
      data={data}
      loadingMore={loadingMore}
      onTextChanged={onTextChanged}
      headerText={props.header}
      isRefreshing={isRefreshing}
      loading={loading}
      onLoadMore={onLoadMore}
      emptyText={loading ? t('SEARCHING') : t('NO_ADDRESS_FOUND')}
      onRefresh={onRefresh}
      onSearch={onSearch}
      searchText={searchText}
      // descriptionText={t('ADDRESS_DESCRIPTION')}
      onItemPress={onItemPress}
      onItemFavouritePress={onItemFavouritePress}
      dispatch={props.dispatch}
      isFullScreen={false}
      postCode={props.postcode}
      descriptionText={props.description}
      onClickHref={props.onClickHref}

/>
    // <View />
  );
};

AddressContainerModal.propTypes = {
  searchText: PropTypes.string,
  onSelect: PropTypes.func,
  dispatch: PropTypes.any.isRequired,
  postCode: PropTypes.string.isRequired,
  header: PropTypes.string,
  description: PropTypes.string,
  onClickHref: PropTypes.func,
};

AddressContainerModal.defaultProps = {};
