import {
  deleteFavouriteLocationFailed,
  deleteFavouriteLocationSuccess,
} from '@redux/postingLocations/deleteFavouriteLocationSlice';
import { deleteFavouriteLocationService } from '@services/postingLocations';
import { call, put } from 'redux-saga/effects';

export function* deleteFavouriteLocationSaga({ payload, meta, error }) {
  try {
    const res = yield call(deleteFavouriteLocationService, payload);
    console.log('deleteFavouriteLocationSaga>>>>', res);
    if (res?.status) {
      yield put(deleteFavouriteLocationSuccess(res));
      if (meta) {
        yield call(meta, res);
      }
    } else {
      yield put(deleteFavouriteLocationFailed(res));
      if (error) {
        yield call(error, res);
      }
    }
  } catch (err) {
    yield put(deleteFavouriteLocationFailed(err));
    yield call(error, err);
  }
}
