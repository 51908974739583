import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useCollapseView = () => {
  const dispatch = useDispatch();
  const [isExpand, setIsExpand] = useState(false);
  useEffect(() => {
    setIsExpand(false);
  }, []);

  const handleCollapsed = () => {
    setIsExpand(!isExpand);
  };

  return { isExpand, handleCollapsed };
};
