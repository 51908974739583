import { getPrizeDrawDetailRequest } from '@redux/app/appSlice';
import { getPrizeDrawDetailCreator } from '@redux/app/selectors';
import { ObjectUtil } from '@utils/common/object';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const usePrizeDraw = () => {
  const dispatch = useDispatch();

  const getPrizeData = useSelector(getPrizeDrawDetailCreator)?.data;
  const data = ObjectUtil.toList(getPrizeData);
  console.log('usePrizeDraw>>>', getPrizeData);
  useEffect(() => {
    getPrizeApi();
  }, []);
  useEffect(() => {}, [getPrizeData]);

  const getPrizeApi = () => {
    dispatch(getPrizeDrawDetailRequest());
  };

  const toDataContent = item => {
    const rs = [];
    console.log('toDataContent>>>', item)
    for (const objectElement in item) {
      const x = {
        key: objectElement,
        value: item[objectElement],
      };
      rs.push(x);
    }
    console.log('toDataContent>>>rs>>>', rs)

    return rs;
  };
  return { getPrizeData, data, toDataContent };
};
