import { AppSafeAreaView, AppKAView, AppText, AppWebView } from '@components';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import React from 'react';
import { StatusBar, ScrollView, View } from 'react-native';
import { useLogic } from './WebView.hook';
import { styles, attributesForWeb } from './WebView.style';
import { Device } from '@theme/device';

const WebView = () => {
  const { html, header, uri, disableLoading } = useLogic();
    if (Device.deviceType === 'web') {
        return (<>
                <StatusBar translucent backgroundColor="transparent" barStyle="dark-content"/>
                <AppSafeAreaView areaStyle={styles.areaStyle}>
                    <GenericHeader onBackClicked={goBack} title={header} style={[{backgroundColor: '#EDEDED'}]}/>
                    <AppKAView>
                        <View style={styles.container}>
                            <iframe style={styles.iframe} src={uri} srcDoc={html} {...attributesForWeb}></iframe>
                        </View>
                    </AppKAView>
                </AppSafeAreaView>
            </>)
    }
  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={styles.areaStyle}>
        <GenericHeader onBackClicked={goBack} title={header} style={[{ backgroundColor: '#EDEDED' }]} />
        <AppKAView>
          <View style={styles.container}>
            <AppWebView html={html} uri={uri} disableLoading={disableLoading}/>
          </View>
        </AppKAView>
      </AppSafeAreaView>
    </>
  );
};

export { WebView };
